import { Store } from 'vuex';
import axios from 'axios';
import Routing from 'fos-routing';

import AbstractRepository from '@/lib/repository-factory/AbstractRepository';
import ProFolder from '@models/proRisk/ProFolder';
import ActionPlan from '@models/proRisk/ActionPlan';
import ActionPlanHasStructure from '@models/proRisk/ActionPlanHasStructure';
import { PaginatedData } from '@/types/paginatedData';

export default class ActionPlanRepository extends AbstractRepository<ActionPlan> {
  query = {
    get: 'api_get_pr_action_plan',
    all: '',
    create: {
      name: 'api_post_pr_action_plan',
      groups: ['post'],
    },
    update: {
      name: 'api_put_pr_action_plan',
      groups: ['put'],
    },
    delete: 'api_delete_pr_action_plan',
  };

  constructor(store: Store<any>) {
    super(store, ActionPlan);
    ActionPlan.setRepository(this);
  }

  getAll(): ActionPlan[] {
    return ActionPlan.query().with(['risk.character', 'risk.structures']).all();
  }

  getAllOrder(order: { col: string, direction: 'asc' | 'desc' }) {
    const datas = ActionPlan.query().with(['risk.character', 'risk.structures']).orderBy(order.col, order.direction).all();
    if (order.col === 'risk.position' && order.direction === 'desc') {
      // @ts-ignore
      return datas.sort((a, b) => (a.risk.position > b.risk.position ? -1 : 1));
    }

    if (order.col === 'risk.character.label') {
      if (order.direction === 'desc') {
        // @ts-ignore
        return datas.sort((a, b) => (a.risk.character.label > b.risk.character.label ? -1 : 1));
      }
    }

    // @ts-ignore
    return datas.sort((a, b) => (a.risk.character.label < b.risk.character.label ? -1 : 1));
  }

  async search(payload: { folder: ProFolder, filters: any }): Promise<PaginatedData<ActionPlan>> {
    const { folder, filters } = payload;
    const { data } = await axios.get<PaginatedData<ActionPlan>>(Routing.generate('api_get_pr_action_plan_search', { id: folder.id, ...filters }));
    await this.deleteAll();
    await ActionPlan.insertOrUpdate({ data: data.items });

    return data;
  }

  async deleteAll(): Promise<any> {
    ActionPlanHasStructure.deleteAll();
    return super.deleteAll();
  }
}
