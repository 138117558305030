import Vue from 'vue';
import { MutationTree } from 'vuex';
import clone from 'lodash/cloneDeep';

import { FirmUpdateModuleInterface } from '@modules/declaration/store/modules/firmUpdate/types';
import Unit from '@modules/declaration/entities/Unit';
import Job from '@modules/declaration/entities/Job';
import EmployeeHasJob from '@modules/declaration/entities/EmployeeHasJob';
import EmployeeHasContract from '@modules/declaration/entities/EmployeeHasContract';
import EmployeeHasExposition from '@modules/declaration/entities/EmployeeHasExposition';
import * as types from './mutation-types';

export default {
  [types.SET_DECLARATION](state, payload) {
    state.declaration = payload;
  },
  [types.SET_INITIAL_STEP](state, payload) {
    state.initialStep = payload;
  },
  [types.UPDATE_CURRENT_STEP](state, payload) {
    state.currentStep = payload;
  },

  [types.INIT_UNITS](state, units) {
    state.units = units;
  },
  [types.INIT_JOBS](state, jobs) {
    state.jobs = jobs;
  },
  [types.INIT_PERSONS](state, data) {
    state.persons = data;
  },
  [types.INIT_EMPLOYEES](state, data) {
    state.employees = data;
  },
  [types.INIT_EMPLOYEE_HAS_JOBS](state, data) {
    state.employeeHasJobs = data;
  },
  [types.INIT_EMPLOYEE_HAS_CONTRACTS](state, data) {
    state.employeeHasContracts = data;
  },
  [types.INIT_EMPLOYEE_HAS_EXPOSITIONS](state, data) {
    state.employeeHasExpositions = data;
  },

  [types.SAVE_RESULT](state, data) {
    state.results = data;
  },

  [types.ADD_UNIT](state, data: Unit) {
    if (data.parentUuid !== null) {
      const parent = state.units[data.parentUuid];
      parent.childrenUuid.push(data.uuid);
      data.parentId = parent.id;
    }

    const cloned = clone(data);
    cloned.jobs = [];

    Vue.set(state.units, data.uuid, data);
  },
  [types.UPDATE_UNIT](state, data: Unit) {
    const unit = state.units[data.uuid];
    if (unit !== null) {
      if (data.parentUuid !== null && unit.parentUuid !== null && data.parentUuid !== unit.parentUuid) {
        const parent = state.units[unit.parentUuid];
        const index = parent.childrenUuid.indexOf(unit.uuid);
        if (index > -1) {
          parent.childrenUuid.splice(index, 1);
        }

        const newParent = state.units[data.parentUuid];
        newParent.childrenUuid.push(data.uuid);
        data.parentId = newParent.id;
      }
      if (data.id !== unit.id) {
        unit.childrenUuid.forEach((key) => {
          state.units[key].parentId = data.id;
        });
        unit.jobsUuid.forEach((key) => {
          state.jobs[key].unitId = data.id;
        });
      }

      const cloned = clone(data);
      cloned.jobsUuid = unit.jobsUuid;
      cloned.jobs = [];

      Object.assign(unit, cloned);
    }
  },
  [types.DELETE_UNIT](state, data: Unit) {
    const unit = state.units[data.uuid];
    if (unit.parentUuid !== null && state.units[unit.parentUuid] !== undefined) {
      const index = state.units[unit.parentUuid].childrenUuid.indexOf(unit.uuid);
      if (index > -1) {
        state.units[unit.parentUuid].childrenUuid.splice(index, 1);
      }
    }

    Vue.delete(state.units, data.uuid);
  },
  [types.ADD_JOB](state, data: Job) {
    const unit = state.units[data.unitUuid];
    data.unitId = unit.id;

    const cloned = clone(data);
    cloned.employeeHasJobs = [];

    Vue.set(state.jobs, data.uuid, cloned);

    unit.jobsUuid.push(data.uuid);
  },
  [types.UPDATE_JOB](state, data: Job) {
    const job = state.jobs[data.uuid];
    if (job !== undefined) {
      if (data.unitUuid !== undefined && data.unitUuid !== job.unitUuid) {
        const parent = state.units[job.unitUuid];
        const index = parent.jobsUuid.indexOf(job.uuid);
        if (index > -1) {
          parent.jobsUuid.splice(index, 1);
        }

        const newParent = state.units[data.unitUuid];
        newParent.jobsUuid.push(data.uuid);
        data.unitId = newParent.id;
      }
      if (data.id && data.id !== job.id) {
        job.employeeHasJobsUuid.forEach((key) => {
          state.employeeHasJobs[key].jobId = data.id;
        });
      }

      const cloned = clone(data);
      cloned.employeeHasJobs = [];
      cloned.employeeHasJobsUuid = job.employeeHasJobsUuid;

      Object.assign(job, cloned);
    }
  },
  [types.DELETE_JOB](state, data: Job) {
    const job = state.jobs[data.uuid];
    job.employeeHasJobsUuid.forEach((key) => {
      Vue.delete(state.employeeHasJobs, key);
    });
    const index = state.units[job.unitUuid].jobsUuid.indexOf(data.uuid);
    if (index > -1) {
      state.units[job.unitUuid].jobsUuid.splice(index, 1);
    }


    Vue.delete(state.jobs, data.uuid);
  },

  [types.ADD_PERSON](state, data) {
    Vue.set(state.persons, data.uuid, data);
  },
  [types.UPDATE_PERSON](state, data) {
    const person = state.persons[data.uuid];
    if (person !== undefined) {
      Object.assign(person, data);
    }
  },
  [types.DELETE_PERSON](state, data) {
    Vue.delete(state.persons, data.uuid);
  },

  [types.ADD_EMPLOYEE](state, data) {
    data.employeeHasJobs = [];
    data.employeeHasContracts = [];
    data.expositions = [];
    data.person = null;

    Vue.set(state.employees, data.uuid, data);
  },
  [types.UPDATE_EMPLOYEE](state, data) {
    const employee = state.employees[data.uuid];
    if (employee !== undefined) {
      const cloned = clone(data);
      cloned.employeeHasJobs = [];
      cloned.employeeHasContracts = [];
      cloned.expositions = [];
      cloned.person = null;

      cloned.employeeHasJobsUuid = employee.employeeHasJobsUuid;
      cloned.employeeHasContractsUuid = employee.employeeHasContractsUuid;
      cloned.employeeHasExpositionsUuid = employee.employeeHasExpositionsUuid;

      Object.assign(employee, cloned);
    }
  },
  [types.DELETE_EMPLOYEE](state, data) {
    const { uuid } = data;
    Vue.delete(state.employees, uuid);
  },

  [types.ADD_EMPLOYEE_HAS_JOB](state, data: EmployeeHasJob) {
    const { jobUuid, employeeUuid } = data;
    const job = state.jobs[jobUuid];
    const employee = state.employees[employeeUuid];

    data.jobId = job.id;
    data.employeeId = employee.id;
    Vue.set(state.employeeHasJobs, data.uuid, data);

    job.employeeHasJobsUuid.push(data.uuid);
    employee.employeeHasJobsUuid.push(data.uuid);
  },
  [types.UPDATE_EMPLOYEE_HAS_JOB](state, data: EmployeeHasJob) {
    const employeeHasJob = state.employeeHasJobs[data.uuid];
    if (employeeHasJob !== null) {
      const cloned = clone(data);
      if (data.employee) {
        cloned.employeeId = data.employee.id;
        cloned.employeeUuid = data.employee.uuid;
      }
      cloned.employee = null;

      if (data.job) {
        cloned.jobId = data.job.id;
        cloned.jobUuid = data.job.uuid;
      }
      cloned.job = null;

      Object.assign(employeeHasJob, cloned);
    }
  },
  [types.DELETE_EMPLOYEE_HAS_JOB](state, data: EmployeeHasJob) {
    const { uuid } = data;
    const { jobUuid, employeeUuid } = state.employeeHasJobs[uuid];
    let index = state.jobs[jobUuid].employeeHasJobsUuid.indexOf(uuid);
    if (index > -1) {
      state.jobs[jobUuid].employeeHasJobsUuid.splice(index, 1);
    }

    index = state.employees[employeeUuid].employeeHasJobsUuid.indexOf(uuid);
    if (index > -1) {
      state.employees[employeeUuid].employeeHasJobsUuid.splice(index, 1);
    }

    Vue.delete(state.employeeHasJobs, uuid);
  },

  [types.ADD_EMPLOYEE_HAS_CONTRACT](state, data: EmployeeHasContract) {
    const { employeeUuid, uuid } = data;
    Vue.set(state.employeeHasContracts, uuid, data);
    state.employees[employeeUuid].employeeHasContractsUuid.push(uuid);
  },
  [types.UPDATE_EMPLOYEE_HAS_CONTRACT](state, data: EmployeeHasContract) {
    const employeeHasContract = state.employeeHasContracts[data.uuid];
    if (employeeHasContract !== null) {
      Object.assign(employeeHasContract, data);
    }
  },
  [types.DELETE_EMPLOYEE_HAS_CONTRACT](state, data: EmployeeHasContract) {
    const { uuid } = data;
    const { employeeUuid } = state.employeeHasContracts[uuid];

    const index = state.employees[employeeUuid].employeeHasContractsUuid.indexOf(uuid);
    if (index > -1) {
      state.employees[employeeUuid].employeeHasContractsUuid.splice(index, 1);
    }

    Vue.delete(state.employeeHasContracts, uuid);
  },

  [types.ADD_EMPLOYEE_HAS_EXPOSITION](state, data: EmployeeHasExposition) {
    const { employeeUuid, uuid } = data;
    Vue.set(state.employeeHasExpositions, uuid, data);
    state.employees[employeeUuid].employeeHasExpositionsUuid.push(uuid);
  },
  [types.UPDATE_EMPLOYEE_HAS_EXPOSITION](state, data: EmployeeHasExposition) {
    const employeeHasExposition = state.employeeHasExpositions[data.uuid];
    if (employeeHasExposition !== null) {
      Object.assign(employeeHasExposition, data);
    }
  },
  [types.DELETE_EMPLOYEE_HAS_EXPOSITION](state, data: { uuid: string }) {
    const { uuid } = data;
    const employeeHasExposition = state.employeeHasExpositions[uuid];
    const employee = state.employees[employeeHasExposition.employeeUuid];

    const index = employee.employeeHasExpositionsUuid.indexOf(uuid);
    if (index > -1) {
      employee.employeeHasExpositionsUuid.splice(index, 1);
    }

    Vue.delete(state.employeeHasExpositions, uuid);
  },
} as MutationTree<FirmUpdateModuleInterface>;
