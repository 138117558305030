import { MutationTree } from 'vuex';

import ProFolder from '@models/proRisk/ProFolder';

import { RiskProModuleInterface } from './types';
import * as types from './mutation-types';

export default {
  setActiveProFolder(state, proFolder: ProFolder | null): void {
    state.activeProFolder = proFolder ? proFolder.id : null;
  },
  [types.SET_HISTORIC_PRO_FOLDER](state, payload: ProFolder[]): void {
    state.historicProFolders = payload;
  },
} as MutationTree<RiskProModuleInterface>;
