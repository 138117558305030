import FirmMetaModel from '@/store/models/firm/FirmMetaModel';
import Firm from '@models/firm/Firm';
import { Expose, Exclude } from 'class-transformer';

@Exclude()
class FirmMeta extends FirmMetaModel {
  @Expose()
  id!: number;

  @Expose()
  key!: string;

  @Expose()
  value!: string;

  @Expose()
  firm!: Firm;

  @Expose()
  firmId!: number;
}

export default FirmMeta;
