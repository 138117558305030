import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import {
  Exclude,
  Expose,
  Transform,
  Type,
} from 'class-transformer';

import Firm from './Firm';

@Exclude()
export class FirmStructure {
  @Expose({ groups: ['all'] })
  new: boolean = true;

  @Expose({ groups: ['all'] })
  edit: boolean = false;

  @Expose({ notEmptyOnly: true })
  id: number | null = null;

  @Expose()
  uuid: string = uuidv4();

  @Expose()
  label: string = '';

  @Expose()
  path: string = '';

  @Expose({ name: 'firm', toPlainOnly: true })
  @Expose({ name: 'firmId', toPlainOnly: true })
  firmId: number = 0;

  @Type(() => Firm)
  firm: Firm | null = null;

  disc: string = 'Structure';

  get isSave() {
    return this.id !== null;
  }

  get isActive() {
    return true;
  }
}

export default FirmStructure;
