export const INIT_UNITS = 'INIT_UNITS';
export const INIT_JOBS = 'INIT_JOBS';
export const INIT_PERSONS = 'INIT_PERSONS';
export const INIT_EMPLOYEES = 'INIT_EMPLOYEES';
export const INIT_EMPLOYEE_HAS_JOBS = 'INIT_EMPLOYEE_HAS_JOBS';
export const INIT_EMPLOYEE_HAS_CONTRACTS = 'INIT_EMPLOYEE_HAS_CONTRACTS';
export const INIT_EMPLOYEE_HAS_EXPOSITIONS = 'INIT_EMPLOYEE_HAS_EXPOSITIONS';

export const SAVE_RESULT = 'SAVE_RESULT';

export const ADD_UNIT = 'ADD_UNIT';
export const UPDATE_UNIT = 'UPDATE_UNIT';
export const DELETE_UNIT = 'DELETE_UNIT';

export const ADD_JOB = 'ADD_JOB';
export const UPDATE_JOB = 'UPDATE_JOB';
export const DELETE_JOB = 'DELETE_JOB';

export const ADD_EMPLOYEE = 'ADD_EMPLOYEE';
export const UPDATE_EMPLOYEE = 'UPDATE_EMPLOYEE';
export const DELETE_EMPLOYEE = 'DELETE_EMPLOYEE';

export const ADD_PERSON = 'ADD_PERSON';
export const UPDATE_PERSON = 'UPDATE_PERSON';
export const DELETE_PERSON = 'DELETE_PERSON';

export const ADD_EMPLOYEE_HAS_JOB = 'ADD_EMPLOYEE_HAS_JOB';
export const UPDATE_EMPLOYEE_HAS_JOB = 'UPDATE_EMPLOYEE_HAS_JOB';
export const DELETE_EMPLOYEE_HAS_JOB = 'DELETE_EMPLOYEE_HAS_JOB';

export const ADD_EMPLOYEE_HAS_CONTRACT = 'ADD_EMPLOYEE_HAS_CONTRACT';
export const UPDATE_EMPLOYEE_HAS_CONTRACT = 'UPDATE_EMPLOYEE_HAS_CONTRACT';
export const DELETE_EMPLOYEE_HAS_CONTRACT = 'DELETE_EMPLOYEE_HAS_CONTRACT';

export const ADD_EMPLOYEE_HAS_EXPOSITION = 'ADD_EMPLOYEE_HAS_EXPOSITION';
export const UPDATE_EMPLOYEE_HAS_EXPOSITION = 'UPDATE_EMPLOYEE_HAS_EXPOSITION';
export const DELETE_EMPLOYEE_HAS_EXPOSITION = 'DELETE_EMPLOYEE_HAS_EXPOSITION';

export const SET_INITIAL_STEP = 'SET_INITIAL_STEP';
export const UPDATE_CURRENT_STEP = 'UPDATE_CURRENT_STEP';
export const SET_DECLARATION = 'SET_DECLARATION';
