import { Store } from 'vuex';

import AbstractRepository from '@/lib/repository-factory/AbstractRepository';
import Unit from '@models/firm/Unit';

export default class UnitRepository extends AbstractRepository<Unit> {
  query = {
    get: '',
    all: '',
    create: 'api_post_unit',
    update: 'api_put_unit',
    delete: 'api_delete_unit',
  };

  constructor(store: Store<any>) {
    super(store, Unit);
    Unit.setRepository(this);
  }

  hasEditedCurrentFirm() {
    return this.hasEdited(this.store.state.UserModule.currentFirmId);
  }

  hasEdited(currentFirm: number) {
    return Unit.query().where('edit', true).where('firmId', currentFirm).count() > 0;
  }
}
