import {
  Exclude, Expose, Transform, Type,
} from 'class-transformer';

import moment from 'moment';
import Group from './Group';
import FirmContact from './FirmContact';
import CodeNaf from './CodeNaf';
import { FirmStructure, Unit, Job } from './FirmStructureHierarchy';
import Employee from './Employee';

@Exclude()
class Firm {
  @Expose()
  id!: number;

  @Expose()
  tag!: string;

  @Expose()
  label!: string;

  @Expose()
  number!: string;

  @Expose()
  siret!: string;

  @Expose()
  padoaId!: string;

  @Expose()
  declaration!: boolean;

  @Expose()
  visibleChi!: boolean;

  @Expose()
  visiblePro!: boolean;

  @Expose()
  codeNafId!: number | null;

  @Expose()
  codeNaf!: CodeNaf | null;

  @Expose()
  root!: number;

  @Expose()
  @Type(() => FirmStructure, {
    discriminator: {
      property: 'disc',
      subTypes: [
        { value: Unit, name: 'Unit' },
        { value: Job, name: 'Job' },
      ],
    },
  })
  structures!: Array<FirmStructure>;

  @Expose()
  @Type(() => Employee)
  employees!: Array<Employee>;

  contactId!: number;

  facturationContactId!: number;

  convocationContactId!: number;

  contact!: FirmContact;

  convocationContact!: FirmContact;

  facturationContact!: FirmContact;

  @Expose()
  @Type(() => Date)
  @Transform(value => (value ? moment(value).format('YYYY-MM-DDTHH:mm:ss') : null), { toPlainOnly: true })
  rgpdAcceptedAt!: Date | null;

  @Expose()
  @Type(() => Date)
  @Transform(value => (value ? moment(value).format('YYYY-MM-DDTHH:mm:ss') : null), { toPlainOnly: true })
  employeesSynchronizedAt!: Date | null;

  @Expose()
  @Type(() => Date)
  @Transform(value => (value ? moment(value).format('YYYY-MM-DDTHH:mm:ss') : null), { toPlainOnly: true })
  lastDeclaration!: Date | null;

  groupId!: number | null;

  group!: Group | null;

  get fullName() {
    return `${this.label} (${this.number})`;
  }

  get isSave() {
    return this.id !== null;
  }
}

export default Firm;
