import axios from 'axios';
import Routing from 'fos-routing';
import { injectable } from 'inversify';

import { FirmUpdateServiceInterface } from '../FirmUpdateServiceInterface';

@injectable()
export default class FirmUpdateService implements FirmUpdateServiceInterface {
  async getEffectiveReal(): Promise<any> {
    const response = await axios.get(Routing.generate('api_get_declaration_effective_real'));
    return response.data;
  }
}
