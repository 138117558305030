import { schema } from 'normalizr';

import Employee from '@modules/declaration/entities/Employee';
import EmployeeHasJob from '@modules/declaration/entities/EmployeeHasJob';
import EmployeeHasContract from '@modules/declaration/entities/EmployeeHasContract';
import EmployeeHasExposition from '@modules/declaration/entities/EmployeeHasExposition';

import person from './personSchema';
import employeeHasContract from './employeeHasContractSchema';
import employeeHasJob from './employeeHasJobSchema';
import employeeHasExposition from './employeeHasExpositionSchema';

const employee = new schema.Entity<Employee>(Employee.entity, {
  person,
  personUuid: person,
  employeeHasContracts: [employeeHasContract],
  employeeHasContractsUuid: [employeeHasContract],
  employeeHasJobs: [employeeHasJob],
  employeeHasJobsUuid: [employeeHasJob],
  expositions: [employeeHasExposition],
  employeeHasExpositionsUuid: [employeeHasExposition],
}, {
  processStrategy: (value) => {
    value.beginDate = new Date(value.beginDate);
    value.endDate = value.endDate ? new Date(value.endDate) : null;

    value.firmId = value.firm;
    value.firm = null;

    value.personUuid = value.person || value.personUuid;
    value.person = null;

    value.employeeHasContracts.forEach((e: EmployeeHasContract) => {
      e.employeeUuid = value.uuid;
    });
    value.employeeHasContractsUuid = value.employeeHasContracts || [];
    value.employeeHasContracts = [];

    value.employeeHasJobs.forEach((e: EmployeeHasJob) => {
      e.employeeUuid = value.uuid;
    });
    value.employeeHasJobsUuid = value.employeeHasJobs || [];
    value.employeeHasJobs = [];

    value.expositions.forEach((e: EmployeeHasExposition) => {
      e.employeeUuid = value.uuid;
    });
    value.employeeHasExpositionsUuid = value.expositions || [];
    value.expositions = [];
    return Object.assign(new Employee(), { ...value });
  },
  mergeStrategy: (entityA, entityB) => Object.assign(entityA, entityB),
  idAttribute: Employee.primaryKey,
});

export default employee;
