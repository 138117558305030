import { Fields } from '@vuex-orm/core';
import { Exclude, Expose, Transform } from 'class-transformer';

import { FirmItemType, FirmStructureItem } from '@/types/firm/firm';

import { FirmStructure, Job } from '@/store/models/firm/FirmStructureHierarchy';

@Exclude()
export class Unit extends FirmStructure implements FirmStructureItem {
  static entity = 'unit';
  static primaryKey = 'uuid';
  static baseEntity = 'firmStructure';

  static fields(): Fields {
    return {
      ...super.fields(),
      type: this.string('unit'),
      parentUuid: this.string(null).nullable(),
      parent: this.belongsTo(Unit, 'parentUuid'),
      lft: this.number(2).nullable(),
      rgt: this.number(1).nullable(),
      lvl: this.number(1).nullable(),
      jobs: this.hasMany(Job, 'unitUuid'),
      children: this.hasMany(Unit, 'parentUuid'),
    };
  }

  @Expose({ groups: ['all'] })
  type!: FirmItemType;

  @Expose({ groups: ['all'] })
  parentUuid!: string | null;

  @Expose()
  @Transform(value => (value ? value.id : null), { toPlainOnly: true })
  parent!: Unit | null;

  @Expose()
  lft!: number;

  @Expose()
  rgt!: number;

  @Expose()
  lvl!: number;

  @Expose({ groups: ['all'] })
  jobs!: Array<Job>;

  jobsUuid: string[] = [];

  children!: Array<Unit>;

  childrenUuid: string[] = [];

  remove: boolean = false;

  get isSave(): boolean {
    return this.id !== null;
  }

  get isRoot(): boolean {
    return this.lvl === 0;
  }
}

export default Unit;
