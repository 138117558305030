import { Store } from 'vuex';
import axios from 'axios';
import Routing from 'fos-routing';

import AbstractRepository from '@/lib/repository-factory/AbstractRepository';
import ProFolder from '@models/proRisk/ProFolder';
import { FolderEnum } from '@/types/Folder';

export default class ProFolderRepository extends AbstractRepository<ProFolder> {
  query = {
    get: 'api_get_pr_pro_folder',
    all: '',
    create: 'api_post_pr_pro_folder',
    update: 'api_put_pr_pro_folder',
    delete: '',
  };

  constructor(store: Store<any>) {
    super(store, ProFolder);
    ProFolder.setRepository(this);
  }

  getActive(): ProFolder | null {
    return ProFolder.find(this.store.state.riskPro.activeProFolder) || null;
  }

  getHistoric(): ProFolder[] {
    return ProFolder.query().where('status', FolderEnum.FOLDER_CLOSED).get();
  }

  getHistoricOrder(prop: string, direction: 'asc' | 'desc') {
    return ProFolder.query().where('status', FolderEnum.FOLDER_CLOSED).orderBy(prop, direction).get();
  }

  async findActiveProFolder(): Promise<ProFolder | null> {
    const response = await axios.get<ProFolder>(Routing.generate('api_get_pr_active_pro_folder')).catch(error => ({ data: null }));

    const proFolder = response.data || null;
    if (proFolder) {
      this.store.commit('riskPro/setActiveProFolder', proFolder, { root: true });
      await ProFolder.insertOrUpdate({ data: proFolder });
    } else {
      this.store.commit('riskPro/setActiveProFolder', null, { root: true });
    }

    return proFolder;
  }

  async findHistoricProFolders() {
    const response = await axios.get(Routing.generate('api_get_pr_historic_pro_folder'));
    const folders = ProFolder.query().where('status', FolderEnum.FOLDER_CLOSED).get();
    folders.forEach((f) => {
      ProFolder.delete(f.id as number);
    });

    return ProFolder.insertOrUpdate({ data: response.data });
  }

  async deleteHistoric() {
    return Promise.resolve();
  }
}
