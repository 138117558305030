import { injectable } from 'inversify';
import axios from 'axios';
import Routing from 'fos-routing';

import { FirmRoleServiceInterface } from '@/services/interfaces';

@injectable()
export default class FirmRoleService implements FirmRoleServiceInterface {
  async matrix(): Promise<any> {
    const response = await axios.get(Routing.generate('api_get_user_firm_roles_matrix'));
    return response.data;
  }
}
