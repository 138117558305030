import { Store } from 'vuex';
import { Container } from 'inversify';
import { Database } from '@vuex-orm/core';
import VueRoutes from '@/types/VueRoutes';
import RepositoryFactory from '@/lib/repository-factory/RepositoryFactory';

export interface Module {
  store: Store<any>;
  router: VueRoutes;
  container: Container;
  database: Database;
  repositoryFactory: RepositoryFactory;

  install: (options: any) => void;
}

export abstract class AbstractModule implements Module {
  store: Store<any>;
  router: VueRoutes;
  container: Container;
  database: Database;
  repositoryFactory: RepositoryFactory;

  constructor(store: Store<any>, router: VueRoutes, container: Container, database: Database, repositoryFactory: RepositoryFactory) {
    this.store = store;
    this.router = router;
    this.container = container;
    this.database = database;
    this.repositoryFactory = repositoryFactory;
  }

  abstract install(options: any): void;
}
