import store from '@/store';
import { RouteConfig } from 'vue-router';

const actionPlan = () => import('../pages/action/ActionPlanView.vue');
const actionPlanForm = () => import('../pages/action/ActionPlanFormView.vue');

export default [
  {
    path: '/entreprise/plan-d-actions',
    name: 'pro-risks-action-plan',
    component: actionPlan,
    beforeEnter: (to, from, next) => {
      const promises = [];
      promises.push(store.dispatch('repositories/riskCharacter/findAll'));
      Promise.all(promises).then(() => {
        next();
      });
    },
    meta: {
      breadcrumb: [
        { name: 'Accueil', link: 'home', icon: 'home' },
        { name: 'Risques Professionnels', link: 'pro-risks-dashboard' },
        { name: 'Plan d\'action' },
      ],
      permission: 1,
      access: () => store.getters['riskPro/validActiveProFolderStatus']([0]),
    },
  },
  {
    path: '/entreprise/plan-d-actions/create',
    name: 'pro-risks-action-plan-create',
    component: actionPlanForm,
    beforeEnter: (to, from, next) => {
      const promises = [];
      promises.push(store.dispatch('repositories/riskCharacter/findAll'));
      Promise.all(promises).then(() => {
        next();
      });
    },
    meta: {
      breadcrumb: [
        { name: 'Accueil', link: 'home', icon: 'home' },
        { name: 'Risques Professionnels', link: 'pro-risks-dashboard' },
        { name: 'Plan d\'action', link: 'pro-risks-action-plan' },
        { name: 'Création' },
      ],
      permission: 2,
      group: 'pro-risks-action-plan',
      access: () => store.getters['riskPro/validActiveProFolderStatus']([0]),
    },
  },
  {
    path: '/entreprise/plan-d-actions/:id/edit',
    name: 'pro-risks-action-plan-edit',
    component: actionPlanForm,
    beforeEnter: (to, from, next) => {
      const promises = [];
      if (to.params.id !== undefined) {
        promises.push(store.dispatch('repositories/proActionPlan/find', to.params.id));
      }
      Promise.all(promises).then(() => {
        next();
      });
    },
    meta: {
      breadcrumb: [
        { name: 'Accueil', link: 'home', icon: 'home' },
        { name: 'Risques Professionnels', link: 'pro-risks-dashboard' },
        { name: 'Plan d\'action', link: 'pro-risks-action-plan' },
      ],
      permission: 2,
      access: () => store.getters['riskPro/validActiveProFolderStatus']([0]),
      group: 'pro-risks-action-plan',
    },
  },
] as RouteConfig[];
