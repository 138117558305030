






import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class InputHelper extends Vue {
  @Prop({ type: String })
  content!: string;
}
