















import {
  Component,
  Prop,
  Watch,
  Vue,
} from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import MenuItem from '@/entity/menu/MenuItem';
import routesProxy from '@/router/proxy';
import Firm from '@models/firm/Firm';
import { RouteConfig } from 'vue-router';

@Component({
  name: 'MenuItemComponent',
})
export default class MenuItemComponent extends Vue {
  @Prop({ type: Object })
  item!: MenuItem;

  @Getter('activeItems')
  actives!: Array<string>;

  @Getter('currentFirm')
  currentFirm!: Firm;

  isOpen: Boolean = false;

  get isActive(): boolean {
    return this.actives.includes(this.item.code);
  }

  @Watch('isActive', { immediate: true })
  initOpen(val: boolean) {
    this.isOpen = val;
  }

  toggleOpenItem() {
    this.isOpen = !this.isOpen;
  }

  route(item: MenuItem): any {
    return item.routeName ? {
      name: item.routeName,
    } : '#';
  }

  get showRoute() {
    const route = this.item.routeName ? routesProxy.find(this.item.routeName) : null;
    let show = this.item.isActive && ((!this.item.routeName && this.hasChildren) || this.item.routeName);
    if (route) {
      show = this.accessRoute(route);
    }

    if (this.item.code === 'pro-risks' && !this.currentFirm.visiblePro) {
      return false;
    }

    if (this.item.code === 'chemi-risks' && !this.currentFirm.visibleChi) {
      return false;
    }

    return show;
  }

  get children() {
    return this.item.children && this.item.children.filter((child: any) => {
      if (!child.routeName) {
        return true;
      }

      const route = routesProxy.find(child.routeName);
      if (!route) {
        return false;
      }

      const permission = route.meta.permission || 1;
      return this.isGranted(child.routeName, permission) && this.accessRoute(route);
    });
  }

  get hasChildren() {
    return this.children.length > 0;
  }

  accessRoute(route: RouteConfig) {
    if (route && route.meta && route.meta.access) {
      return typeof route.meta.access === 'function' ? route.meta.access() : route.meta.access;
    }

    return true;
  }
}
