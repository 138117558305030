import { injectable } from 'inversify';

import Risk from '@models/proRisk/Risk';
import Unit from '@models/firm/Unit';
import Job from '@models/firm/Job';

import { CriticalityColor, QuotationEnum, QuotationEnumName } from '../../types/enum';
import { Criticality, CRITICALITY_UNDEFINED } from '../../types/risk';
import { RiskServiceInterface } from '../RiskServiceInterface';
import UnitMatrix from '../../models/UnitMatrix';

@injectable()
export default class RiskService implements RiskServiceInterface {
  criticality(risk: Risk | null): Criticality {
    if (!risk) {
      return CRITICALITY_UNDEFINED;
    }

    const { quotation } = risk;

    return {
      color: this.color(risk),
      quotation: QuotationEnumName.get(quotation[0]) as string,
      value: quotation[1].toFixed(2),
      quotationValue: quotation[0],
    };
  }

  color(risk: Risk | null): string {
    if (!risk) {
      return CriticalityColor.get(QuotationEnum.UNDEFINED) as string;
    }

    return CriticalityColor.get(risk.quotation[0]) || '';
  }

  generateMatrix(risks: Risk[], units: Unit[]): Record<number, UnitMatrix[]> {
    const matrix: Record<number, UnitMatrix[]> = {};
    units.forEach((u) => {
      const { firmId } = u;
      if (!matrix[firmId]) {
        matrix[firmId] = [];
      }

      const record = matrix[firmId];
      const unitMatrix = new UnitMatrix(u);
      record.push(unitMatrix);
    });

    Object.values(matrix).forEach((value) => {
      value.sort((a, b) => (a.unit.lft < b.unit.lft ? -1 : 1));
    });

    risks.forEach((risk) => {
      risk.structures.forEach((structure) => {
        if (!risk.characterId) {
          return;
        }

        const { firmId } = structure;
        if (!matrix[firmId]) {
          matrix[firmId] = [];
        }
        const record = matrix[firmId];

        if (structure.disc === 'Unit') {
          this.setQuotationCascade(structure as Unit, risk, record, units);
        } else {
          const job = structure as Job;
          const unit = units.find(u => u.uuid === job.parentUuid);
          this.injectRiskUnitMatrix(unit as Unit, risk, record);
        }
      });
    });

    return matrix;
  }

  private setQuotationCascade(structure: Unit, risk: Risk, matrix: UnitMatrix[], units: Unit[]) {
    if (!risk.characterId) {
      return;
    }

    this.injectRiskUnitMatrix(structure, risk, matrix);

    const children = units.filter(u => u.parentUuid === structure.uuid);
    children.forEach((child) => {
      this.setQuotationCascade(child, risk, matrix, units);
    });
  }

  private injectRiskUnitMatrix(structure: Unit, risk: Risk, matrix: UnitMatrix[]): void {
    if (!risk.characterId) {
      return;
    }

    let unitMatrix = matrix.find(m => m.unit.id === structure.id);
    if (!unitMatrix) {
      unitMatrix = new UnitMatrix(structure);
      matrix.push(unitMatrix);
    }

    unitMatrix.setQuotation(risk.characterId, this.criticality(risk));
  }
}
