import store from '@/store';

const synthesis = () => import('../pages/synthesis/SynthesisView.vue');
const preview = () => import('../pages/synthesis/PreviewView.vue');

export default [
  {
    path: '/risques-chimiques/synthese',
    name: 'chemi-risks-synthesis',
    component: synthesis,
    beforeEnter: (to: any, from: any, next: () => void) => {
      const p1 = store.dispatch('cheRisk/getApiActiveCheFolder');
      const p3 = store.dispatch('cheRisk/loadPictograms');
      const p4 = store.dispatch('cheRisk/loadHazardStatements');
      Promise.all([p1, p3, p4]).then(() => {
        next();
      });
    },
    meta: {
      permission: 1,
      breadcrumb: [
        { name: 'Accueil', link: 'home', icon: 'home' },
        { name: 'Risques Chimiques', link: 'chemi-risks-dashboard' },
        { name: 'Synthèse d\'analyse des FDS' },
      ],
      access: () => store.getters['cheRisk/validActiveCheFolderStatus']([0, 4, 5]),
    },
  },
  {
    path: '/risques-chimiques/previsualisation',
    name: 'chemi-risks-preview',
    component: preview,
    beforeEnter: (to: any, from: any, next: () => void) => {
      const p1 = store.dispatch('cheRisk/getApiActiveCheFolder');
      // const p2 = store.dispatch('firm/loadFirmStructure', store.state.UserModule.currentFirmId);
      const p3 = store.dispatch('cheRisk/loadPictograms');
      const p4 = store.dispatch('cheRisk/loadHazardStatements');
      Promise.all([p1, p3, p4]).then(() => {
        next();
      });
    },
    meta: {
      permission: 1,
      breadcrumb: [
        { name: 'Accueil', link: 'home', icon: 'home' },
        { name: 'Risques Chimiques', link: 'chemi-risks-dashboard' },
        { name: 'Prévisualisation du bilan' },
      ],
      access: () => store.getters['cheRisk/validActiveCheFolderStatus']([0, 4, 5]),
    },
  },
];
