import moment from 'moment';

window.TODAY = moment().startOf('day').toDate();

window.EMPR1TE_URL = process.env.EMPR1TE_URL || 'http://localhost:80';

window.scrollToError = () => {
};

window.scrollToTop = () => {
  const scrollDuration = 500;
  const scrollHeight = window.scrollY;
  const scrollStep = Math.PI / (scrollDuration / 15);
  const cosParameter = scrollHeight / 2;
  let scrollCount = 0;
  let scrollMargin;

  const scrollInterval = setInterval(() => {
    if (window.scrollY !== 0) {
      scrollCount += 1;
      scrollMargin = cosParameter - cosParameter * Math.cos(scrollCount * scrollStep);
      window.scrollTo(0, (scrollHeight - scrollMargin));
    } else clearInterval(scrollInterval);
  }, 15);
};

window.redirectToEmpr1te = (path = 'logout') => {
  window.location.href = `${window.EMPR1TE_URL}/${path}`;
};

window.prop = function prop<T, K extends keyof T>(obj: T, key: K) {
  return obj[key];
};
