import { schema } from 'normalizr';
import Unit from '@modules/declaration/entities/Unit';

import job from './jobSchema';

const unit = new schema.Entity<Unit>(Unit.entity, {}, {
  processStrategy: (value, parent) => {
    value.jobsUuid = value.jobs || [];
    value.jobs = [];

    value.childrenUuid = value.children || [];
    value.children = [];

    value.firmId = value.firm || parent.firmId || parent.id;
    value.firm = null;

    value.parentId = value.parent || parent.id || null;

    return Object.assign(new Unit(), { ...value });
  },
  mergeStrategy: (entityA, entityB) => Object.assign(entityA, entityB),
  idAttribute: Unit.primaryKey,
});

const units = new schema.Array(unit);
unit.define({
  jobsUuid: [job],
  childrenUuid: units,
});

export default unit;
