import { Module } from 'vuex';
import Routing from 'fos-routing';
import axios from 'axios';
import _ from 'lodash';
import { classToPlain, plainToClass } from 'class-transformer';

import Firm from '@models/firm/Firm';

import User from '@models/user/User';
import FirmRole from '@models/user/FirmRole';
import cancelToken from '@/api/cancelToken';
import Group from '@models/firm/Group';
import MercureServer from '@/mercure/MercureServer';
import { UserModuleInterface } from './types';

const UserModule: Module<UserModuleInterface, any> = {
  state: {
    browserCompatible: true,
    currentUserId: 0,
    currentFirmId: 0,
    expire: 0,
    connectedUser: new User(),
  },

  getters: {
    connectedUser: state => state.connectedUser,
    browserCompatible: state => state.browserCompatible,
    currentFirm: state => Firm.find(state.currentFirmId) || new Firm(),
    currentUser: state => User.find(state.currentUserId) || new User(),
    currentGroup: state => Group.query().with('firms').limit(1).first(),
    getPermission: state => (group: string) => state.connectedUser.permissions.find(p => p.route === group) || null,
    isGranted: (state, getters) => (group: string, lvl: number) => {
      const p = getters.getPermission(group);
      return p ? p.permission >= lvl : false;
    },
  },

  mutations: {
    setBrowserCompatible(state, status: boolean): void {
      state.browserCompatible = status;
    },
    setCurrentFirmId(state, id: number): void {
      state.currentFirmId = id;
    },
    setCurrentUserId(state, id: number): void {
      state.currentUserId = id;
    },
    setConnectedUser(state, user: User): void {
      state.connectedUser = user;
    },
  },

  actions: {
    async init(context) {
      let response = await axios.put(Routing.generate('api_put_synchronize_current_firm')); // Synchronize firm with PADOA and get current firm
      await Firm.insert({ data: response.data });
      context.commit('setCurrentFirmId', response.data.id);

      response = await axios.get(Routing.generate('api_get_user_firm_available'));
      await Firm.insert({ data: response.data });

      // Ancienne methode : Ne pas supprimer
      // response = await axios.get(Routing.generate('api_get_user_firm_current'));
      // await Firm.insert({ data: response.data });
      // context.commit('setCurrentFirmId', response.data.id);

      const currentFirm = Firm.find(context.state.currentFirmId);
      if (currentFirm === null) {
        window.redirectToEmpr1te();
      }

      try {
        const source = MercureServer.subscriber(context.state.currentFirmId);
        source.addListener('firm_structure', _.debounce(() => {
          context.dispatch('firm/loadStructure', context.state.currentFirmId);
        }, 1000));
      } catch (e) {
        context.commit('setBrowserCompatible', false);
      }

      response = await axios.get<User>(Routing.generate('api_get_current_user'));
      const user = plainToClass(User, response.data);
      context.commit('setConnectedUser', user);

      await context.dispatch('getRoles');
      await context.dispatch('firm/loadStructure', context.state.currentFirmId);
    },

    async refreshCurrentFirm() {
      const response = await axios.get(Routing.generate('api_get_user_firm_available'));
      await Firm.insert({ data: response.data });
    },

    async getUsersCurrentFirm() {
      const response = await axios.get(Routing.generate('api_get_user_firm_users'));
      await User.insert({ data: response.data });
    },

    async changeCurrentUser(context, userId): Promise<void> {
      let user = User.query().find(userId);
      if (!user) {
        await context.dispatch('getUsersCurrentFirm');
        user = User.query().find(userId);
      }

      context.commit('setCurrentUserId', userId);
    },

    async getRoles() {
      const response = await axios.get(Routing.generate('api_get_user_firm_roles'));
      await FirmRole.insert({ data: response.data });
    },

    async updateUser(context, user) {
      const response = await axios.put(Routing.generate('api_put_user', { id: user.id }), {
        user: classToPlain(user),
      }, {
        cancelToken: cancelToken.token,
      });
      await User.update(response.data);
    },
  },
};

export default UserModule;
