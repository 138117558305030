import Vue from 'vue';
import Vuex from 'vuex';

import VuexORM from '@vuex-orm/core';
import VuexRepository from '@/lib/repository-factory/VuexRepository';

import database from './database';
import repositoryFactory from './repository';
import MenuModule from './modules/menu';
import UserModule from './modules/user';
import FirmModule from './modules/firm';

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    init: false,
  },
  mutations: {
    SET_INIT(state, payload) {
      state.init = payload;
    },
  },
  modules: {
    MenuModule,
    UserModule,
    firm: FirmModule,
  },
  plugins: [
    VuexORM.install(database),
    VuexRepository.install(repositoryFactory),
  ],
  strict: (process.env.NODE_ENV !== 'production'),
});

export default store;
