import store from '@/store';

const dashboard = () => import('../pages/dashboard/DashboardView.vue');

export default [
  {
    path: '/risques-chimiques/dossier',
    name: 'chemi-risks-dashboard',
    component: dashboard,
    beforeEnter: (to: any, from: any, next: () => void) => {
      const promises = [];
      promises.push(store.dispatch('cheRisk/getApiActiveCheFolder'));
      promises.push(store.dispatch('cheRisk/getApiHistoricCheFolders'));
      Promise.all(promises).then(() => {
        next();
      });
    },
    meta: {
      permission: 1,
      breadcrumb: [
        { name: 'Accueil', link: 'home', icon: 'home' },
        { name: 'Risques Chimiques' },
        { name: 'Tableau de bord' },
      ],
    },
  },
];
