import { Store } from 'vuex';

import AbstractRepository from '@/lib/repository-factory/AbstractRepository';
import Exposition from '@models/firm/Exposition';

export default class ExpositionRepository extends AbstractRepository<Exposition> {
  query = {
    get: '',
    all: 'api_get_firm_expositions',
    create: '',
    update: '',
    delete: '',
  };

  constructor(store: Store<any>) {
    super(store, Exposition);
    Exposition.setRepository(this);
  }
}
