import { Fields, Model } from '@vuex-orm/core';

class Contract extends Model {
  static entity = 'contract';

  static fields(): Fields {
    return {
      id: this.number(null),
      code: this.string(''),
      name: this.string(''),
      deletedAt: this.date(null).nullable(),
    };
  }

  id!: number;
  code!: string;
  name!: string;
  deletedAt!: Date | null;

  get isCDD(): boolean {
    return this.name.toUpperCase().indexOf('CDD') !== -1;
  }
}

export default Contract;
