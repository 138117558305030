import { AbstractModule } from '@/types/modules';

import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faMinusSquare,
  faPlusSquare,
  faTrashAlt,
  faInfo,
} from '@fortawesome/free-solid-svg-icons';

import routes from './router';

import firmUpdate from './store/modules/firmUpdate';

import { FirmUpdateModuleInterface } from './store/modules/firmUpdate/types';

import declarationContainer from './ioc/declaration.container';

export default class DeclarationModule extends AbstractModule {
  install(options: any): void {
    this.router.addRoutes(routes);
    this.store.registerModule<FirmUpdateModuleInterface>('firmUpdate', firmUpdate);
    declarationContainer.build(this.container);

    library.add(faTrashAlt, faMinusSquare, faPlusSquare, faInfo);
  }
}
