import axios from 'axios';
import Routing from 'fos-routing';
import { injectable } from 'inversify';
import { plainToClass } from 'class-transformer';

import { JobClassificationServiceInterface } from '@/services/interfaces';
import JobClassification from '@models/firm/JobClassification';

@injectable()
export default class JobClassificationService implements JobClassificationServiceInterface {
  async search(q: string): Promise<Array<JobClassification>> {
    const response = await axios.get<JobClassification[]>(Routing.generate('api_get_firm_job_classification_search', { q }));
    return plainToClass(JobClassification, response.data);
  }

  async get(id: number): Promise<JobClassification> {
    const response = await axios.get<JobClassification>(Routing.generate('api_get_firm_job_classification', { id }));
    return plainToClass(JobClassification, response.data);
  }
}
