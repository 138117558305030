import store from '@/store';
import { RouteConfig } from 'vue-router';
import FirmUpdate from '@models/firm/FirmUpdate';
import moment from 'moment';

const MyDeclarations = () => import(/* webpackChunkName: "declaration_list" */'../pages/MyDeclarationListView.vue');
const MyEmployeesListView = () => (store.getters.isGranted('my-employees', 2)
  ? import(/* webpackChunkName: "employee" */'../pages/MyEmployeesListView.vue')
  : import(/* webpackChunkName: "employee" */'../pages/OrganizationView.vue'));
// const MyEmployeesListView = () => import(/* webpackChunkName: "employee" */'../pages/OrganizationView.vue');

const declarationRoutes: Array<RouteConfig> = [];

declarationRoutes.push({
  path: '/entreprise/mes-declarations',
  name: 'my-declarations',
  component: MyDeclarations,
  beforeEnter: (to, from, next) => {
    const firm = store.getters.currentFirm;
    store.dispatch('firmUpdate/getDeclaration', firm.id).then(() => {
      next();
    });
  },
  meta: {
    breadcrumb: [
      { name: 'Accueil', link: 'home', icon: 'home' },
      { name: 'Entreprise' },
      { name: 'Mes déclarations' },
    ],
    permission: 1,
  },
});

declarationRoutes.push({
  path: '/entreprise/mon-organigramme',
  name: 'my-employees',
  component: MyEmployeesListView,
  beforeEnter: (to, from, next) => {
    store.dispatch('repositories/exposition/findAll');
    next();
  },
  meta: {
    breadcrumb: [
      { name: 'Accueil', link: 'home', icon: 'home' },
      { name: 'Entreprise' },
      { name: 'Mon organigramme' },
    ],
    permission: 1,
    access: () => store.getters.currentFirm,
  },
});

export default declarationRoutes;
