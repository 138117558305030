import moment from 'moment';
import { Fields, Model } from '@vuex-orm/core';

import User from '@models/user/User';

class FirmUpdate extends Model {
  static entity = 'firmUpdate';

  static fields(): Fields {
    return {
      id: this.number(null),
      currentStep: this.number(null),
      finish: this.boolean(false),
      nbEmployee: this.number(null).nullable(),
      isDeclaration: this.boolean(false),
      firm: this.number(null),
      summary: this.string(null).nullable(),
      createdById: this.number(null).nullable(),
      createdBy: this.belongsTo(User, 'createdById'),
      createdAt: this.date(null).nullable(),
      updatedAt: this.date(null).nullable(),
    };
  }

  id!: number;
  currentStep!: number;
  finish!: boolean;
  nbEmployee!: number;
  isDeclaration!: boolean;
  firm!: number;
  summary!: string;
  createdById!: number | null;
  createdBy!: User | null;
  createdAt!: Date | null;
  updatedAt!: Date | null;

  get year() {
    return moment(this.createdAt).year();
  }
}

export default FirmUpdate;
