


















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class HomeBox extends Vue {
  @Prop({ default: 'grey', type: String }) color!: string;

  @Prop(String) title!: string;

  @Prop(Boolean) offset!: boolean;

  @Prop(Boolean) offset2!: boolean;

  @Prop({ default: 'info-circle', type: String }) icon!: string;
}
