import { Store } from 'vuex';
import axios from 'axios';
import Routing from 'fos-routing';

import AbstractRepository from '@/lib/repository-factory/AbstractRepository';
import Firm from '@models/firm/Firm';
import Unit from '@models/firm/Unit';

export default class FirmRepository extends AbstractRepository<Firm> {
  query = {
    get: 'api_get_firm_firm',
    all: '',
    create: '',
    update: '',
    delete: '',
  };

  currentFirmStructures: number = 0;

  constructor(store: Store<any>) {
    super(store, Firm);
    Firm.setRepository(this);
  }

  getCurrentRootWithChildren() {
    const id: number | null = this.store.state.UserModule.currentFirmId;

    return id ? Unit.query()
      .where('firmId', id)
      .where('lvl', 0)
      .withAllRecursive()
      .first() : [];
  }

  async findStructures(id: number) {
    // if (this.currentFirmStructures !== id) {
    const response = await axios.get(Routing.generate('api_get_firm_firm_organization', { id }));
    await Unit.insertOrUpdate({ data: response.data });
    this.currentFirmStructures = id;
    // }
  }

  async findCurrentStructures() {
    const id: number | null = this.store.state.UserModule.currentFirmId;

    if (id) {
      const response = await axios.get(Routing.generate('api_get_firm_firm_organization', { id }));
      await Unit.insertOrUpdate({ data: response.data });
      this.currentFirmStructures = id;
    }
  }
}
