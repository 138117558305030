import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import {
  Exclude,
  Expose,
  Transform,
  Type,
} from 'class-transformer';

@Exclude()
class Person {
  static entity = 'persons';
  static primaryKey = 'uuid';

  @Expose({ groups: ['all'] })
  new: boolean = true;

  @Expose({ groups: ['all'] })
  edit: boolean = false;

  @Expose({ notEmptyOnly: true })
  id: number | null = null;

  @Expose()
  tag: string | null = null;

  @Expose()
  uuid: string = uuidv4();

  @Expose()
  origin: string | null = null;

  @Expose()
  forename: string = '';

  @Expose()
  surname: string | null = null;

  @Expose()
  birthName: string = '';

  @Expose()
  sex: number | null = null;

  @Expose()
  numSecu: string | null = null;

  @Expose()
  validNoSS: boolean | null = false;

  @Expose()
  @Type(() => Date)
  @Transform(value => (value ? moment(value).format('YYYY-MM-DD') : null), { toPlainOnly: true })
  dateOfBirth: Date | null = null;

  get isSave() {
    return this.id !== null || this.origin !== null;
  }

  get isAdult() {
    return moment(this.dateOfBirth).isBefore(moment().subtract(12, 'years'));
  }

  get label() {
    let useName = '';
    if (this.surname) {
      useName = this.surname.toUpperCase();
    } else if (this.birthName) {
      useName = this.birthName.toUpperCase();
    } else {
      useName = 'Aucun nom';
    }
    const forename = this.forename ? ` ${this.forename}` : ' sans prénom';
    const birthName = this.surname && this.birthName ? ` (né(e) ${this.birthName.toUpperCase()})` : '';

    return useName + forename + birthName;
  }
}

export default Person;
