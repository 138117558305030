import { Fields, Model } from '@vuex-orm/core';

import MenuItem from './MenuItem';

class Menu extends Model {
  static entity = 'menu';
  static primaryKey = 'code';

  static fields(): Fields {
    return {
      code: this.string(''),
      items: this.hasMany(MenuItem, 'menu'),
    };
  }

  code!: string;
  items!: Array<MenuItem>;
}

export default Menu;
