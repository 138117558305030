import moment from 'moment';
import {
  Exclude,
  Expose,
  Transform,
  Type,
} from 'class-transformer';
import { Fields, Model } from '@vuex-orm/core';

import Job from '@models/firm/Job';
import Employee from '@models/firm/Employee';

@Exclude()
class EmployeeHasJob extends Model {
  static entity = 'employeeHasJob';
  static primaryKey = 'uuid';

  static fields(): Fields {
    return {
      new: this.boolean(true),
      edit: this.boolean(false),
      id: this.number(null).nullable(),
      uuid: this.string(null),
      employeeUuid: this.string(null),
      tag: this.string(null).nullable(),
      jobUuid: this.string(null),
      beginDate: this.date(''),
      endDate: this.date(null).nullable(),
      employee: this.belongsTo(Employee, 'employeeUuid'),
      job: this.belongsTo(Job, 'jobUuid'),
    };
  }

  @Expose({ groups: ['all'] })
  new!: boolean;

  @Expose({ groups: ['all'] })
  edit!: boolean;

  @Expose({ notEmptyOnly: true })
  id!: number | null;

  @Expose()
  tag!: string | null;

  @Expose()
  uuid!: string;

  @Expose({ groups: ['all'] })
  employeeUuid!: string;

  @Expose({ groups: ['all'] })
  jobUuid!: string;

  @Expose()
  @Type(() => Date)
  @Transform(value => moment(value).format('YYYY-MM-DDTHH:mm:ss'), { toPlainOnly: true })
  beginDate!: Date | null;

  @Expose()
  @Type(() => Date)
  @Transform(value => (value ? moment(value).format('YYYY-MM-DDTHH:mm:ss') : null), { toPlainOnly: true })
  endDate!: Date | null;

  @Expose()
  @Transform(value => (value ? value.id : null), { toPlainOnly: true })
  job!: Job;

  employee!: Employee;

  get isSave(): boolean {
    return this.id !== null;
  }

  get isActive(): boolean {
    return this.endDate === null || this.endDate > TODAY;
  }
}

export default EmployeeHasJob;
