













import { Component, Vue } from 'vue-property-decorator';
import MenuComponent from '@/components/menu/MenuComponent.vue';
import Menu from '@/entity/menu/Menu';

@Component({
  components: {
    CMenu: MenuComponent,
  },
})
export default class SidebarMenu extends Vue {
  menuOpen: Boolean = true;

  toggleMenu() {
    this.menuOpen = !this.menuOpen;
    const body = document.querySelector('body')!;
    body.classList.toggle('page-sidebar-closed');
  }

  get menu(): Menu {
    return Menu.query().where('code', 'sidebar').withAllRecursive().first() as Menu;
  }
}
