import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import NProgress from 'nprogress';

import cancelToken from '@/api/cancelToken';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [],
});

router.beforeResolve((to, from, next) => {
  if (to.name) {
    NProgress.start();
  }
  next();
});

router.beforeEach(async (to, from, next) => {
  if (!store.state.init) {
    await store.dispatch('init');
    store.commit('SET_INIT', true);
  }
  cancelToken.cancel();

  if (to.meta && typeof to.meta.access !== 'undefined') {
    const access = typeof to.meta.access === 'function' ? to.meta.access() : to.meta.access;
    if (!access) {
      next(false);
      return;
    }

    if (to.meta.permission) {
      const lvl = to.meta.permission;
      const group = to.meta.group || to.name;

      if (!store.getters.isGranted(group, lvl)) {
        next(false);
        return;
      }
    }
  }

  next();
});

router.afterEach((to, from) => {
  NProgress.done();
});

export default router;
