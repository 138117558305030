import { Exclude, Expose, Type } from 'class-transformer';

import UserModel from '@/store/models/UserModel';
import FirmPermission from '@models/user/FirmPermission';

@Exclude()
class User extends UserModel {
  @Expose({ toClassOnly: true })
  id!: number;

  @Expose()
  firstName!: string;

  @Expose()
  lastName!: string;

  @Expose()
  email!: string;

  @Expose()
  role!: string;

  @Expose({ toClassOnly: true })
  @Type(() => FirmPermission)
  permissions!: FirmPermission[];

  get fullName(): string {
    return `${this.firstName} ${this.lastName}`;
  }
}

export default User;
