import axios from 'axios';
import Routing from 'fos-routing';
import routes from './routes.json';

axios.defaults.withCredentials = true;
Routing.setData(routes);
Routing.setBaseUrl(window.EMPR1TE_URL);

axios.interceptors.response.use(response => response, (error): any => {
  const originalRequest = error.config;
  if (error.response && (error.response.status === 401 || error.response.status === 503) && !originalRequest.retry) {
    originalRequest.retry = true;
    localStorage.removeItem('empr1te_expire_token');
    // window.redirectToEmpr1te();
  }

  return Promise.reject(error);
});
