import * as Vuex from 'vuex';
import RepositoryFactory from '@/lib/repository-factory/RepositoryFactory';

export default {
  install(factory: RepositoryFactory): Vuex.Plugin<any> {
    return (store) => {
      factory.bindStore(store);
    };
  },
};
