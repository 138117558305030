import Unit from '@models/firm/Unit';
import { Criticality, CRITICALITY_UNDEFINED } from '@modules/professional-risk/types/risk';

export default class UnitMatrix {
  unit: Unit;

  quotations: Map<number, Criticality> = new Map();

  constructor(unit: Unit) {
    this.unit = unit;
  }

  getQuotation(character: number): Criticality {
    return this.quotations.get(character) || CRITICALITY_UNDEFINED;
  }

  setQuotation(character: number, quotation: Criticality) {
    if (this.hasQuotation(character)) {
      const currentQuotation = this.quotations.get(character);
      if (!currentQuotation || currentQuotation.quotationValue < quotation.quotationValue) {
        this.quotations.set(character, quotation);
      }
    } else {
      this.quotations.set(character, quotation);
    }
  }

  hasQuotation(character: number) {
    return this.quotations.has(character);
  }
}
