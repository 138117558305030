import { Fields, Model } from '@vuex-orm/core';
import Firm from '@models/firm/Firm';

class FirmMetaModel extends Model {
  static entity = 'firmMeta';

  static fields(): Fields {
    return {
      id: this.number(null),
      key: this.string(null).nullable(),
      value: this.string(null).nullable(),
      firm: this.belongsTo(Firm, 'firmId', 'id'),
      firmId: this.number(null),
    };
  }
}

export default FirmMetaModel;
