import { GetterTree } from 'vuex';
import clone from 'lodash/cloneDeep';

import { FirmUpdateModuleInterface } from '@modules/declaration/store/modules/firmUpdate/types';
import Employee from '@modules/declaration/entities/Employee';
import Unit from '@modules/declaration/entities/Unit';
import EmployeeHasJob from '@modules/declaration/entities/EmployeeHasJob';
import moment from 'moment';

export default {
  getItem: state => (uuid: string, type: 'employees' | 'units' | 'jobs') => clone(state[type][uuid]),
  getEmployeeWithPerson: state => (uuid: string) => {
    const e = clone(state.employees[uuid]);
    if (!e) {
      return null;
    }

    if (e.personUuid) {
      e.person = state.persons[e.personUuid];
    }

    return e;
  },
  getEmployeeWithInfos: (state, getters) => (uuid: string) => {
    const e = getters.getEmployeeWithPerson(uuid) as Employee | null;
    if (!e) {
      return null;
    }

    e.employeeHasJobsUuid.forEach((ehjUuid) => {
      e.employeeHasJobs.push(state.employeeHasJobs[ehjUuid]);
    });
    e.employeeHasContractsUuid.forEach((ehcUuid) => {
      e.employeeHasContracts.push(state.employeeHasContracts[ehcUuid]);
    });
    e.employeeHasExpositionsUuid.forEach((eheUuid) => {
      e.expositions.push(state.employeeHasExpositions[eheUuid]);
    });

    return e;
  },

  getUnit: state => (uuid: string) => clone(state.units[uuid]),
  getJob: state => (uuid: string) => clone(state.jobs[uuid]),
  getEmployee: state => (uuid: string) => clone(state.employees[uuid]),
  getEmployeeHasJob: state => (uuid: string) => clone(state.employeeHasJobs[uuid]),

  getActiveEmployees: (state, getters) => (firmId: number) => Object.keys(state.employees).filter((key) => {
    const e = state.employees[key];
    return e.firmId === firmId && e.toShow;
  }).map(k => getters.getEmployeeWithPerson(k)),
  getCurrentEmployees: (state, getters) => (firmId: number) => Object.keys(state.employees).filter((key) => {
    const e = state.employees[key];
    return e.firmId === firmId && e.isActive;
  }).map(k => getters.getEmployeeWithPerson(k)),
  getNbEmployee1J: (state, getters) => (firmId: number, year: number) => {
    const employees: Employee[] = getters.getActiveEmployees(firmId);
    const firstJanuary = moment(`${year}-01-01`);

    return employees.filter((e) => {
      const beginDate = moment(e.beginDate);
      const endDate = (e.endDate) ? moment(e.endDate) : null;

      return beginDate.isSameOrBefore(firstJanuary) && (endDate === null || endDate.isSameOrAfter(firstJanuary));
    }).length;
  },
  getNotAffectedEmployees: (state, getters) => (firmId: number) => {
    const employees: Employee[] = getters.getActiveEmployees(firmId);

    const notAffected: Employee[] = [];
    employees.forEach((e) => {
      let active = false;
      e.employeeHasJobsUuid.forEach((ehjUuid) => {
        const ehj = state.employeeHasJobs[ehjUuid];
        active = active || ehj.isActive;
      });

      if (!active && e.isActive) {
        notAffected.push(e);
      }
    });

    return notAffected;
  },
  getEmployeeWithoutSS: (state, getters) => (firmId: number) => {
    const employees: Employee[] = getters.getActiveEmployees(firmId);

    return employees.filter(e => !e.person.numSecu && !e.person.validNoSS);
  },

  getEmployeeHasJobsByJob: state => (jobUuid: string) => {
    const job = state.jobs[jobUuid];
    return job.employeeHasJobsUuid.map(k => state.employeeHasJobs[k]);
  },
  getCurrentEmployeeHasJobsByJob: (state, getters) => (jobUuid: string) => {
    const employeeHasJobs: EmployeeHasJob[] = getters.getEmployeeHasJobsByJob(jobUuid);
    return employeeHasJobs.filter(ehj => ehj.isActive);
  },

  getEmployeesCurrentFirm: (state, getters, rootState) => {
    const { currentFirmId } = rootState.UserModule;
    const employeesUuid = Object.keys(state.employees).filter(k => state.employees[k].firmId === currentFirmId);
    return employeesUuid.map(e => getters.getEmployeeWithInfos(e));
  },
  getUnitsWithChildrenCurrentFirm: (state, getters, rootState) => {
    const { currentFirmId } = rootState.UserModule;
    return getters.getRootFirmWithChildren(currentFirmId, true);
  },

  getRootCurrentFirm: (state, getters, rootState) => {
    const { currentFirmId } = rootState.UserModule;
    return getters.getRootFirm(currentFirmId);
  },
  getRootWithChildrenCurrentFirm: (state, getters, rootState) => {
    const { currentFirmId } = rootState.UserModule;
    return getters.getRootFirmWithChildren(currentFirmId);
  },
  getRootFirm: state => (firmId: number) => {
    const rootKey = Object.keys(state.units).find(k => state.units[k].firmId === firmId && state.units[k].isRoot);
    if (rootKey) {
      return clone(state.units[rootKey]);
    }

    return null;
  },
  getRootFirmWithChildren: (state, getters) => (firmId: number, full: boolean = false) => {
    const root = getters.getRootFirm(firmId) as Unit | null;
    if (!root) {
      return null;
    }

    getters.hydrateUnitWithChildren(root, full);
    return root;
  },
  hydrateUnitWithChildren: (state, getters) => (unit: Unit, full: boolean = false) => {
    if (!unit) {
      return;
    }

    unit.childrenUuid.forEach((childUuid) => {
      const child = clone(state.units[childUuid]);
      if (child.isActive || full) {
        getters.hydrateUnitWithChildren(child, full);
        unit.children.push(child);
      }
    });

    unit.jobsUuid.forEach((jobUuid) => {
      const job = clone(state.jobs[jobUuid]);
      if (full) {
        job.employeeHasJobsUuid.forEach((ehjUuid) => {
          job.employeeHasJobs.push(state.employeeHasJobs[ehjUuid]);
        });
      }
      if (job.isActive || full) {
        unit.jobs.push(job);
      }
    });
  },

  getRemovedUnits: state => (firmId: number) => Object.keys(state.units).filter((key) => {
    const unit = state.units[key];
    const hasParent = unit.parentUuid !== null ? state.units[unit.parentUuid] !== undefined : true;
    return hasParent && state.units[key].firmId === firmId && state.units[key].remove && !state.units[key].new && !state.units[key].edit;
  }).map(k => state.units[k]),
  getRemovedJobs: state => (firmId: number) => Object.keys(state.jobs).filter(key => state.jobs[key].firmId === firmId && state.jobs[key].remove && !state.jobs[key].new && !state.jobs[key].edit).map(k => state.jobs[k]),
  getEditedUnits: state => (firmId: number) => Object.keys(state.units).filter((key) => {
    const unit = state.units[key];
    const hasParent = unit.parentUuid !== null ? state.units[unit.parentUuid] !== undefined : true;
    return hasParent && state.units[key].firmId === firmId && (state.units[key].edit || state.units[key].new) && !state.units[key].remove;
  }).map(k => state.units[k]),
  getEditedUnitsCurrentFirm: (state, getters, rootState) => {
    const { currentFirmId } = rootState.UserModule;
    return getters.getEditedUnits(currentFirmId);
  },
  getRemovedUnitsCurrentFirm: (state, getters, rootState) => {
    const { currentFirmId } = rootState.UserModule;
    return getters.getRemovedUnits(currentFirmId);
  },
  getRemovedJobsCurrentFirm: (state, getters, rootState) => {
    const { currentFirmId } = rootState.UserModule;
    return getters.getRemovedJobs(currentFirmId);
  },
  hasEditedUnitsCurrentFirm: (state, getters) => getters.getEditedUnitsCurrentFirm.length > 0,
  hasRemovedUnitsCurrentFirm: (state, getters) => getters.getRemovedUnitsCurrentFirm.length > 0,
  hasRemovedJobsCurrentFirm: (state, getters) => getters.getRemovedJobsCurrentFirm.length > 0,
  getActiveJobs: state => (firmId: number) => Object.keys(state.jobs).filter((k) => {
    const job = state.jobs[k];
    return job.firmId === firmId && job.isActive;
  }).map(k => state.jobs[k]),
  getActiveJobsCurrentFirm: (state, getters, rootState) => {
    const { currentFirmId } = rootState.UserModule;
    return getters.getActiveJobs(currentFirmId);
  },
  getEditedJobs: state => (firmId: number) => Object.keys(state.jobs).filter((key) => {
    const job = state.jobs[key];
    const unit = state.units[job.unitUuid];

    return unit.firmId === firmId && (job.edit || job.new);
  }).map(k => state.jobs[k]),
  getEditedJobsCurrentFirm: (state, getters, rootState) => {
    const { currentFirmId } = rootState.UserModule;
    return getters.getEditedJobs(currentFirmId);
  },
  hasEditedJobsCurrentFirm: (state, getters) => getters.getEditedJobsCurrentFirm.length > 0,

  getEditedEmployees: state => (firmId: number) => Object.keys(state.employees).filter((key) => {
    const e = state.employees[key];
    if (e.firmId !== firmId) {
      return false;
    }

    return e.new || e.edit;
  }).map(k => state.employees[k]),
  getEditedEmployeesCurrentFirm: (state, getters, rootState) => {
    const { currentFirmId } = rootState.UserModule;
    return getters.getEditedEmployees(currentFirmId);
  },
  hasEditedEmployeesCurrentFirm: (state, getters) => getters.getEditedEmployeesCurrentFirm.length > 0,

  getVersionJob: state => (reference: string, version: number) => {
    const key = Object.keys(state.jobs).find((k) => {
      const job = state.jobs[k];
      return (job.reference === reference || job.uuid === reference) && job.version === version;
    });
    return key !== undefined ? state.jobs[key] : undefined;
  },
} as GetterTree<FirmUpdateModuleInterface, any>;
