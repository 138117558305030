import { FirmItemType, FirmStructureItem } from '@/types/firm/firm';
import { Exclude, Expose } from 'class-transformer';

import { FirmStructure, Job } from './FirmStructureHierarchy';

@Exclude()
export class Unit extends FirmStructure implements FirmStructureItem {
  static entity = 'units';
  static primaryKey = 'uuid';

  @Expose()
  disc: string = 'Unit';

  type: FirmItemType = FirmItemType.Unit;

  @Expose({ groups: ['all'] })
  parentUuid: string | null = null;

  @Expose({ name: 'parent', toPlainOnly: true })
  parentId: number | null = null;

  @Expose()
  lft: number = 0;

  @Expose()
  rgt: number = 1;

  @Expose()
  lvl: number = 0;

  @Expose({ groups: ['all'] })
  jobs: Job[] = [];
  jobsUuid: string[] = [];

  @Expose({ groups: ['all'] })
  children: Unit[] = [];
  childrenUuid: string[] = [];

  remove: boolean = false;

  get isSave(): boolean {
    return this.id !== null;
  }

  get isRoot(): boolean {
    return this.lvl === 0;
  }
}

export default Unit;
