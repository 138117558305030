import { Container } from 'inversify';
import getDecorators from 'inversify-inject-decorators';

import * as Services from '@/services/interfaces';
import JobClassificationService from '@/services/JobClassificationService';
import PageService from '@/services/PageService';
import EmployeeService from '@/services/EmployeeService';
import FirmRoleService from '@/services/user/FirmRoleService';

import SERVICE_IDENTIFIER from './identifiers';

const container = new Container();

container.bind<Services.JobClassificationServiceInterface>(SERVICE_IDENTIFIER.JobClassificationServiceInterface).to(JobClassificationService);
container.bind<Services.IPageService>(SERVICE_IDENTIFIER.IPageService).to(PageService).inSingletonScope();
container.bind<Services.EmployeeServiceInterface>(SERVICE_IDENTIFIER.EmployeeServiceInterface).to(EmployeeService);
container.bind<Services.FirmRoleServiceInterface>(SERVICE_IDENTIFIER.FirmRoleServiceInterface).to(FirmRoleService).inSingletonScope();

const { lazyInject } = getDecorators(container);

export { container, lazyInject, SERVICE_IDENTIFIER };
