import { Fields, Model } from '@vuex-orm/core';

import RiskCharacter from '@models/proRisk/RiskCharacter';

class Thesaurus extends Model {
  static entity = 'thesaurus';

  static fields(): Fields {
    return {
      id: this.number(null),
      reference: this.string(''),
      content: this.string(''),
      riskCharacterId: this.number(null),
      riskCharacter: this.belongsTo(RiskCharacter, 'riskCharacterId'),
    };
  }

  id!: number;
  reference!: string;
  content!: string;
  riskCharacterId!: number;
  riskCharacter!: RiskCharacter | null;
}

export default Thesaurus;
