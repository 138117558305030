import VuexORM from '@vuex-orm/core';
import datePlugin from 'vuex-orm-plugin-date-attribute';
import RepositoryVuexORMPlugin from '@/lib/repository-factory/RepositoryVuexORMPlugin';

VuexORM.use(datePlugin);
VuexORM.use(RepositoryVuexORMPlugin);

const database = new VuexORM.Database();

const models = require.context('@models', true, /.ts/);
models.keys().forEach((v) => {
  const model = models(v);
  database.register(model.default);
});

export default database;
