import Vue from 'vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faUser,
  faUsers,
  faMedkit,
  faList,
  faStethoscope,
  faFlask,
  faHome,
  faFolderOpen,
  faLightbulb,
  faKey,
  faArrowLeft,
  faInfoCircle,
  faAngleUp,
  faDownload,
  faPlus,
  faSearch,
  faRoad,
  faTachometerAlt,
  faCheck,
  faFilePdf,
  faQuestion,
  faFile,
  faBan,
  faArrowsAlt,
  faAngleRight,
  faPencilAlt,
  faExclamation,
  faEdit,
  faTimes,
  faEye,
  faTrashAlt,
  faInfo,
  faArrowAltCircleUp,
  faArrowAltCircleDown,
  faHdd,
  faTrash,
  faCopy,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(
  faAngleRight,
  faUser,
  faUsers,
  faMedkit,
  faList,
  faStethoscope,
  faFlask,
  faHome,
  faFolderOpen,
  faLightbulb,
  faKey,
  faArrowLeft,
  faInfoCircle,
  faAngleUp,
  faDownload,
  faPlus,
  faSearch,
  faRoad,
  faTachometerAlt,
  faCheck,
  faFilePdf,
  faQuestion,
  faFile,
  faBan,
  faArrowsAlt,
  faPencilAlt,
  faExclamation,
  faTrashAlt,
  faEdit,
  faInfo,
  faArrowAltCircleUp,
  faArrowAltCircleDown,
  faHdd,
  faTimes,
  faTrash,
  faEye,
  faCopy,
);

Vue.component('font-awesome-icon', FontAwesomeIcon);
