import { injectable } from 'inversify';
import moment from 'moment';

import { FirmItemType, FirmStructureItem } from '@/types/firm/firm';

import Unit from '@modules/declaration/entities/Unit';
import Job from '@modules/declaration/entities/Job';
import EmployeeHasJob from '@modules/declaration/entities/EmployeeHasJob';

import { FirmListItem } from '../../types/firm-list';
import FirmUtils from '../../utils/FirmUtils';

import { FirmListServiceInterface } from '../FirmListServiceInterface';

@injectable()
export default class FirmListService implements FirmListServiceInterface {
  convertStructureToList(root: FirmStructureItem, listType?: Array<FirmItemType>): Array<FirmListItem> {
    const list: Array<FirmListItem> = [];
    this.initList(list, [root], listType || []);

    return list;
  }

  private initList(list: Array<FirmListItem>, children: Array<FirmStructureItem>, listType: Array<FirmItemType>) {
    children.forEach((child) => {
      if (listType.length > 0 && listType.indexOf(child.type) === -1) {
        return;
      }
      const item = {
        id: child.uuid,
        type: child.type,
        uuid: child.uuid,
        label: child.label,
        remove: child.remove,
      } as FirmListItem;

      if (child.type === FirmItemType.Unit) {
        item.children = [];

        const unit = child as Unit;
        this.initList(item.children, unit.children, listType);
        this.initList(item.children, unit.jobs, listType);
      } else if (child.type === FirmItemType.Job && (listType.indexOf(FirmItemType.Employee) !== -1 || listType.length === 0)) {
        item.children = [];

        const job = child as Job;
        this.initEmployees(item.children, job.employeeHasJobs);
      }

      list.push(item);
    });

    list.sort(FirmUtils.sortItem);
  }

  private initEmployees(list: Array<FirmListItem>, children: Array<EmployeeHasJob>) {
    children.forEach((employeeHasJob) => {
      const { uuid } = employeeHasJob;

      if (employeeHasJob.endDate !== null && moment(employeeHasJob.endDate).isBefore(moment())) {
        return;
      }

      const { employee } = employeeHasJob;
      if (!employee) {
        return;
      }

      const item = {
        id: employee.uuid,
        type: FirmItemType.Employee,
        uuid: employee.uuid,
        label: employee.label,
        ruuid: uuid,
      };
      list.push(item);
    });

    list.sort(FirmUtils.sortItem);
  }
}
