



























































import { Component, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

import Firm from '@models/firm/Firm';
import User from '@models/user/User';
import Menu from '@models/menu/Menu';
import Group from '@models/firm/Group';

@Component
export default class Header extends Vue {
  @Getter('currentFirm')
  currentFirm!: Firm;

  @Getter('currentGroup')
  currentGroup!: Group | null;

  @Getter('connectedUser')
  connectedUser!: User;

  @Getter('userActions')
  menu!: Menu;
}
