import { FirmItemType } from '@/types/firm/firm';
import { FirmListItem } from '../types/firm-list';

export default class FirmUtils {
  static sortItem(a: FirmListItem, b: FirmListItem) {
    const typeRankA = a.type === FirmItemType.Unit ? 0 : 1;
    const typeRankB = b.type === FirmItemType.Unit ? 0 : 1;

    if (typeRankA < typeRankB) {
      return -1;
    }

    if (typeRankA > typeRankB) {
      return 1;
    }

    return a.label.localeCompare(b.label);
  }
}
