















import { Vue, Component, Prop } from 'vue-property-decorator';
import Breadcrumb from '@/components/Breadcrumb.vue';

@Component({
  components: {
    Breadcrumb,
  },
})
export default class LayoutDefault extends Vue {
  @Prop({ type: Array, default: () => [] })
  readonly actions!: Array<any>;

  @Prop({ type: Object, default: () => {} })
  readonly printAction!: Object;

  @Prop({ type: Object, default: () => {} })
  readonly helpAction!: Object;
}
