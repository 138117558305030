import { Fields, Model } from '@vuex-orm/core';

import Exposition from '@models/firm/Exposition';
import CodeNafHasExposition from '@models/firm/CodeNafHasExposition';

class CodeNaf extends Model {
  static entity = 'codeNaf';

  static fields(): Fields {
    return {
      id: this.number(null),
      code: this.string(''),
      name: this.string(''),
      expositions: this.belongsToMany(Exposition, CodeNafHasExposition, 'codeNafId', 'expositionId'),
    };
  }

  id!: number;
  code!: string;
  name!: string;
  expositions!: Array<Exposition> | null;
}

export default CodeNaf;
