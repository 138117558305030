import { Fields, Model } from '@vuex-orm/core';

import Painfulness from '@models/proRisk/Painfulness';

class PainfulnessQuestion extends Model {
  static entity = 'painfulnessQuestion';

  static fields(): Fields {
    return {
      id: this.number(false),
      question: this.string(false),
      type: this.number(false),
      responseCriteria: this.number(false),
      response: this.string(false),
      painfulness: this.hasOne(Painfulness, 'id'),
    };
  }

  id!: number;
  question!: string;
  type!: number;
  responseCriteria!: number;
  response!: string;
  painfulness!: Painfulness;
}

export default PainfulnessQuestion;
