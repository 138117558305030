import moment from 'moment';
import { Exclude, Expose, Transform } from 'class-transformer';
import { Fields } from '@vuex-orm/core';

import { FirmItemType, FirmStructureItem } from '@/types/firm/firm';

import { FirmStructure, Unit } from '@/store/models/firm/FirmStructureHierarchy';
import EmployeeHasJob from '@models/firm/EmployeeHasJob';

@Exclude()
export class Job extends FirmStructure implements FirmStructureItem {
  static entity = 'job';
  static primaryKey = 'uuid';
  static baseEntity = 'firmStructure';

  static fields(): Fields {
    return {
      ...super.fields(),
      disc: this.string('Job'),
      reference: this.string(''),
      version: this.number(1),
      description: this.string(null).nullable(),
      type: this.string('job'),
      unitUuid: this.string(''),
      classification: this.number(null).nullable(),
      expositions: this.attr([]),
      unit: this.belongsTo(Unit, 'unitUuid'),
      employeeHasJobs: this.hasMany(EmployeeHasJob, 'jobUuid'),
    };
  }

  @Expose({ groups: ['all'] })
  type!: FirmItemType;

  @Expose()
  reference!: string;

  @Expose()
  version!: number;

  @Expose()
  description!: string | null;

  @Expose()
  classification!: number | null;

  @Expose()
  expositions!: Array<number>;

  @Expose({ groups: ['all'] })
  unitUuid!: string;

  @Expose()
  @Transform(value => (value ? value.id : null), { toPlainOnly: true })
  unit!: Unit;

  employeeHasJobs!: Array<EmployeeHasJob>;

  remove: boolean = false;

  @Expose({ groups: ['all'] })
  isOldJob: boolean = false;

  get isSave() {
    return this.id !== null;
  }

  get isActive() {
    return true;
  }

  get parentUuid(): string {
    return this.unitUuid;
  }

  set parentUuid(uuid: string) {
    this.unitUuid = uuid;
  }
}

export default Job;
