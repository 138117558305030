import { GetterTree } from 'vuex';
import clone from 'lodash/cloneDeep';

import { FirmModuleState } from '@/store/modules/firm/types';

import Unit from '@models/firm/Unit';
import Job from '@models/firm/Job';
import Exposition from '@models/firm/Exposition';
import Firm from '@models/firm/Firm';
import Contract from '@models/firm/Contract';
import SharedFile from '@models/firm/SharedFile';

export default {
  getJobExpositions: () => Exposition.query().where('forEmployee', false).all(),
  getEmployeeExpositions: () => Exposition.query().where('forEmployee', true).all(),
  getFirmExpositions: () => (id: number) => {
    const firm: Firm | null = Firm.query().where('id', id).with('codeNaf.expositions').first();
    return firm !== null && firm.codeNaf !== null ? firm.codeNaf.expositions || [] : [];
  },

  getVisibleContracts: () => Contract.query().where('deletedAt', null).get(),
  getSharedFiles: (state): SharedFile[] => state.sharedFiles,
  getJobs: (state): Job[] => state.structures.filter(s => s.disc === 'Job') as Job[],
  getUnits: (state): Unit[] => state.structures.filter(s => s.disc === 'Unit') as Unit[],
  getOrganization: (state, getters): Unit[] => {
    function hydrateUnit(unit: Unit) {
      const units = clone(getters.getUnits);
      unit.children = units.filter((u: Unit) => u.parentUuid === unit.uuid);
      unit.children.forEach(child => hydrateUnit(child));
      const jobs = clone(getters.getJobs);
      unit.jobs = jobs.filter((j: Job) => j.unitUuid === unit.uuid);
    }

    const units = clone(getters.getUnits) as Unit[];
    const firmRoots = units.filter(u => u.lvl === 0);
    firmRoots.forEach((r) => {
      hydrateUnit(r);
    });

    return firmRoots;
  },
} as GetterTree<FirmModuleState, any>;
