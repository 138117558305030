import { GetterTree } from 'vuex';

import ProFolder from '@models/proRisk/ProFolder';

import { RiskProModuleInterface } from './types';

export default {
  getActiveProFolder: state => (state.activeProFolder !== null ? ProFolder.query().withAllRecursive().find(state.activeProFolder) : null),

  hasActiveProFolder: (state, getters) => !!getters.getActiveProFolder,

  validActiveProFolderStatus: (state, getters, rootState) => (status: Array<number>) => {
    if (state.activeProFolder !== 0) {
      // @ts-ignore
      const proFolder = ProFolder.query().withAllRecursive().find(state.activeProFolder);
      if (proFolder && status.includes(proFolder.status)) {
        return true;
      }
    }
    return false;
  },
} as GetterTree<RiskProModuleInterface, any>;
