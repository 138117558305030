import axios from 'axios';
import Routing from 'fos-routing';
import { injectable } from 'inversify';
import { plainToClass } from 'class-transformer';

import { EmployeeServiceInterface } from '@/services/interfaces';
import Employee from '@models/firm/Employee';
import Person from '@models/firm/Person';

@injectable()
export default class EmployeeService implements EmployeeServiceInterface {
  async search(q: string, firmId: number): Promise<Employee[]> {
    const employees = Employee.query()
      .where('firmId', firmId).whereHas('person', (query) => {
        query.where((person: Person) => person.label.toLowerCase().includes(q.toLowerCase()));
      })
      .with('person')
      .get();

    const route = Routing.generate('api_get_employee_search', { q });
    const response = await axios.get<Employee[]>(route);
    const remoteEmployees = response.data ? plainToClass(Employee, response.data) : [];

    const employeesUuid = employees.map(employee => employee.uuid);
    remoteEmployees.forEach((remoteEmployee) => {
      if (employeesUuid.indexOf(remoteEmployee.uuid) === -1) {
        remoteEmployee.firmId = firmId;
        employees.push(remoteEmployee);
      }
    });

    return employees;
  }

  async get(uuid: string, firmId: number): Promise<Employee> {
    let employee: Employee = Employee.query().where('firmId', firmId).with(['person', 'employeeHasJobs']).find(uuid) as Employee;
    if (employee === null) {
      const route = Routing.generate('api_get_employee', { uuid });
      const response = await axios.get<Employee>(route);

      if (response.data) {
        employee = plainToClass(Employee, response.data);
        employee.firmId = firmId;
        await Employee.insert({ data: employee });
      }
    }

    return employee;
  }
}
