import Vue from 'vue';
import {
  Alert,
  Button,
  Dialog,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Upload,
  Input,
  Radio,
  RadioGroup,
  Table,
  TableColumn,
  TabPane,
  Tabs,
  Form,
  FormItem,
  Select,
  Option,
  OptionGroup,
  Checkbox,
  CheckboxGroup,
  DatePicker,
  Tooltip,
  Pagination,
  Tree,
  Tag,
  Transfer,
  Message,
  MessageBox,
  Loading,
  Icon,
  Popover,
} from 'element-ui';
import lang from 'element-ui/lib/locale/lang/fr';
import locale from 'element-ui/lib/locale';
import VueDataTables from 'vue-data-tables';

locale.use(lang);

Vue.use(Alert);
Vue.use(Button);
Vue.use(Dialog);
Vue.use(Dropdown);
Vue.use(DropdownItem);
Vue.use(DropdownMenu);
Vue.use(Upload);
Vue.use(Input);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(TabPane);
Vue.use(Tabs);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Select);
Vue.use(Option);
Vue.use(OptionGroup);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(DatePicker);
Vue.use(Tooltip);
Vue.use(Popover);
Vue.use(Pagination);
Vue.use(Tree);
Vue.use(Transfer);
Vue.use(Tag);
Vue.use(Icon);
Vue.use(VueDataTables);

Vue.use(Loading.directive);

Vue.prototype.$loading = Loading.service;
Vue.prototype.$msgbox = MessageBox;
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$prompt = MessageBox.prompt;
Vue.prototype.$notify = Notification;
Vue.prototype.$message = Message;
