import { Module } from 'vuex';

import { FirmModuleState } from './types';

import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const FirmModule: Module<FirmModuleState, any> = {
  namespaced: true,
  state: {
    firmStructure: null,
    structures: [],
    sharedFiles: [],
  },
  mutations,
  getters,
  actions,
};

export default FirmModule;
