import { schema } from 'normalizr';
import Job from '@modules/declaration/entities/Job';

import employeeHasJob from './employeeHasJobSchema';

const job = new schema.Entity<Job>(Job.entity, {
  employeeHasJobs: [employeeHasJob],
  employeeHasJobsUuid: [employeeHasJob],
}, {
  processStrategy: (value, parent) => {
    value.employeeHasJobsUuid = value.employeeHasJobs;
    value.employeeHasJobs = [];

    value.firmId = value.firm;
    value.firm = null;

    value.unitId = value.unit || parent.id;
    value.unit = null;

    return Object.assign(new Job(), { ...value });
  },
  mergeStrategy: (entityA, entityB) => Object.assign(entityA, entityB),
  idAttribute: Job.primaryKey,
});

export default job;
