import { MutationTree } from 'vuex';

import FirmStructure from '@models/firm/FirmStructure';
import SharedFile from '@models/firm/SharedFile';
import { FirmModuleState } from '@/store/modules/firm/types';
import * as types from './mutation-types';

export default {
  [types.SET_FIRM_STRUCTURES](state, structure: FirmStructure): void {
    state.firmStructure = structure;
  },
  [types.SET_STRUCTURES](state, payload: FirmStructure[]): void {
    state.structures = payload;
  },
  [types.SET_SHARED_FILES](state, payload: SharedFile[]): void {
    state.sharedFiles = payload;
  },
} as MutationTree<FirmModuleState>;
