import { Fields, Model } from '@vuex-orm/core';

class JobClassification extends Model {
  static entity = 'jobClassification';

  static fields(): Fields {
    return {
      id: this.number(null),
      code: this.string(''),
      name: this.string(''),
      deletedAt: this.date(null).nullable(),
    };
  }

  id!: number;
  code!: string;
  name!: string;
  deletedAt!: Date | null;

  get isActive() {
    return this.deletedAt === null;
  }

  get label(): string {
    return `${this.code} - ${this.name}`;
  }
}

export default JobClassification;
