import axios, { CancelTokenSource, CancelTokenStatic, CancelToken as Token } from 'axios';

interface ICancelToken {
  token: Token;
}

class CancelToken implements ICancelToken {
  private __source: CancelTokenSource;
  axiosCancelToken: CancelTokenStatic;

  constructor() {
    this.axiosCancelToken = axios.CancelToken;
    this.__source = this.axiosCancelToken.source();
  }

  get source() {
    return this.__source;
  }

  get token() {
    return this.__source.token;
  }

  renew() {
    this.__source = this.axiosCancelToken.source();
  }

  cancel(msg?: string) {
    this.__source.cancel(msg || 'Operation canceled by the user.');
    this.renew();
  }
}

export default new CancelToken();
