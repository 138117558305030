import store from '@/store';

const inventory = () => import('../pages/inventory/InventoryView.vue');
const inventoryEdit = () => import('../pages/inventory/InventoryEditView.vue');

export default [
  {
    path: '/risques-chimiques/inventaire',
    name: 'chemi-risks-inventory',
    component: inventory,
    meta: {
      permission: 1,
      breadcrumb: [
        { name: 'Accueil', link: 'home', icon: 'home' },
        { name: 'Risques Chimiques', link: 'chemi-risks-dashboard' },
        { name: 'Inventaire' },
      ],
    },
  },
  {
    path: '/risques-chimiques/inventaire/creation',
    name: 'chemi-risks-inventory-add',
    component: inventoryEdit,
    beforeEnter: (to: any, from: any, next: () => void) => {
      const p1 = store.dispatch('cheRisk/loadPictograms');
      const p2 = store.dispatch('cheRisk/loadHazardStatements');
      Promise.all([p1, p2]).then(() => {
        next();
      });
    },
    meta: {
      permission: 2,
      group: 'chemi-risks-inventory',
      breadcrumb: [
        { name: 'Accueil', link: 'home', icon: 'home' },
        { name: 'Risques Chimiques', link: 'chemi-risks-dashboard' },
        { name: 'Inventaire', link: 'chemi-risks-inventory' },
        { name: 'Création' },
      ],
    },
  },
  {
    path: '/risques-chimiques/inventaire/edition/:id',
    name: 'chemi-risks-inventory-edit',
    component: inventoryEdit,
    beforeEnter: (to: any, from: any, next: () => void) => {
      const p1 = store.dispatch('cheRisk/loadProduct', to.params.id);
      const p2 = store.dispatch('cheRisk/loadPictograms');
      const p3 = store.dispatch('cheRisk/loadHazardStatements');
      Promise.all([p1, p2, p3]).then(() => {
        next();
      });
    },
    meta: {
      permission: 2,
      group: 'chemi-risks-inventory',
      breadcrumb: [
        { name: 'Accueil', link: 'home', icon: 'home' },
        { name: 'Risques Chimiques', link: 'chemi-risks-dashboard' },
        { name: 'Inventaire', link: 'chemi-risks-inventory' },
        { name: 'Édition' },
      ],
    },
  },
  {
    path: '/risques-chimiques/inventaire/delete/:id/:status',
    name: 'chemi-risks-inventory-delete',
    component: inventoryEdit,
    beforeEnter: (to: any, from: any, next: () => void) => {
      const p1 = store.dispatch('cheRisk/loadProduct', to.params.id);
      const p2 = store.dispatch('cheRisk/loadPictograms');
      const p3 = store.dispatch('cheRisk/loadHazardStatements');
      Promise.all([p1, p2, p3]).then(() => {
        next();
      });
    },
    meta: {
      permission: 2,
      group: 'chemi-risks-inventory',
      breadcrumb: [
        { name: 'Accueil', link: 'home', icon: 'home' },
        { name: 'Risques Chimiques', link: 'chemi-risks-dashboard' },
        { name: 'Inventaire', link: 'chemi-risks-inventory' },
        { name: 'Édition' },
      ],
    },
  },
];
