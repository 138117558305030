import axios from 'axios';
import { Store } from 'vuex';
import Routing from 'fos-routing';

import AbstractRepository from '@/lib/repository-factory/AbstractRepository';
import Thesaurus from '@models/proRisk/Thesaurus';

export default class ThesaurusRepository extends AbstractRepository<Thesaurus> {
  query = {
    get: 'api_get_pr_risk_character',
    all: 'api_get_all_pr_risk_character',
    create: '',
    update: '',
    delete: '',
  };

  alreadyCall: number[] = [];

  constructor(store: Store<any>) {
    super(store, Thesaurus);
    Thesaurus.setRepository(this);
  }

  getByCharacter(characterId: number) {
    return Thesaurus.query().where('riskCharacterId', characterId).get();
  }

  async findByCharacter(characterId: number) {
    if (!this.alreadyCall.includes(characterId)) {
      const { data } = await axios.get(Routing.generate('api_get_pr_risk_character_thesaurus', { id: characterId }));
      await Thesaurus.insertOrUpdate({ data });
      this.alreadyCall.push(characterId);
    }
  }

  async deleteAll() {
    this.alreadyCall = [];
    return super.deleteAll();
  }
}
