import Home from '@/views/Home.vue';
import firmRoutes from '@/router/firm';

const sharedFolderView = () => import('@/views/sharedFolder/SharedFolderView.vue');
const userGuideView = () => import('@/views/help/UserGuideView.vue');

export default [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      icon: 'home',
      breadcrumb: [
        { name: 'Accueil', icon: 'home' },
      ],
    },
  },
  {
    path: '/partage-de-fichiers',
    name: 'shared-folder',
    meta: {
      permission: 1,
      breadcrumb: [
        { name: 'Accueil', link: 'home', icon: 'home' },
        { name: 'Partage de fichiers', link: 'shared-folder' },
      ],
    },
    component: sharedFolderView,
  },
  {
    path: '/aide/mode-d-emploi',
    name: 'user-guide',
    meta: {
      permission: 1,
      breadcrumb: [
        { name: 'Accueil', link: 'home', icon: 'home' },
        { name: 'Aide' },
        { name: 'Mode d\'emploi', link: 'user-guide' },
      ],
    },
    component: userGuideView,
  },
  ...firmRoutes,
];
