import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import {
  Exclude,
  Expose,
  Transform,
  Type,
} from 'class-transformer';

import Contract from '@models/firm/Contract';
import Employee from './Employee';

@Exclude()
class EmployeeHasContract {
  static entity = 'employeeHasContracts';
  static primaryKey = 'uuid';

  @Expose({ groups: ['all'] })
  new: boolean = true;

  @Expose({ groups: ['all'] })
  edit: boolean = false;

  @Expose({ notEmptyOnly: true })
  id: number | null = null;

  @Expose()
  uuid: string = uuidv4();

  @Expose({ groups: ['all'] })
  employeeUuid: string = '';

  employee: Employee | null = null;

  @Expose({ name: 'contract', toPlainOnly: true })
  contractId: number | null = null;

  contract: Contract | null = null;

  @Expose()
  @Type(() => Date)
  @Transform(value => moment(value).format('YYYY-MM-DDTHH:mm:ss'), { toPlainOnly: true })
  dateStart: Date = new Date();

  @Expose()
  @Type(() => Date)
  @Transform(value => (value ? moment(value).format('YYYY-MM-DDTHH:mm:ss') : null), { toPlainOnly: true })
  dateEnd: Date | null = null;

  get isSave() {
    return this.id !== null;
  }

  get isActive() {
    return this.dateEnd === null || moment(this.dateEnd).isAfter(moment());
  }
}

export default EmployeeHasContract;
