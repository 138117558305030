import { Fields, Model } from '@vuex-orm/core';

class FirmContactModel extends Model {
  static entity = 'firmContact';

  static fields(): Fields {
    return {
      id: this.number(null),
      name: this.string(null).nullable(),
      contact: this.string(null).nullable(),
      email: this.string(null).nullable(),
      address: this.string(null).nullable(),
      postalCode: this.string(null).nullable(),
      city: this.string(null).nullable(),
      phone: this.string(null).nullable(),
      mobile: this.string(null).nullable(),
      fax: this.string(null).nullable(),
    };
  }
}

export default FirmContactModel;
