import moment from 'moment';
import { Fields, Model } from '@vuex-orm/core';
import { Expose, Transform, Type } from 'class-transformer';

import Firm from '@models/firm/Firm';
import { Job, Unit } from '@/store/models/firm/FirmStructureHierarchy';
import RiskHasFirmStructure from '@models/proRisk/RiskHasFirmStructure';
import Risk from '@models/proRisk/Risk';

export class FirmStructure extends Model {
  static entity = 'firmStructure';
  static primaryKey = 'uuid';
  static typeKey = 'disc';

  static types() {
    return {
      Job,
      Unit,
    };
  }

  static fields(): Fields {
    return {
      new: this.boolean(true),
      edit: this.boolean(false),
      id: this.number(null).nullable(),
      uuid: this.string(''),
      label: this.string(''),
      path: this.string(''),
      firmId: this.number(null),
      firm: this.belongsTo(Firm, 'firmId'),
      disc: this.string('Unit'),
      risks: this.belongsToMany(Risk, RiskHasFirmStructure, 'structureId', 'riskId'),
    };
  }

  @Expose({ groups: ['all'] })
  new!: boolean;

  @Expose({ groups: ['all'] })
  edit!: boolean;

  @Expose({ notEmptyOnly: true })
  id!: number | null;

  @Expose()
  uuid!: string;

  @Expose()
  label!: string;

  @Expose({ toClassOnly: true })
  path!: string;

  @Expose({ name: 'firm', toPlainOnly: true })
  firmId!: number;

  @Type(() => Firm)
  firm!: Firm;

  @Expose()
  disc!: string;

  jobs!: any;

  units!: any;

  get isSave() {
    return this.uuid !== null;
  }

  get isActive() {
    return true;
  }
}

export default FirmStructure;
