import { Module } from 'vuex';
import axios from 'axios';
import Routing from 'fos-routing';

import MenuItem from '@models/menu/MenuItem';
import Menu from '@models/menu/Menu';

import { MenuModuleInterface } from '@/store/modules/menu/types';

const MenuModule: Module<MenuModuleInterface, any> = {
  getters: {
    activeItems(state, getters, rootState) {
      const routeName = rootState.RouteModule.meta.group || rootState.RouteModule.name;

      let item = MenuItem.query().where('routeName', routeName).withAllRecursive().first();
      const activeItems: Array<string> = [];
      while (item) {
        activeItems.push(item.code);
        item = item.itemParent;
      }

      return activeItems;
    },
    userActions: () => Menu.query().with('items').find('userActions') as Menu,
  },

  actions: {
    async init() {
      const response = await axios.get<Menu[]>(Routing.generate('api_get_menu'));
      return Menu.insertOrUpdate({ data: response.data });
    },
  },
};

export default MenuModule;
