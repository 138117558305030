class HazardStatement {
  id!: number;
  reference!: string;
  label!: string;
  classe!: string;
  sensibility!: number;
  version!: number;
  cmr!: number;
  sortableRank!: number;
}

export default HazardStatement;
