const proRisks = () => import('../pages/dashboard/DashboardView.vue');

export default [
  {
    path: '/risques-professionnels/dossiers',
    name: 'pro-risks-dashboard',
    component: proRisks,
    meta: {
      breadcrumb: [
        { name: 'Accueil', link: 'home', icon: 'home' },
        { name: 'Risques Professionnels', link: 'pro-risks-dashboard' },
        { name: 'Tableau de bord' },
      ],
      permission: 1,
    },
  },
];
