





























































































import { Component, Prop, Vue } from 'vue-property-decorator';
import {
  ActionInterface,
  BreadcrumbItem,
  HelpActionInterface,
  PrintActionInterface,
  PrintActionBtnInterface,
} from '@/types/breadcrumb';

@Component
export default class Breadcrumb extends Vue {
  @Prop({ type: Array, default: () => [] })
  readonly actions!: Array<ActionInterface>;

  @Prop({ type: Object, default: null })
  readonly printAction!: PrintActionInterface;

  @Prop({ type: Object, default: null })
  readonly printActionBtn!: PrintActionBtnInterface;

  @Prop({ type: Object, default: null })
  readonly helpAction!: HelpActionInterface;

  get breadcrumbList(): BreadcrumbItem[] {
    return this.$route.meta.breadcrumb;
  }

  get printActionContent(): string {
    if (this.printAction.tooltip) {
      return this.printAction.tooltip;
    }
    return 'Exporter en PDF';
  }

  handleEvent(event: any, action: ActionInterface) {
    if (!this.conditionPass(action)) {
      return false;
    }

    return event !== undefined ? event() : true;
  }

  conditionPass(action: ActionInterface) {
    return !(action.condition !== undefined && !action.condition());
  }
}
