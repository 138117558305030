import moment from 'moment';
import { Fields, Model } from '@vuex-orm/core';
import {
  Exclude, Expose, Transform, Type,
} from 'class-transformer';

import Firm from '@models/firm/Firm';
import Risk from '@models/proRisk/Risk';
import RiskEmployee from '@models/proRisk/RiskEmployee';

import { Folder, FolderEnum } from '@/types/Folder';

@Exclude()
class ProFolder extends Model implements Folder {
  static entity = 'proFolder';

  static fields(): Fields {
    return {
      id: this.number(null).nullable(),
      reference: this.string(false),
      title: this.string(''),
      beginAt: this.date(null).nullable(),
      endAt: this.date(null).nullable(),
      inClosing: this.boolean(false),
      closed: this.boolean(false),
      firmId: this.number(null),
      firm: this.belongsTo(Firm, 'firmId'),
      risks: this.hasMany(Risk, 'folder'),
      riskEmployees: this.hasMany(RiskEmployee, 'folder'),
      nbRisks: this.number(0),
      status: this.number(0),
      nbActions: this.number(0),
      quotationCompletion: this.number(0),
      quotationDetails: this.attr([]),
      actionsDetails: this.attr([]),
      files: this.attr([]),
    };
  }

  @Expose()
  id!: number | null;

  @Expose()
  reference!: string;

  @Expose({ toClassOnly: true })
  title!: string;

  @Expose()
  @Type(() => Date)
  @Transform(value => (value ? moment(value).format('YYYY-MM-DDTHH:mm:ss') : null), { toPlainOnly: true })
  beginAt!: Date | null;

  @Expose()
  @Type(() => Date)
  @Transform(value => (value ? moment(value).format('YYYY-MM-DDTHH:mm:ss') : null), { toPlainOnly: true })
  endAt!: Date | null;

  @Expose({ toClassOnly: true })
  inClosing!: boolean;

  @Expose({ toClassOnly: true })
  closed!: boolean;

  @Expose({ name: 'firm', toPlainOnly: true })
  firmId!: number;

  @Type(() => Firm)
  firm!: Firm;

  risks!: Array<Risk>;

  riskEmployees!: Array<RiskEmployee>;

  @Expose({ toClassOnly: true })
  nbRisks!: number;

  @Expose({ toClassOnly: true })
  nbActions!: number;

  @Expose({ toClassOnly: true })
  quotationCompletion!: number;

  status!: number;

  @Expose({ toClassOnly: true })
  quotationDetails!: {};

  @Expose({ toClassOnly: true })
  actionsDetails!: {};

  @Expose({ toClassOnly: true })
  files!: Array<any>;

  get isInClosing() {
    return this.status === FolderEnum.FOLDER_IN_CLOSING;
  }

  get isInError() {
    return this.status === -1;
  }
}

export default ProFolder;
