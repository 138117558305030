import RepositoryFactory from '@/lib/repository-factory/RepositoryFactory';

const repositoryFactory = new RepositoryFactory();

const repositories = require.context('@/repositories', true, /\.ts$/);
repositories.keys().forEach((v) => {
  const Repository = repositories(v).default;
  if (Repository.name !== undefined) {
    repositoryFactory.register(Repository);
  }
});

export default repositoryFactory;
