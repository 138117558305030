
















































































import { Vue, Component } from 'vue-property-decorator';
import { Getter, State } from 'vuex-class';
import axios from 'axios';
import Routing from 'fos-routing';

import Layout from '@/layouts/LayoutDefault.vue';
import HomeBox from '@/components/HomeBox.vue';

import Firm from '@models/firm/Firm';
import Menu from '@models/menu/Menu';

@Component({
  components: {
    Layout,
    HomeBox,
  },
})
export default class Home extends Vue {
  @Getter('currentFirm')
  currentFirm!: Firm;
  @Getter('browserCompatible')
  browserCompatible!: Boolean;

  @State(state => state.riskPro.activeProFolder)
  activeProFolder!: number | null;

  dashboardTitle: string = '';
  dialogVisible: boolean = false;
  dashboardDescription: string = '';

  formData: Record<string, string> = {
    token: '',
    url: '',
  };

  get menu(): Menu {
    return Menu.query().where('code', 'sidebar').withAllRecursive().first() as Menu;
  }

  async created() {
    if (!this.browserCompatible) {
      this.dialogVisible = true;
    }
    const dashboardPage = await this.pageService.get('dashboard');
    this.dashboardTitle = dashboardPage.title;
    this.dashboardDescription = dashboardPage.description;
  }

  get homePath() {
    return process.env.LOCAL_URL + this.$router.resolve({ name: 'home', append: false }).href;
  }

  redirect() {

  }
}
