import { Fields, Model } from '@vuex-orm/core';

class FirmPermission extends Model {
  static entity = 'firmPermissions';

  static fields(): Fields {
    return {
      route: this.string(''),
      permission: this.number(0),
      userId: this.number(null),
    };
  }

  route!: string;
  permission!: number;
  userId!: number;
}

export default FirmPermission;
