import { Fields, Model } from '@vuex-orm/core';

import ProFolder from '@models/proRisk/ProFolder';
import Risk from '@models/proRisk/Risk';
import FirmStructure from '@models/firm/FirmStructure';
import ActionPlanHasStructure from '@models/proRisk/ActionPlanHasStructure';
import { Exclude, Expose, Transform } from 'class-transformer';
import moment from 'moment';

export enum ActionPlanStatus {
  ACTION_PLAN_TO_PLANNED,
  ACTION_PLAN_OUTDATED,
  ACTION_PLAN_IN_PROGRESS,
  ACTION_PLAN_FINISH,
  ACTION_PLAN_ABANDONED,
}

@Exclude()
class ActionPlan extends Model {
  static entity = 'proActionPlan';

  static fields(): Fields {
    return {
      id: this.number(null).nullable(),
      comment: this.string(null).nullable(),
      driver: this.string(null).nullable(),
      resources: this.string(null).nullable(),
      aims: this.string(null).nullable(),
      abandoned: this.boolean(false),
      fixedDate: this.date(null).nullable(),
      closedDate: this.date(null).nullable(),
      riskId: this.number(null).nullable(),
      risk: this.belongsTo(Risk, 'riskId'),
      folderId: this.number(null).nullable(),
      folder: this.belongsTo(ProFolder, 'folderId'),
      structures: this.belongsToMany(FirmStructure, ActionPlanHasStructure, 'actionPlanId', 'structureId'),
    };
  }

  riskId!: number | null;

  folderId!: number | null;

  @Expose({ groups: ['put'] })
  id!: number | null;

  @Expose({ groups: ['post', 'put'] })
  comment!: string | null;

  @Expose({ groups: ['post', 'put'] })
  driver!: string | null;

  @Expose({ groups: ['post', 'put'] })
  resources!: string | null;

  @Expose({ groups: ['post', 'put'] })
  aims!: string | null;

  @Expose({ groups: ['post', 'put'] })
  abandoned!: boolean;

  @Expose({ groups: ['post', 'put'] })
  @Transform(value => (value ? moment(value).format('YYYY-MM-DDTHH:mm:ss') : null), { toPlainOnly: true })
  fixedDate!: Date | null;

  @Expose({ groups: ['post', 'put'] })
  @Transform(value => (value ? moment(value).format('YYYY-MM-DDTHH:mm:ss') : null), { toPlainOnly: true })
  closedDate!: Date | null;

  @Expose({ name: 'folder', groups: ['post'] })
  folder!: ProFolder | null;

  @Expose({ name: 'risk', groups: ['post'] })
  risk!: Risk | null;

  @Expose({ groups: ['post', 'put'] })
  structures!: FirmStructure[];

  get status(): ActionPlanStatus {
    if (this.abandoned) {
      return ActionPlanStatus.ACTION_PLAN_ABANDONED;
    }

    if (this.closedDate) {
      return ActionPlanStatus.ACTION_PLAN_FINISH;
    }

    if (!this.fixedDate) {
      return ActionPlanStatus.ACTION_PLAN_TO_PLANNED;
    }

    if (this.fixedDate < TODAY) {
      return ActionPlanStatus.ACTION_PLAN_OUTDATED;
    }

    return ActionPlanStatus.ACTION_PLAN_IN_PROGRESS;
  }
}

export default ActionPlan;
