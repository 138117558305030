








import { Component, Prop, Vue } from 'vue-property-decorator';
import MenuItemComponent from '@/components/menu/MenuItemComponent.vue';
import Menu from '@/entity/menu/Menu';
import MenuItem from '@/entity/menu/MenuItem';

@Component({
  components: {
    MenuItem: MenuItemComponent,
  },
})
export default class MenuComponent extends Vue {
  menuOpen: Boolean = true;

  @Prop({ type: Object })
  menu!: Menu;

  get items(): Array<MenuItem> {
    return this.menu ? this.menu.items.filter(c => c.permission > 0) : [];
  }
}
