import { Container } from 'inversify';
import SERVICE_IDENTIFIER from './identifiers';

import { FirmListServiceInterface } from '../services/FirmListServiceInterface';
import { FirmUpdateServiceInterface } from '../services/FirmUpdateServiceInterface';

import FirmListService from '../services/impl/FirmListService';
import FirmUpdateService from '../services/impl/FirmUpdateService';

export default class DeclarationContainer {
  static build(container: Container) {
    container.bind<FirmListServiceInterface>(SERVICE_IDENTIFIER.FirmListServiceInterface).to(FirmListService);
    container.bind<FirmUpdateServiceInterface>(SERVICE_IDENTIFIER.FirmUpdateServiceInterface).to(FirmUpdateService);
  }
}
