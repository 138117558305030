import moment from 'moment';
import {
  Exclude,
  Expose,
  Transform,
  Type,
} from 'class-transformer';
import { Fields, Model } from '@vuex-orm/core';
import { v4 as uuidv4 } from 'uuid';

import Employee from '@models/firm/Employee';

@Exclude()
class EmployeeHasExposition extends Model {
  static entity = 'employeeHasExposition';
  static primaryKey = 'uuid';

  static fields(): Fields {
    return {
      id: this.number(null).nullable(),
      uuid: this.string(() => uuidv4()),
      employeeUuid: this.string(''),
      employee: this.belongsTo(Employee, 'employeeUuid'),
      exposition: this.number(null).nullable(),
      beginDate: this.date(''),
      endDate: this.date(null).nullable(),
    };
  }

  @Expose({ notEmptyOnly: true })
  id!: number | null;

  uuid!: string;

  employeeUuid!: string;

  employee!: Employee;

  @Expose()
  exposition!: number;

  @Expose()
  @Type(() => Date)
  @Transform(value => moment(value).format('YYYY-MM-DDTHH:mm:ss'), { toPlainOnly: true })
  beginDate!: Date | null;

  @Expose()
  @Type(() => Date)
  @Transform(value => (value ? moment(value).format('YYYY-MM-DDTHH:mm:ss') : null), { toPlainOnly: true })
  endDate!: Date | null;

  get isCurrent(): boolean {
    return !this.endDate || this.endDate > TODAY;
  }
}

export default EmployeeHasExposition;
