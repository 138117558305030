import { Fields, Model } from '@vuex-orm/core';
import FirmPermission from '@models/user/FirmPermission';

class UserModel extends Model {
  static entity = 'users';

  static fields(): Fields {
    return {
      id: this.number(null),
      firstName: this.string(''),
      lastName: this.string(''),
      email: this.string(''),
      role: this.string(''),
      permissions: this.hasMany(FirmPermission, 'userId'),
    };
  }
}

export default UserModel;
