import { IFetchRequest } from '@/lib/fetch';

import structureSchema from '@modules/declaration/store/schema/structureSchema';
import employeeSchema from '@modules/declaration/store/schema/employeeSchema';

export const getData: IFetchRequest = {
  url: 'api_get_firm_data',
  method: 'GET',
  schema: {
    structures: structureSchema,
    employees: [employeeSchema],
  },
};

export const getDeclarationData: IFetchRequest = {
  url: 'api_get_firm_declaration_data',
  method: 'GET',
  schema: {
    structures: structureSchema,
    employees: [employeeSchema],
  },
};

export const patchStep: IFetchRequest = {
  url: 'api_patch_declaration_step',
  method: 'PATCH',
};
