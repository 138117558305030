import { Vue } from 'vue-property-decorator';
import moment from 'moment';
import InputMask from 'inputmask';

import InputHelper from '@/components/InputHelper.vue';
import ElTablePaginated from '@/components/ElTablePaginated.vue';
import Portlet from '@/components/Portlet.vue';
import Modal from '@/components/Modal.vue';

class Empr1tePlugin {
  install(options: Record<any, any>) {
    Vue.prototype.empty = (value: string) => value === null || value === undefined || value.trim() === '';

    Vue.directive('uppercase', {
      update: (el, binding) => {
        let element: HTMLInputElement = el as HTMLInputElement;
        if (typeof element.value === 'undefined') {
          element = el.querySelector('input') as HTMLInputElement;
        }

        element.value = element.value.toUpperCase();
      },
    });

    Vue.directive('focus', {
      bind: (el, binding) => {
        let element: HTMLInputElement = el as HTMLInputElement;
        if (typeof element.value === 'undefined') {
          element = el.querySelector('input') as HTMLInputElement;
        }
        window.setTimeout(() => {
          element.focus();
        }, 100);
      },
    });

    Vue.directive('mask', {
      bind(el, binding) {
        const inputs = el.getElementsByTagName('INPUT') as HTMLCollection;
        let input: HTMLElement = inputs[0] as HTMLElement;
        if (inputs.length > 1) {
          input = inputs[inputs.length - 1] as HTMLElement;
        }

        new InputMask(binding.value).mask(input);
      },
    });

    Vue.filter('format', (value: string, arg: string) => moment(value).format(arg));
    Vue.filter('empty', (value: string) => value === null || value === undefined || value.trim() === '');
    Vue.filter('uppercase', (value: string) => {
      if (!value) return '';
      return value.toString().toUpperCase();
    });
    Vue.filter('cmsContent', (value: string) => {
      if (!value) return '';
      const uploadUrl = `${window.EMPR1TE_URL}/upload`;
      return value.replace(/\/upload/g, uploadUrl);
    });

    Vue.component('input-helper', InputHelper);
    Vue.component('el-table-paginated', ElTablePaginated);
    Vue.component('portlet', Portlet);
    Vue.component('modal', Modal);

    Vue.mixin({
      methods: {
        handleRedirect(name, params = {}, query = {}, path = '') {
          window.scrollToTop();
          this.$router.push({
            name,
            path,
            params,
            query,
          });
        },
        redirectToEmpr1te(path) {
          window.redirectToEmpr1te(path);
        },
        generateEmpr1tePath(path) {
          return `${window.EMPR1TE_URL}/${path}`;
        },
        goTo(routename: string) {
          this.$router.push({ name: routename });
        },
      },
    });
  }
}

export default new Empr1tePlugin();
