import axios from 'axios';
import Routing from 'fos-routing';
import { injectable } from 'inversify';

import { IPageService } from '@/services/interfaces';

@injectable()
export default class PageService implements IPageService {
  pageData: Record<string, any> = {};

  async get(slug: string, save: boolean = true): Promise<any> {
    if (save && this.pageData[slug] !== undefined) {
      return this.pageData[slug];
    }

    const response = await axios.get(Routing.generate('api_get_page', { slug }));
    if (save) {
      this.pageData[slug] = response.data;
    }

    return response.data;
  }
}
