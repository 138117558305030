import Risk from '@models/proRisk/Risk';
import { QuotationEnum, QuotationEnumName } from '@modules/professional-risk/types/enum';

export interface RiskDropdownArgs {
  type: string,
  risk: Risk
}

export interface Criticality {
  color: string;
  value: string;
  quotation: string;
  quotationValue: QuotationEnum;
}

export const CRITICALITY_UNDEFINED: Criticality = {
  color: 'white',
  value: '',
  quotation: QuotationEnumName.get(QuotationEnum.UNDEFINED) as string,
  quotationValue: QuotationEnum.UNDEFINED,
};
