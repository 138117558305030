import { Store } from 'vuex';
import axios from 'axios';
import Routing from 'fos-routing';
import { classToPlain } from 'class-transformer';

import { PaginatedData } from '@/types/paginatedData';

import AbstractRepository from '@/lib/repository-factory/AbstractRepository';

import Risk from '@models/proRisk/Risk';
import ProFolder from '@models/proRisk/ProFolder';
import RiskHasFirmStructure from '@models/proRisk/RiskHasFirmStructure';

export default class RiskRepository extends AbstractRepository<Risk> {
  usePatch = true;

  query = {
    get: 'api_get_pr_risk',
    all: 'api_get_pr_risk_by_folder',
    create: {
      name: 'api_post_pr_risk',
      groups: ['post'],
    },
    update: {
      name: 'api_patch_pr_risk',
      groups: ['patch'],
    },
    delete: 'api_delete_pr_risk',
  };

  constructor(store: Store<any>) {
    super(store, Risk);
    Risk.setRepository(this);
  }

  getAll() {
    return Risk.query().with(['character', 'structures']).all();
  }

  getAllOrder(order: { col: string, direction: 'asc' | 'desc' }) {
    return Risk.query().with(['character', 'structures']).orderBy(order.col, order.direction).all();
  }

  async findByFolder(folder: ProFolder): Promise<void> {
    const { data } = await axios.get(Routing.generate('api_get_pr_risk_by_folder', { id: folder.id }));
    await Risk.insertOrUpdate({ data });
  }

  async search(payload: { folder: ProFolder, filters: any }): Promise<PaginatedData<Risk>> {
    const { folder, filters } = payload;
    const { data } = await axios.get<PaginatedData<Risk>>(Routing.generate('api_get_pr_risk_search', { id: folder.id, ...filters }));
    await this.deleteAll();
    const response = await Risk.insertOrUpdate({ data: data.items });

    return data;
  }

  async updateQuotation(risk: Risk): Promise<void> {
    const response = await axios.patch(Routing.generate('api_patch_pr_risk', { id: risk.id }), classToPlain(risk, { groups: ['cotation'] }));
    await Risk.insertOrUpdate({ data: response.data });
  }

  async deleteAll() {
    await RiskHasFirmStructure.deleteAll();
    return super.deleteAll();
  }
}
