import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { Expose, Transform, Type } from 'class-transformer';

import Substance from './Substance';
import Comment from './Comment';

export enum ProductSheetStatus {
  DRAFT = 0,
  UNDER_INVESTIGATION = 1,
  REJECT = 2,
  VALIDATE_CONSISTENT = 3,
  VALIDATE_NOT_CONSISTENT = 4,
  REVIEW_IMPOSSIBLE = 5,
}

class ProductSheet {
  static entity = 'productSheets';
  static primaryKey = 'uuid';

  _new: boolean = false;
  _edit: boolean = false;
  uuid: string = uuidv4();
  label: string = '';
  caution: number | null = null;
  status: number | null = null;
  vendor: string = '';
  year: string = '';
  isOldVersion: boolean = false;
  isPublic: boolean = false;
  isCompleted: boolean = false;
  file: string | null = null;
  fileUrl: string | null = null;
  nonHazardStatement: boolean = false;
  hasNoSubstance: boolean = false;
  hazardStatements: Array<number> = [];
  pictograms: Array<number> = [];

  @Expose()
  @Type(() => Substance)
  substances: Array<Substance> = [];

  @Expose()
  @Type(() => Comment)
  comments: Array<Comment> = [];

  @Expose()
  @Type(() => Date)
  @Transform(value => moment(value).format('YYYY-MM-DDTHH:mm:ss'), { toPlainOnly: true })
  createdAt: Date | null = null;

  @Expose()
  @Type(() => Date)
  @Transform(value => moment(value).format('YYYY-MM-DDTHH:mm:ss'), { toPlainOnly: true })
  updatedAt: Date | null = null;

  get isSave() {
    return this.uuid !== null;
  }

  get statusToLabel() {
    let status = '';
    switch (this.status) {
      case 0:
        status = 'Brouillon';
        break;
      case 1:
        status = 'En cours d\'analyse';
        break;
      case 2:
        status = 'Rejetée';
        break;
      case 3:
        status = 'Etiquetage correct';
        break;
      case 4:
        status = 'Etiquetage erroné';
        break;
      case 5:
        status = 'Analyse critique irréalisable';
        break;
      default:
        status = 'Brouillon';
        break;
    }
    return status;
  }

  get color() {
    let color = '';
    if (!this.isCompleted) {
      color = 's-red';
    }
    return color;
  }
}

export default ProductSheet;
