import { Fields, Model } from '@vuex-orm/core';

import RiskCategory from '@models/proRisk/RiskCategory';
import Painfulness from '@models/proRisk/Painfulness';
import Thesaurus from '@models/proRisk/Thesaurus';

class RiskCharacter extends Model {
  static entity = 'riskCharacter';

  static fields(): Fields {
    return {
      id: this.number(false),
      label: this.string(false),
      riskCriterionLabel: this.string(false),
      isChemicalAgent: this.boolean(false),
      isPotentiallyHard: this.boolean(false),
      deletedAt: this.date(''),
      painfulness: this.hasOne(Painfulness, 'riskCharacter'),
      thesauruses: this.hasMany(Thesaurus, 'riskCharacter'),
      category: this.hasOne(RiskCategory, 'id'),
    };
  }

  id!: number;
  label!: string;
  riskCriterionLabel!: string;
  isChemicalAgent!: boolean;
  isPotentiallyHard!: boolean;
  deletedAt!: string;
  painfulness!: Painfulness;
  thesauruses!: Array<Painfulness>;
  category!: RiskCategory;
}

export default RiskCharacter;
