import { Model } from '@vuex-orm/core';

export default class RiskHasFirmStructure extends Model {
  static entity = 'riskHasStructure';

  static primaryKey = ['structureId', 'riskId'];

  static fields() {
    return {
      riskId: this.attr(null),
      structureId: this.attr(null),
    };
  }
}
