import { schema } from 'normalizr';
import EmployeeHasContract from '@modules/declaration/entities/EmployeeHasContract';

const employeeHasContract = new schema.Entity<EmployeeHasContract>(EmployeeHasContract.entity, {}, {
  processStrategy: (value) => {
    value.contractId = value.contract || value.contractId || null;
    value.contract = null;

    value.dateStart = value.dateStart ? new Date(value.dateStart) : null;
    value.dateEnd = value.dateEnd ? new Date(value.dateEnd) : null;
    return Object.assign(new EmployeeHasContract(), { ...value });
  },
  mergeStrategy: (entityA, entityB) => Object.assign(entityA, entityB),
  idAttribute: EmployeeHasContract.primaryKey,
});

export default employeeHasContract;
