import { schema } from 'normalizr';
import Person from '@modules/declaration/entities/Person';

const person = new schema.Entity<Person>(Person.entity, {}, {
  processStrategy: (value) => {
    value.dateOfBirth = value.dateOfBirth ? new Date(value.dateOfBirth) : null;
    return Object.assign(new Person(), { ...value });
  },
  mergeStrategy: (entityA, entityB) => Object.assign(entityA, entityB),
  idAttribute: Person.primaryKey,
});

export default person;
