import { Fields, Model } from '@vuex-orm/core';

class Exposition extends Model {
  static entity = 'exposition';

  static fields(): Fields {
    return {
      id: this.number(null),
      code: this.string(''),
      name: this.string(''),
      description: this.string(null).nullable(),
      forEmployee: this.boolean(false),
      type: this.number(null).nullable(),
      category: this.number(null).nullable(),
      level: this.number(null).nullable(),
      isVisible: this.boolean(false),
      deletedAt: this.date(null).nullable(),
    };
  }

  id!: number;
  code!: string;
  name!: string;
  description!: string | null;
  forEmployee!: boolean;
  type!: number | null;
  category!: number | null;
  level!: number | null;
  isVisible!: boolean;
  deletedAt!: Date | null;
}

export default Exposition;
