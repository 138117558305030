import axios from 'axios';
import { normalize, Schema } from 'normalizr';
import Routing from 'fos-routing';

export interface IFetchInit extends RequestInit {
  params?: object;
}

export interface IFetchRequest {
  url: string;
  method: 'GET' | 'POST' | 'PATCH' | 'DELETE' | 'PUT';
  schema?: Schema
}

export function fetch(config: IFetchRequest, params?: Object, data?: Record<any, any>): Promise<any> {
  const { schema, method, url } = config;

  const uri = Routing.generate(url, params || {});
  return axios.request({ url: uri, method, data })
    .then(response => response.data)
    .then((responseData) => {
      let response;
      if (schema) {
        const { entities } = normalize(responseData, schema);
        response = entities;
      } else {
        response = responseData;
      }

      return response;
    });
}

export default fetch;
