import { Fields, Model } from '@vuex-orm/core';

class RiskCategory extends Model {
  static entity = 'riskCategory';

  static fields(): Fields {
    return {
      id: this.number(false),
      label: this.string(false),
    };
  }

  id!: number;
  label!: string;
}

export default RiskCategory;
