import { Module } from 'vuex';

import mutations from './mutations';
import actions from './actions';
import getters from './getters';

import { RiskProModuleInterface } from './types';

const RiskProModule: Module<RiskProModuleInterface, any> = {
  namespaced: true,
  state: {
    activeProFolder: null,
    historicProFolders: [],
  },
  mutations,
  getters,
  actions,
};

export default RiskProModule;
