import { Fields, Model } from '@vuex-orm/core';

class MenuItem extends Model {
  static entity = 'menuItems';
  static primaryKey = 'code';

  static fields(): Fields {
    return {
      lvl: this.number(0),
      code: this.string(''),
      title: this.string(''),
      icon: this.string(null).nullable(),
      permission: this.number(0),
      parent: this.string(''),
      itemParent: this.belongsTo(MenuItem, 'parent'),
      routeName: this.string(null).nullable(),
      isShow: this.boolean(false),
      isActive: this.boolean(false),
      path: this.string(null).nullable(),
      menu: this.string(''),
      children: this.hasMany(MenuItem, 'parent'),
    };
  }

  code!: string;
  title!: string;
  icon!: string;
  lvl!: number;
  permission!: number;
  parent!: string;
  itemParent!: MenuItem;
  routeName!: string | null;
  isShow!: boolean;
  isActive!: boolean;
  path!: string | null;
  menu!: string;
  children!: Array<MenuItem>;
}

export default MenuItem;
