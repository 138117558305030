import { ActionPlanStatus } from '@models/proRisk/ActionPlan';

export enum FrequenciesEnum {
  VERY_COMMON = 10,
  COMMON = 7,
  CASUAL = 4,
  RARE = 1
}

export const FrequenciesEnumName = new Map<number, string>([
  [FrequenciesEnum.VERY_COMMON, 'Très fréquent'],
  [FrequenciesEnum.COMMON, 'Fréquent'],
  [FrequenciesEnum.CASUAL, 'Occasionnel'],
  [FrequenciesEnum.RARE, 'Rare'],
]);

export enum SeriousnessEnum {
  SERIOUS_10 = 10,
  SERIOUS_7 = 7,
  SERIOUS_4 = 4,
  SERIOUS_1 = 1
}

export const SeriousnessEnumName = new Map<number, string>([
  [SeriousnessEnum.SERIOUS_10, 'Très grave'],
  [SeriousnessEnum.SERIOUS_7, 'Grave'],
  [SeriousnessEnum.SERIOUS_4, 'Sérieux'],
  [SeriousnessEnum.SERIOUS_1, 'Bénin'],
]);

export enum MastersEnum {
  MASTER_90 = 90,
  MASTER_60 = 60,
  MASTER_30 = 30,
  MASTER_0 = 0
}

export const MastersEnumName = new Map<number, string>([
  [MastersEnum.MASTER_90, '90%'],
  [MastersEnum.MASTER_60, '60%'],
  [MastersEnum.MASTER_30, '30%'],
  [MastersEnum.MASTER_0, '0%'],
]);

export enum QuotationEnum {
  UNDEFINED,
  LOW,
  MEDIUM,
  HIGH,
}

export const QuotationEnumName = new Map<number, string>([
  [QuotationEnum.UNDEFINED, 'Non coté'],
  [QuotationEnum.LOW, 'Faible'],
  [QuotationEnum.MEDIUM, 'Moyen'],
  [QuotationEnum.HIGH, 'Elevé'],
]);

export const CriticalityColor = new Map<number, string>([
  [QuotationEnum.UNDEFINED, 'grey'],
  [QuotationEnum.LOW, 'green'],
  [QuotationEnum.MEDIUM, 'yellow'],
  [QuotationEnum.HIGH, 'red'],
]);

export const ActionPlanEnumName = new Map<number, string>([
  [ActionPlanStatus.ACTION_PLAN_TO_PLANNED, 'Non planifié'],
  [ActionPlanStatus.ACTION_PLAN_OUTDATED, 'Dépassé'],
  [ActionPlanStatus.ACTION_PLAN_IN_PROGRESS, 'En cours'],
  [ActionPlanStatus.ACTION_PLAN_FINISH, 'Terminée'],
  [ActionPlanStatus.ACTION_PLAN_ABANDONED, 'Abandonnée'],
]);
