import moment from 'moment';
import { Exclude, Expose } from 'class-transformer';

import { FirmItemType, FirmStructureItem } from '@/types/firm/firm';

import { FirmStructure, Unit } from './FirmStructureHierarchy';
import EmployeeHasJob from './EmployeeHasJob';

@Exclude()
export class Job extends FirmStructure implements FirmStructureItem {
  static entity = 'jobs';
  static primaryKey = 'uuid';

  @Expose()
  disc: string = 'Job';

  type: FirmItemType = FirmItemType.Job;

  @Expose()
  reference: string | null = null;

  @Expose()
  version: number = 1;

  @Expose()
  description: string | null = null;

  @Expose()
  classification: number | null = null;

  @Expose()
  expositions: number[] = [];

  @Expose({ groups: ['all'] })
  unitUuid: string = '';

  @Expose({ name: 'unit', toPlainOnly: true })
  unitId: number | null = null;

  unit: Unit | null = null;

  @Expose({ groups: ['all'] })
  employeeHasJobs: EmployeeHasJob[] = [];
  employeeHasJobsUuid: string[] = [];

  remove: boolean = false;

  @Expose({ groups: ['all'] })
  isOldJob: boolean = false;

  get isSave() {
    return this.id !== null;
  }

  get isActive() {
    return true;
  }

  get parentUuid(): string {
    return this.unitUuid;
  }

  set parentUuid(uuid: string) {
    this.unitUuid = uuid;
  }
}

export default Job;
