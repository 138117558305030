import { Container } from 'inversify';
import SERVICE_IDENTIFIER from './identifiers';

import { RiskServiceInterface } from '../services/RiskServiceInterface';

import RiskService from '../services/impl/RiskService';

export default class ProfessionalRiskContainer {
  static build(container: Container) {
    container.bind<RiskServiceInterface>(SERVICE_IDENTIFIER.RiskServiceInterface).to(RiskService);
  }
}
