import {
  Exclude, Expose, Transform, Type,
} from 'class-transformer';
import { Fields, Model } from '@vuex-orm/core';

import { FirmStructure, Unit, Job } from '@/store/models/firm/FirmStructureHierarchy';
import Employee from '@models/firm/Employee';
import FirmContact from '@models/firm/FirmContact';
import CodeNaf from '@models/firm/CodeNaf';
import Group from '@models/firm/Group';
import moment from 'moment';

@Exclude()
class Firm extends Model {
  static entity = 'firm';

  static fields(): Fields {
    return {
      id: this.number(null),
      tag: this.string(null),
      label: this.string(''),
      number: this.string('').nullable(),
      siret: this.string('').nullable(),
      padoaId: this.string(null).nullable(),
      declaration: this.boolean(false),
      visibleChi: this.boolean(false),
      visiblePro: this.boolean(false),
      root: this.number(null).nullable(),
      codeNafId: this.number(null).nullable(),
      structures: this.hasMany(FirmStructure, 'firmId'),
      employees: this.hasMany(Employee, 'firmId'),
      codeNaf: this.belongsTo(CodeNaf, 'codeNafId'),
      contactId: this.number(null).nullable(),
      facturationContactId: this.number(null).nullable(),
      convocationContactId: this.number(null).nullable(),
      contact: this.belongsTo(FirmContact, 'contactId'),
      facturationContact: this.belongsTo(FirmContact, 'facturationContactId'),
      convocationContact: this.belongsTo(FirmContact, 'convocationContactId'),
      rgpdAcceptedAt: this.date(null).nullable(),
      employeesSynchronizedAt: this.date(null).nullable(),
      lastDeclaration: this.date(null).nullable(),
      groupId: this.number(null).nullable(),
      group: this.belongsTo(Group, 'groupId'),
    };
  }

  @Expose()
  id!: number;

  @Expose()
  tag!: string;

  @Expose()
  label!: string;

  @Expose()
  number!: string;

  @Expose()
  siret!: string;

  @Expose()
  declaration!: boolean;

  @Expose()
  visibleChi!: boolean;

  @Expose()
  visiblePro!: boolean;

  @Expose()
  codeNafId!: number | null;

  @Expose()
  codeNaf!: CodeNaf | null;

  @Expose()
  root!: number;

  @Expose()
  @Type(() => FirmStructure, {
    discriminator: {
      property: 'disc',
      subTypes: [
        { value: Unit, name: 'Unit' },
        { value: Job, name: 'Job' },
      ],
    },
  })
  structures!: Array<FirmStructure>;

  @Expose()
  @Type(() => Employee)
  employees!: Array<Employee>;

  contactId!: number;

  facturationContactId!: number;

  convocationContactId!: number;

  contact!: FirmContact;

  convocationContact!: FirmContact;

  facturationContact!: FirmContact;

  groupId!: number | null;

  group!: Group | null;


  @Expose()
  @Type(() => Date)
  @Transform(value => (value ? moment(value).format('YYYY-MM-DDTHH:mm:ss') : null), { toPlainOnly: true })
  rgpdAcceptedAt!: Date | null;

  @Expose()
  @Type(() => Date)
  @Transform(value => (value ? moment(value).format('YYYY-MM-DDTHH:mm:ss') : null), { toPlainOnly: true })
  lastDeclaration!: Date | null;

  @Expose()
  @Type(() => Date)
  @Transform(value => (value ? moment(value).format('YYYY-MM-DDTHH:mm:ss') : null), { toPlainOnly: true })
  employeesSynchronizedAt!: Date | null;

  get fullName() {
    return `${this.label} (${this.number})`;
  }

  get isSave() {
    return this.id !== null;
  }
}

export default Firm;
