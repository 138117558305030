import { RouteConfig } from 'vue-router';
import { VueRouter } from 'vue-router/types/router';

export default class VueRoutes {
  private readonly vueRouter: VueRouter;
  routes: RouteConfig[] = [];

  constructor(router: VueRouter) {
    this.vueRouter = router;
  }

  get router() {
    return this.vueRouter;
  }

  addRoutes(routes: RouteConfig[]) {
    routes.forEach((route) => {
      const index = this.routes.findIndex(r => r.name === route.name);
      if (index > -1) {
        this.routes[index] = route;
      } else {
        this.routes.push(route);
      }
    });
  }

  injectRoutes() {
    this.vueRouter.addRoutes(this.routes);
  }

  find(routeName: string) {
    return this.routes.find(route => route.name === routeName);
  }
}
