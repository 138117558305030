import { schema } from 'normalizr';

import EmployeeHasJob from '../../entities/EmployeeHasJob';

const employeeHasJob = new schema.Entity<EmployeeHasJob>(EmployeeHasJob.entity, {}, {
  processStrategy: (value, parent) => {
    value.beginDate = value.beginDate ? new Date(value.beginDate) : null;
    value.endDate = value.endDate ? new Date(value.endDate) : null;

    value.jobId = value.job || parent.id || null;
    value.job = null;

    value.employeeId = value.employee || parent.id || null;
    value.employee = null;

    return Object.assign(new EmployeeHasJob(), { ...value });
  },
  mergeStrategy: (entityA, entityB) => Object.assign(entityA, entityB),
  idAttribute: EmployeeHasJob.primaryKey,
});

export default employeeHasJob;
