import { Fields, Model } from '@vuex-orm/core';
import { InsertOrUpdate, Update } from '@vuex-orm/core/lib/modules/payloads/Actions';
import Collections from '@vuex-orm/core/lib/data/Collections';
import Record from '@vuex-orm/core/lib/data/Record';
import { Exclude, Expose } from 'class-transformer';

import ActionPlan from '@models/proRisk/ActionPlan';
import RiskCharacter from '@models/proRisk/RiskCharacter';
import ProFolder from '@models/proRisk/ProFolder';
import FirmStructure from '@models/firm/FirmStructure';
import RiskHasFirmStructure from '@models/proRisk/RiskHasFirmStructure';
import { QuotationEnum } from '@modules/professional-risk/types/enum';

@Exclude()
class Risk extends Model {
  static entity = 'risk';

  static fields(): Fields {
    return {
      id: this.number(null).nullable(),
      description: this.string(null).nullable(),
      organizationalArrangements: this.string(null).nullable(),
      individualArrangements: this.string(null).nullable(),
      collectiveArrangements: this.string(null).nullable(),
      evrp: this.string(null).nullable(),
      diagnosticDescription: this.string(null).nullable(),
      hardnessDescription: this.string(false),
      frequency: this.number(null).nullable(),
      seriousness: this.number(null).nullable(),
      mastery: this.number(null).nullable(),
      responses: this.string(false),
      isHardness: this.boolean(false),
      position: this.number(1),
      actionPlans: this.hasMany(ActionPlan, 'risk'),
      characterId: this.number(null).nullable(),
      character: this.belongsTo(RiskCharacter, 'characterId'),
      folderId: this.number(null).nullable(),
      folder: this.belongsTo(ProFolder, 'folderId'),
      structures: this.belongsToMany(FirmStructure, RiskHasFirmStructure, 'riskId', 'structureId'),
    };
  }

  @Expose({ groups: ['post', 'patch', 'cotation'] })
  id!: number;
  @Expose({ groups: ['post', 'patch'] })
  description!: string;
  @Expose({ groups: ['post', 'patch'] })
  organizationalArrangements!: string;
  @Expose({ groups: ['post', 'patch'] })
  individualArrangements!: string;
  @Expose({ groups: ['post', 'patch'] })
  collectiveArrangements!: string;
  @Expose({ groups: ['patch'] })
  evrp!: string;

  diagnosticDescription!: string;
  // @Expose()
  hardnessDescription!: string;

  @Expose({ groups: ['cotation'] })
  frequency!: number | null;
  @Expose({ groups: ['cotation'] })
  seriousness!: number | null;
  @Expose({ groups: ['cotation'] })
  mastery!: number | null;
  // @Expose()
  responses!: string;
  // @Expose()
  isHardness!: boolean;

  @Expose({ toClassOnly: true })
  position!: number;

  // @Expose()
  actionPlans!: Array<ActionPlan>;

  @Expose({ name: 'character' })
  characterId!: number | null;

  character!: RiskCharacter | null;

  @Expose({ name: 'folder', groups: ['post', 'patch'] })
  folderId!: number | null;

  folder!: ProFolder;

  @Expose({ groups: ['post', 'patch'] })
  structures!: Array<FirmStructure>;

  get quotation() {
    let quotation = QuotationEnum.UNDEFINED;
    let value = 0;
    if (this.frequency !== null && this.seriousness !== null && this.mastery !== null) {
      value = this.frequency * this.seriousness * (1 - (this.mastery / 100));
      if (value >= 19 || (this.mastery < 90 && this.seriousness === 10)) {
        quotation = QuotationEnum.HIGH;
      } else if (value < 19 && value >= 4.5 && this.mastery < 90) {
        quotation = QuotationEnum.MEDIUM;
      } else if ((value < 4.5 && value > 0) || this.mastery === 90) {
        quotation = QuotationEnum.LOW;
      }
    }

    return [quotation, value];
  }

  static insertOrUpdate(payload: InsertOrUpdate): Promise<Collections> {
    let data: Record[] = [];
    data = Array.isArray(payload.data) ? payload.data : [payload.data];

    data.forEach((v) => {
      const relations = RiskHasFirmStructure.query().where('riskId', v.id).get();
      relations.forEach((r) => {
        if (r.$id) {
          RiskHasFirmStructure.delete(r.$id);
        }
      });
    });

    return super.insertOrUpdate(payload);
  }
}

export default Risk;
