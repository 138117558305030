import dashboard from './dashboard';
import documents from './documents';
import plan from './plan';

const dashboardView = () => import('../pages/dashboard/DashboardView.vue');

export default [
  {
    path: '/risques-professionnels',
    name: 'pro-risks',
    component: dashboardView,
  },
  ...dashboard,
  ...documents,
  ...plan,
];
