import { Expose, Type } from 'class-transformer';

import { FirmStructure } from '@/entities/firm/FirmStructureHierarchy';
import ProductSheet from './ProductSheet';

class ProductFolder {
  static entity = 'productFolders';
  static primaryKey = 'id';

  _new!: boolean;
  _edit!: boolean;
  id: number | null = null;
  position: number = 0;
  activity: string | null = null;
  comment: string | null = null;
  internalReference: string | null = null;
  quantity: string | null = null;
  frequency: string | null = null;
  status: number | null = null;
  inputDate: string | null = null;
  outputDate: string | null = null;
  createdAt: string | null = null;
  updatedAt: string | null = null;
  folder: number | null = null;

  @Expose()
  @Type(() => ProductSheet)
  productSheet!: ProductSheet;

  @Expose()
  @Type(() => FirmStructure)
  structures!: Array<FirmStructure>;

  get isSave() {
    return this.id !== null;
  }

  get isEditable() {
    return this.productSheet && !this.productSheet.isPublic;
  }

  get isDeletable() {
    return this.productSheet && [null, 0, 2, 3, 4, 5].includes(this.productSheet.status);
  }

  get statusToLabel() {
    let status = '';
    switch (this.status) {
      case 1:
        status = 'Production';
        break;
      case 2:
        status = 'Essai';
        break;
      case 3:
        status = 'Sortie';
        break;
      default:
        status = '';
        break;
    }
    return status;
  }
}

export default ProductFolder;
