import { Fields, Model } from '@vuex-orm/core';
import { Expose, Type } from 'class-transformer';

import Firm from '@models/firm/Firm';

export class SharedFile extends Model {
  static entity = 'sharedFile';
  static primaryKey = 'id';

  static fields(): Fields {
    return {
      edit: this.boolean(false),
      id: this.number(null).nullable(),
      label: this.string(''),
      firmId: this.number(null),
      firm: this.belongsTo(Firm, 'firmId'),
    };
  }

  @Expose({ notEmptyOnly: true })
  id!: number | null;

  @Expose()
  label!: string;

  @Expose({ name: 'firm', toPlainOnly: true })
  firmId!: number;

  @Type(() => Firm)
  firm!: Firm;

  get isSave() {
    return this.id !== null;
  }
}

export default SharedFile;
