
































import {
  Vue,
  Component,
  Prop,
  PropSync, Watch,
} from 'vue-property-decorator';

@Component
export default class ElTablePaginated extends Vue {
  @Prop({ type: Array, default: () => [] })
  data!: Array<any>;

  @Prop({ type: Array, default: () => [] })
  limits!: Array<number>;

  @Prop({ type: Boolean, default: true })
  showSearch!: boolean;

  @Prop({ type: String })
  size!: String;

  @Prop()
  width!: String | Number;

  @Prop()
  height!: String | Number;

  @Prop()
  maxHeight!: String | Number;

  @Prop({ type: Boolean, default: true })
  fit!: Boolean;

  @Prop()
  stripe!: Boolean;

  @Prop({ type: Boolean, default: true })
  border!: Boolean;

  @Prop()
  rowKey!: [String, Function];

  @Prop()
  context!: {};

  @Prop({
    type: Boolean,
    default: true,
  })
  showHeader!: Boolean;

  @Prop({ type: Boolean })
  showSummary!: Boolean;

  @Prop()
  sumText!: String;

  @Prop()
  summaryMethod!: Function;

  @Prop()
  rowClassName!: String | Function;

  @Prop()
  rowStyle!: Object | Function;

  @Prop()
  cellClassName!: String | Function;

  @Prop()
  cellStyle!: Object | Function;

  @Prop()
  headerRowClassName!: String | Function;

  @Prop()
  headerRowStyle!: Object | Function;

  @Prop()
  headerCellClassName!: String | Function;

  @Prop()
  headerCellStyle!: Object | Function;

  @Prop()
  highlightCurrentRow!: Boolean;

  @Prop()
  currentRowKey!: String | Number;

  @Prop()
  emptyText!: String;

  @Prop()
  expandRowKeys!: Array<any>;

  @Prop()
  defaultExpandAll!: Boolean;

  @Prop()
  defaultSort!: Object;

  @Prop()
  tooltipEffect!: String;

  @Prop()
  spanMethod!: Function;

  @Prop({ type: Boolean, default: true })
  selectOnIndeterminate!: Boolean;

  @Prop({ type: Number, default: 16 })
  indent!: Number;

  @Prop({
    type: Object,
    default() {
      return {
        hasChildren: 'hasChildren',
        children: 'children',
      };
    },
  })
  treeProps!: Object;

  @Prop()
  lazy!: Boolean;

  @Prop()
  load!: Function;

  @Prop({ type: Boolean, default: false })
  remote!: boolean;

  @Prop({ type: Number, default: null })
  totalPage!: number | null;

  @PropSync('limit', { default: 10 })
  limitSync!: number;

  @PropSync('currentPage', { default: 1 })
  currentPageSync!: number;

  search: string = '';

  get searchData() {
    if (!this.search) {
      return this.data;
    }

    return this.data.filter((d) => {
      let find: boolean = false;
      Object.keys(d).forEach((k) => {
        const value: any = d[k];
        if (!value || typeof value !== 'string') {
          return;
        }

        find = find || value.toLowerCase().includes(this.search.toLowerCase());
      });

      return find;
    });
  }

  get total() {
    return this.totalPage || this.searchData.length;
  }

  get filteredData() {
    return !this.remote
      ? this.searchData.filter((row, index) => index >= (this.limitSync * (this.currentPageSync - 1)) && index < (this.limitSync * this.currentPageSync))
      : this.searchData;
  }

  sortChange(payload: { column: any, prop: string, order: string }) {
    const { column, prop, order } = payload;

    let tmpOrder = null;
    if (order) {
      tmpOrder = order === 'ascending' ? 'ASC' : 'DESC';
    }

    this.$emit('sort-change', {
      column,
      prop,
      order: tmpOrder,
    });
  }

  @Watch('total')
  onTotalChanged() {
    const maxPage = (this.total % this.limitSync) + 1;
    if (maxPage < this.currentPageSync) {
      this.currentPageSync = 1;
    }
  }
}
