import _ from 'lodash';
import axios, { AxiosResponse } from 'axios';
import Routing from 'fos-routing';
import { ActionTree } from 'vuex';
import { classToPlain, plainToClass } from 'class-transformer';
import { v4 as uuidv4 } from 'uuid';
import { normalize } from 'normalizr';

import fetch from '@/lib/fetch';
import promisesProgress from '@/utils/PromiseProgress';

import { FirmItem, FirmStructureItem, SoftDeletableData } from '@/types/firm/firm';

import Firm from '@models/firm/Firm';
import Contract from '@models/firm/Contract';

import Unit from '@modules/declaration/entities/Unit';
import Job from '@modules/declaration/entities/Job';
import Person from '@modules/declaration/entities/Person';
import Employee from '@modules/declaration/entities/Employee';
import EmployeeHasJob from '@modules/declaration/entities/EmployeeHasJob';
import EmployeeHasContract from '@modules/declaration/entities/EmployeeHasContract';
import EmployeeHasExposition from '@modules/declaration/entities/EmployeeHasExposition';

import EffectiveReal from '@modules/declaration/entities/EffectiveReal';
import { FirmUpdateModuleInterface } from '@modules/declaration/store/modules/firmUpdate/types';

import FirmUpdate from '@models/firm/FirmUpdate';
import cancelToken from '@/api/cancelToken';

import schemaEmployee from '../../schema/employeeSchema';

import * as declarationRequest from '../../../requests/declarationRequest';
import * as types from './mutation-types';

export default {
  async findData(context, firmId: number) {
    axios.get(Routing.generate('api_get_firm_contracts')).then((response) => {
      Contract.insertOrUpdate(response);
      return response;
    });

    const normalizeData = await fetch(declarationRequest.getData, { id: firmId });
    context.commit(types.SET_DECLARATION, false);
    return context.dispatch('initData', normalizeData);
  },
  initData(context, data) {
    context.commit(types.INIT_EMPLOYEE_HAS_JOBS, data.employeeHasJobs || {});
    context.commit(types.INIT_EMPLOYEE_HAS_CONTRACTS, data.employeeHasContracts || {});
    context.commit(types.INIT_EMPLOYEE_HAS_EXPOSITIONS, data.employeeHasExpositions || {});
    context.commit(types.INIT_EMPLOYEES, data.employees || {});
    context.commit(types.INIT_PERSONS, data.persons || {});
    context.commit(types.INIT_JOBS, data.jobs || {});
    context.commit(types.INIT_UNITS, data.units || {});
  },
  async clearData(context) {
    context.commit(types.INIT_EMPLOYEE_HAS_JOBS, {});
    context.commit(types.INIT_EMPLOYEE_HAS_CONTRACTS, {});
    context.commit(types.INIT_EMPLOYEE_HAS_EXPOSITIONS, {});
    context.commit(types.INIT_EMPLOYEES, {});
    context.commit(types.INIT_PERSONS, {});
    context.commit(types.INIT_JOBS, {});
    context.commit(types.INIT_UNITS, {});
  },

  updateEditedJobDate({ state, commit }) {
    Object.keys(state.jobs).forEach((key) => {
      const job = state.jobs[key];
      if (job.edit) {
        commit(types.UPDATE_JOB, {
          uuid: job.uuid,
          dateStart: TODAY,
        });
      }
    });
  },

  async saveFirms(context) {
    const firms = Firm.all();

    const promises: Promise<Firm>[] = [];
    firms.forEach((firm) => {
      promises.push(context.dispatch('saveFirm', firm.id));
    });

    return promisesProgress<Firm>(promises, (complete, response) => {
      context.commit('UPDATE_PROGRESS', {
        label: "Enregistrement de la structure de l'entreprise",
        counter: complete,
        max: firms.length,
        finish: false,
      });
    });
  },
  async saveFirm({ dispatch }, firmId) {
    try {
      await dispatch('saveUnits', firmId);
      await dispatch('saveJobs', firmId);
      await dispatch('removeJobs', firmId);
      await dispatch('removeUnits', firmId);
      // await dispatch('saveEmployees', firmId);
    } catch (error) {
      throw error;
    }
  },

  async saveUnits({ getters, dispatch }, firmId) {
    const editedUnits: Unit[] = getters.getEditedUnits(firmId);
    editedUnits.sort((a, b) => (a.lvl > b.lvl ? 1 : -1));

    await editedUnits.reduce((p, u) => p.then(() => dispatch('saveUnit', u.uuid)), Promise.resolve());

    return Promise.resolve();
  },
  async removeUnits({ getters, dispatch }, firmId) {
    const removedUnits: Unit[] = getters.getRemovedUnits(firmId);
    removedUnits.sort((a, b) => (a.lvl > b.lvl ? -1 : 1));

    await removedUnits.reduce((p, u) => p.then(() => dispatch('saveUnit', u.uuid)), Promise.resolve());

    return Promise.resolve();
  },
  async saveUnit({ dispatch, getters, commit }, uuid: string): Promise<any> {
    const unit = getters.getUnit(uuid);
    if (!unit) {
      throw new Error('unit does not exist');
    }
    const object = classToPlain<Unit>(unit);

    let promise: Promise<AxiosResponse<Unit>>;

    if (unit.new) {
      promise = axios.post<Unit>(Routing.generate('api_post_unit'), { unit: object });
    } else if (unit.edit) {
      promise = axios.put<Unit>(Routing.generate('api_put_unit', { id: unit.id }, false), { unit: object });
    } else {
      promise = axios.delete<Unit>(Routing.generate('api_delete_unit', { id: unit.id }, false));
      commit(types.DELETE_UNIT, unit);
    }

    if (unit.remove) {
      return promise;
    }

    return promise
      .then((response) => {
        dispatch('initUnit', response.data);
        return response.data;
      })
      .catch((error) => {
        const { response } = error;
        if (response.status === 409) {
          dispatch('initUnit', response.data);
          return response.data;
        }

        throw error;
      });
  },
  async saveJobs({ getters, dispatch }, firmId) {
    const jobs: Job[] = getters.getEditedJobs(firmId);

    await jobs.reduce((p, j) => p.then(() => dispatch('saveJob', j.uuid)), Promise.resolve());

    return Promise.resolve();
  },
  async saveJob({ getters, dispatch, commit }, uuid): Promise<Job> {
    const job = getters.getJob(uuid);
    if (!job || !job.unitId) {
      throw new Error('Job does not exist');
    }

    const object = classToPlain<Job>(job);

    let promise: Promise<AxiosResponse<Job>>;

    if (job.new) {
      promise = axios.post<Job>(Routing.generate('api_post_job'), { job: object });
    } else if (job.edit) {
      promise = axios.put<Job>(Routing.generate('api_put_job', { id: job.id }, false), { job: object });
    } else {
      promise = axios.delete<Job>(Routing.generate('api_delete_job', { id: job.id }, false));
      commit(types.DELETE_JOB, job);
    }

    return promise
      .then((response) => {
        dispatch('initJob', response.data);
        return response.data;
      })
      .catch((error) => {
        const { response } = error;
        if (response.status === 409) {
          dispatch('initJob', response.data);
          return response.data;
        }

        throw error;
      });
  },
  async removeJobs({ getters, dispatch }, firmId) {
    const removedJobs: Job[] = getters.getRemovedJobs(firmId);

    await removedJobs.reduce((p, j) => p.then(() => dispatch('saveJob', j.uuid)), Promise.resolve());

    return Promise.resolve();
  },
  async saveEmployees({ commit, dispatch, getters }, firmId) {
    const employees: Employee[] = getters.getEditedEmployees(firmId);
    const max = employees.length;

    let counter = 1;
    await employees
      .reduce((p, employee) => p.then(() => dispatch('saveEmployee', employee.uuid)
        .then(() => {
          counter += 1;
          commit('UPDATE_PROGRESS', {
            label: 'Mise à jour des salariés',
            counter,
            max,
            finish: false,
          });
        })), Promise.resolve());

    return Promise.resolve();
  },
  async saveEmployee({ getters, commit, dispatch }, uuid): Promise<Employee> {
    const employee: Employee | null = getters.getEmployeeWithInfos(uuid);
    if (!employee) {
      throw new Error('employee not found');
    }

    const object = classToPlain<Employee>(employee);
    const promise: Promise<AxiosResponse<Employee>> = !employee.isSave
      ? axios.post<Employee>(Routing.generate('api_post_firm_employees'), { employee: object })
      : axios.put<Employee>(Routing.generate('api_put_firm_employees', { id: employee.id }), { employee: object });

    return promise
      .then((response) => {
        dispatch('initEmployee', response.data);
        return response.data;
      })
      .catch((error) => {
        const { response } = error;
        if (response !== undefined && response.status === 409) {
          dispatch('initEmployee', response.data);
          return response.data;
        }

        throw error;
      });
  },

  async initUnit({ commit }, data) {
    data.edit = false;
    commit(types.UPDATE_UNIT, data);
  },
  async initJob({ commit }, data) {
    if (data !== '') {
      data.edit = false;
      commit(types.UPDATE_JOB, data);
    }
  },
  async initEmployee({ commit }, data) {
    const normalizeData: any = normalize(data, schemaEmployee);

    Object.keys(normalizeData.entities.employees).forEach(key => commit(types.UPDATE_EMPLOYEE, normalizeData.entities.employees[key]));
    Object.keys(normalizeData.entities.persons).forEach(key => commit(types.UPDATE_PERSON, normalizeData.entities.persons[key]));
    if (normalizeData.entities.employeeHasJobs !== undefined) {
      Object.keys(normalizeData.entities.employeeHasJobs).forEach(key => commit(types.UPDATE_EMPLOYEE_HAS_JOB, normalizeData.entities.employeeHasJobs[key]));
    }
    if (normalizeData.entities.employeeHasContracts !== undefined) {
      Object.keys(normalizeData.entities.employeeHasContracts).forEach(key => commit(types.UPDATE_EMPLOYEE_HAS_CONTRACT, normalizeData.entities.employeeHasContracts[key]));
    }
    if (normalizeData.entities.employeeHasExpositions !== undefined) {
      Object.keys(normalizeData.entities.employeeHasExpositions).forEach(key => commit(types.UPDATE_EMPLOYEE_HAS_EXPOSITION, normalizeData.entities.employeeHasExpositions[key]));
    }
  },

  async getDeclarationData(context, firmId: number) {
    axios.get(Routing.generate('api_get_firm_contracts')).then((response) => {
      Contract.insertOrUpdate(response);
      return response;
    });

    const normalizeData = await fetch(declarationRequest.getDeclarationData, { id: firmId });
    context.commit(types.SET_DECLARATION, true);
    return context.dispatch('initData', normalizeData);
  },
  async getDeclaration(context, firmId: number) {
    const response = await axios.get<FirmUpdate[]>(Routing.generate('api_get_firm_declarations', { id: firmId }), {
      cancelToken: cancelToken.token,
    });
    await FirmUpdate.insertOrUpdate({ data: response.data });
  },
  async getActiveDeclaration(context, firmId: number): Promise<FirmUpdate> {
    const response = await axios.get<FirmUpdate>(Routing.generate('api_get_firm_declaration_active', { id: firmId }));
    await FirmUpdate.insertOrUpdate({ data: response.data });

    if (response.data) {
      context.commit('SET_INITIAL_STEP', response.data.currentStep);
    }

    return response.data;
  },

  async saveUpdate(context): Promise<any> {
    if (!context.state.editable) {
      return Promise.resolve();
    }

    try {
      await context.dispatch('updateEditedJobDate');
      await context.dispatch('saveFirms');
      await context.dispatch('saveStep');
    } catch (error) {
      await context.dispatch('saveStep');
      return Promise.reject(error);
    }

    return Promise.resolve();
  },
  async saveStep(context, step: number | undefined): Promise<any> {
    const currentStep = step !== undefined && step > context.state.currentStep ? step : context.state.currentStep;
    context.commit('UPDATE_CURRENT_STEP', currentStep);

    const firmId = context.rootState.UserModule.currentFirmId;

    const root: Unit = context.getters.getUnitsWithChildrenCurrentFirm;
    const employees: Employee[] = context.getters.getEmployeesCurrentFirm;

    const json = {
      structures: classToPlain(root, { groups: ['all'] }),
      employees: classToPlain(employees, { groups: ['all'] }),
    };

    return axios
      .patch(Routing.generate('api_patch_declaration_step', {
        id: firmId,
        nb: currentStep,
      }, false), {
        data: JSON.stringify(json),
      });
  },
  async validSubscription(context, effectiveReal: EffectiveReal): Promise<any> {
    if (!context.state.editable) {
      return Promise.resolve();
    }

    return axios.post(Routing.generate('api_post_declaration_effective_real'), {
      effective: effectiveReal,
    });
  },
  async validDeclaration(context) {
    if (!context.state.editable) {
      return Promise.resolve();
    }

    return axios.patch(Routing.generate('api_patch_declaration_valid', {
      id: context.rootState.UserModule.currentFirmId,
    }, false)).then((response) => {
      context.dispatch('refreshCurrentFirm', null, { root: true });
      return response;
    });
  },
  async validUpdate(context) {
    return axios.post(Routing.generate('api_post_declaration_valid_update', {
      id: context.rootState.UserModule.currentFirmId,
    }, false));
  },

  deleteItem(context, item: FirmItem) {
    const { type, uuid } = item;
    return context.dispatch(`delete${_.capitalize(type)}`, { uuid, cascade: true });
  },
  softDeleteItem({ state, getters }, payload: SoftDeletableData): FirmStructureItem {
    const { item, cascade } = payload;

    const parent = item.parentUuid ? state.units[item.parentUuid] : null;
    if (parent !== null && !parent.isSave && cascade) {
      const firm = Firm.find(item.firmId);
      if (firm) {
        const rootUnit = getters.getRootFirm(firm.id);
        item.parentUuid = rootUnit ? rootUnit.uuid : null;
      }
    }

    return item;
  },

  async addUnit(context, unit: Unit): Promise<Unit> {
    unit.new = true;
    if (unit.parentUuid) {
      const parent = context.state.units[unit.parentUuid];
      unit.lvl = parent ? parent.lvl + 1 : 1;
    }

    context.commit(types.ADD_UNIT, unit);
    return unit;
  },
  async updateRemoveJob(context, job: Job): Promise<Job> {
    job.remove = true;
    job.edit = false;
    job.new = false;

    context.commit(types.UPDATE_JOB, job);
    return job;
  },
  async updateRemoveUnit(context, unit: Unit): Promise<Unit> {
    unit.remove = true;
    unit.edit = false;
    unit.new = false;

    context.commit(types.UPDATE_UNIT, unit);
    return unit;
  },
  async updateUnit(context, unit: Unit): Promise<Unit> {
    unit.edit = true;
    context.commit(types.UPDATE_UNIT, unit);
    return unit;
  },
  async deleteUnit({ getters, dispatch, commit }, payload: { uuid: string, cascade: boolean }): Promise<void> {
    const { uuid, cascade } = payload;
    const unit = getters.getUnit(uuid) as Unit | null;

    if (unit === null) {
      return Promise.reject();
    }

    const root = getters.getRootFirm(unit.firmId) as Unit | null;

    const promises: Promise<any>[] = [];
    const jobsUuid = [...unit.jobsUuid];
    jobsUuid.forEach((jUuid) => {
      const job = getters.getJob(jUuid) as Job;
      // @ts-ignore
      promises.push(dispatch('changeParentUnit', { item: job, parentUuid: root.uuid }));
    });

    const childrenUuid = [...unit.childrenUuid];
    childrenUuid.forEach(u => promises.push(dispatch('deleteUnit', { uuid: u, cascade: true })));

    await Promise.all(promises);
    dispatch('updateRemoveUnit', unit);

    return Promise.resolve();
  },
  changeParentUnit({ state, dispatch }, payload: { item: FirmStructureItem, parentUuid: string }): Promise<any> {
    const { item, parentUuid } = payload;
    const parent = state.units[parentUuid];
    if (parent === null) {
      return Promise.reject();
    }

    item.parentUuid = parent.uuid;
    if (item instanceof Unit && item.lvl !== undefined) {
      item.lvl = parent.lvl + 1;
    }

    return dispatch(`update${_.capitalize(item.type)}`, item);
  },

  async addJob({ getters, commit, dispatch }, job: Job): Promise<Job> {
    job.new = false;
    job.edit = true;
    if (job.isSave) {
      job.id = null;
      job.reference = job.reference || job.uuid;
      job.uuid = uuidv4();
      job.version += 1;
      job.employeeHasJobsUuid = [];
    }

    commit(types.ADD_JOB, job);

    if (job.version > 1) {
      const oldVersion = getters.getVersionJob(job.reference, job.version - 1);
      if (oldVersion) {
        await dispatch('deleteJob', { uuid: oldVersion.uuid, transfer: job.uuid });
      }
    }

    return job;
  },
  async updateJob({ commit, dispatch }, job: Job): Promise<Job> {
    job.edit = true;
    commit(types.UPDATE_JOB, job);

    return job;
  },
  async deleteJob({ getters, commit, dispatch }, payload: { uuid: string, transfer?: string, cascade?: boolean }): Promise<void> {
    const { uuid, transfer, cascade } = payload;
    if (transfer !== undefined && transfer) {
      await dispatch('copyEmployeeHasJobs', {
        originUuid: uuid,
        targetUuid: transfer,
      });
    }

    const job = getters.getJob(uuid) as Job | null;
    if (job === null) {
      return Promise.reject();
    }

    const employeeHasJobsUuid = [...job.employeeHasJobsUuid];
    employeeHasJobsUuid.forEach(ehjUuid => dispatch('deleteEmployeeHasJob', { uuid: ehjUuid }));

    if (!job.isSave) {
      commit(types.DELETE_JOB, job);
    } else {
      const update = await dispatch('softDeleteItem', { item: job, cascade }) as Job;
      update.edit = true;
      await dispatch('updateJob', update);
    }

    dispatch('updateRemoveJob', job);
    return Promise.resolve();
  },

  async employeeSearch({ state, getters }, payload: { query: string, firmId: number }) {
    const { query } = payload;

    const route = Routing.generate('api_get_employee_search', { q: query });
    const response = await axios.get<Employee[]>(route);

    return response.data ? plainToClass(Employee, response.data) : [];
  },
  async employeeFetch({ state, getters, dispatch }, payload: { uuid: string, firmId: number }) {
    const { uuid } = payload;
    let employee = getters.getEmployee(uuid);
    if (!employee) {
      const route = Routing.generate('api_get_employee', { uuid });
      const response = await axios.get<Employee>(route);

      if (response.data) {
        dispatch('addEmployeeInfo', response.data);
      }
      employee = getters.getEmployee(uuid);
    }

    return employee;
  },

  async addEmployeeInfo({ commit }, data) {
    const normalizeData: any = normalize(data, schemaEmployee);

    Object.keys(normalizeData.entities.employees).forEach(key => commit(types.ADD_EMPLOYEE, normalizeData.entities.employees[key]));
    Object.keys(normalizeData.entities.persons).forEach(key => commit(types.ADD_PERSON, normalizeData.entities.persons[key]));
    if (normalizeData.entities.employeeHasJobs !== undefined) {
      Object.keys(normalizeData.entities.employeeHasJobs).forEach(key => commit(types.ADD_EMPLOYEE_HAS_JOB, normalizeData.entities.employeeHasJobs[key]));
    }
    if (normalizeData.entities.employeeHasContracts !== undefined) {
      Object.keys(normalizeData.entities.employeeHasContracts).forEach(key => commit(types.ADD_EMPLOYEE_HAS_CONTRACT, normalizeData.entities.employeeHasContracts[key]));
    }
    if (normalizeData.entities.employeeHasExpositions !== undefined) {
      Object.keys(normalizeData.entities.employeeHasExpositions).forEach(key => commit(types.ADD_EMPLOYEE_HAS_EXPOSITION, normalizeData.entities.employeeHasExpositions[key]));
    }
  },
  async updateEmployeeInfo({ state, dispatch, getters }, employee: Employee) {
    const {
      person, employeeHasJobs, employeeHasContracts, expositions,
    } = employee;

    const promises = [];
    const employeeOldData = state.employees[employee.uuid] || null;
    employee.personUuid = person.uuid;

    promises.push(person.new ? dispatch('addPerson', person) : dispatch('updatePerson', person));
    promises.push(employee.new ? dispatch('addEmployee', employee) : dispatch('updateEmployee', employee));

    employeeHasJobs.forEach((employeeHasJob) => {
      employeeHasJob.employeeUuid = employee.uuid;
      promises.push(employeeHasJob.new
        ? dispatch('addEmployeeHasJob', employeeHasJob)
        : dispatch('updateEmployeeHasJob', employeeHasJob));
    });

    if (employeeOldData) {
      const employeeHasJobsUuid = [...employeeOldData.employeeHasJobsUuid];
      employeeHasJobsUuid.forEach((employeeHasJobUuid) => {
        if (employeeHasJobs.find(e => e.uuid === employeeHasJobUuid) === undefined) {
          promises.push(dispatch('deleteEmployeeHasJob', { uuid: employeeHasJobUuid }));
        }
      });
    }

    employeeHasContracts.forEach((employeeHasContract) => {
      employeeHasContract.employeeUuid = employee.uuid;
      promises.push(employeeHasContract.new
        ? dispatch('addEmployeeHasContract', employeeHasContract)
        : dispatch('updateEmployeeHasContract', employeeHasContract));
    });

    if (employeeOldData) {
      const employeeHasContractsUuid = [...employeeOldData.employeeHasContractsUuid];
      employeeHasContractsUuid.forEach((employeeHasContractUuid) => {
        if (employeeHasContracts.find(e => e.uuid === employeeHasContractUuid) === undefined) {
          promises.push(dispatch('deleteEmployeeHasContract', { uuid: employeeHasContractUuid }));
        }
      });
    }

    expositions.forEach((exposition) => {
      exposition.employeeUuid = employee.uuid;
      promises.push(exposition.new
        ? dispatch('addEmployeeHasExposition', exposition)
        : dispatch('updateEmployeeHasExposition', exposition));
    });

    if (employeeOldData) {
      const employeeHasExpositionsUuid = [...employeeOldData.employeeHasExpositionsUuid];
      employeeHasExpositionsUuid.forEach((employeeHasExpositionUuid) => {
        if (expositions.find(e => e.uuid === employeeHasExpositionUuid) === undefined) {
          promises.push(dispatch('deleteEmployeeHasExposition', { uuid: employeeHasExpositionUuid }));
        }
      });
    }

    promises.push(dispatch('updateEmployeeHasJobByEmployee', {
      data: employee,
      oldData: employeeOldData,
    }));

    return Promise.all(promises);
  },

  async addPerson({ commit }, data: Person): Promise<Person> {
    data.new = false;
    data.edit = true;

    commit(types.ADD_PERSON, data);
    return data;
  },
  async updatePerson({ commit }, data: Person): Promise<Person> {
    data.edit = true;
    commit(types.UPDATE_PERSON, data);
    return data;
  },
  async deletePerson({ commit }, data: Person): Promise<void> {
    if (!data.isSave) {
      commit(types.DELETE_PERSON, data);
    }

    return Promise.resolve();
  },

  async addEmployee({ commit }, data: Employee): Promise<Employee> {
    data.new = false;
    data.edit = true;

    commit(types.ADD_EMPLOYEE, data);
    return data;
  },
  async updateEmployee({ state, commit, dispatch }, data: Employee): Promise<Employee> {
    data.edit = true;
    const oldData = state.employees[data.uuid];

    await dispatch('updateEmployeeHasJobByEmployee', {
      data,
      oldData,
    });

    commit(types.UPDATE_EMPLOYEE, data);
    return data;
  },
  async deleteEmployee({ state, dispatch, commit }, data: Employee): Promise<any> {
    const employee = state.employees[data.uuid];
    if (!employee) {
      return Promise.reject();
    }

    const promises: Promise<any>[] = [];
    const employeeHasJobsUuid = [...employee.employeeHasJobsUuid];
    employeeHasJobsUuid.forEach((employeeHasJobUuid) => {
      promises.push(dispatch('deleteEmployeeHasJob', { uuid: employeeHasJobUuid }));
    });

    const employeeHasContractsUuid = [...employee.employeeHasContractsUuid];
    employeeHasContractsUuid.forEach((employeeHasContractUuid) => {
      promises.push(dispatch('deleteEmployeeHasContract', { uuid: employeeHasContractUuid }));
    });

    const employeeHasExpositionsUuid = [...employee.employeeHasExpositionsUuid];
    employeeHasExpositionsUuid.forEach((expositionUuid) => {
      promises.push(dispatch('deleteEmployeeHasExposition', { uuid: expositionUuid }));
    });

    if (!employee.isSave) {
      commit(types.DELETE_EMPLOYEE, employee);
    } else {
      promises.push(dispatch('updateEmployee', {
        uuid: data.uuid,
        end_date: TODAY,
      }));
    }

    if (employee.personUuid !== null) {
      promises.push(dispatch('deletePerson', { uuid: employee.personUuid }));
    }

    return Promise.all(promises);
  },

  async addEmployeeHasContract({ commit }, data: EmployeeHasContract): Promise<EmployeeHasContract> {
    data.new = false;
    data.edit = true;

    commit(types.ADD_EMPLOYEE_HAS_CONTRACT, data);
    return data;
  },
  async updateEmployeeHasContract({ commit }, data: EmployeeHasContract): Promise<EmployeeHasContract> {
    data.edit = true;
    commit(types.UPDATE_EMPLOYEE_HAS_CONTRACT, data);
    return data;
  },
  async deleteEmployeeHasContract({ commit }, data: EmployeeHasContract): Promise<void> {
    commit(types.DELETE_EMPLOYEE_HAS_CONTRACT, data);
  },

  async addEmployeeHasJob({ state, commit, dispatch }, employeeHasJob: EmployeeHasJob): Promise<EmployeeHasJob> {
    const { jobUuid, employeeUuid } = employeeHasJob;
    const job = state.jobs[jobUuid];
    const employee = state.employees[employeeUuid];
    if (!employee || !job) {
      return Promise.reject();
    }

    const nbEmployeeHasJob = employee.employeeHasJobsUuid.length;
    const defaultDate = nbEmployeeHasJob > 0 && employee.beginDate && TODAY > employee.beginDate ? TODAY : employee.beginDate;

    employeeHasJob.new = false;
    employeeHasJob.edit = true;
    employeeHasJob.beginDate = employeeHasJob.beginDate || defaultDate;
    employeeHasJob.endDate = employeeHasJob.endDate || null;

    commit(types.ADD_EMPLOYEE_HAS_JOB, employeeHasJob);
    if (employee && !employee.edit) {
      commit(types.UPDATE_EMPLOYEE, {
        uuid: employee.uuid,
        edit: true,
      });
    }

    return employeeHasJob;
  },
  async updateEmployeeHasJob(context, data: EmployeeHasJob): Promise<EmployeeHasJob> {
    data.edit = true;

    const employeeHasJob = context.state.employeeHasJobs[data.uuid] as EmployeeHasJob;
    const employee = context.getters.getEmployee(employeeHasJob.employeeUuid) as Employee;
    if (employee && !employee.edit) {
      context.commit(types.UPDATE_EMPLOYEE, {
        uuid: employee.uuid,
        edit: true,
      });
    }

    context.commit(types.UPDATE_EMPLOYEE_HAS_JOB, data);
    return data;
  },
  async deleteEmployeeHasJob({ state, commit, dispatch }, data: EmployeeHasJob): Promise<void> {
    const employeeHasJob = state.employeeHasJobs[data.uuid];
    if (!employeeHasJob.isSave) {
      commit(types.DELETE_EMPLOYEE_HAS_JOB, employeeHasJob);
    } else if (employeeHasJob.isActive) {
      await dispatch('updateEmployeeHasJob', {
        uuid: data.uuid,
        endDate: TODAY,
      });
    }

    return Promise.resolve();
  },
  async copyEmployeeHasJobs({ state, dispatch }, payload: { targetUuid: string, originUuid: string }) {
    const { targetUuid, originUuid } = payload;

    const origin = state.jobs[originUuid];
    const target = state.jobs[targetUuid];
    if (!origin || !target) {
      return Promise.reject();
    }

    origin.employeeHasJobsUuid.forEach((ehjUuid) => {
      const employeeHasJob = state.employeeHasJobs[ehjUuid];
      if (!employeeHasJob.isActive) {
        return;
      }

      const employeeHasJobTarget = new EmployeeHasJob();
      employeeHasJobTarget.employeeUuid = employeeHasJob.employeeUuid;
      employeeHasJobTarget.jobUuid = target.uuid;
      employeeHasJobTarget.endDate = employeeHasJob.endDate || null;

      dispatch('addEmployeeHasJob', employeeHasJobTarget);
    });

    return Promise.resolve();
  },

  async addEmployeeHasExposition({ commit }, data: EmployeeHasExposition): Promise<EmployeeHasExposition> {
    data.new = false;
    data.edit = true;

    commit(types.ADD_EMPLOYEE_HAS_EXPOSITION, data);
    return data;
  },
  async updateEmployeeHasExposition({ commit }, data: EmployeeHasExposition): Promise<EmployeeHasExposition> {
    data.edit = true;
    commit(types.UPDATE_EMPLOYEE_HAS_EXPOSITION, data);
    return data;
  },
  async deleteEmployeeHasExposition({ state, commit, dispatch }, data: { uuid: string }): Promise<void> {
    const employeeHasExposition = state.employeeHasExpositions[data.uuid];
    if (!employeeHasExposition.isSave) {
      commit(types.DELETE_EMPLOYEE_HAS_EXPOSITION, employeeHasExposition);
    } else if (employeeHasExposition.isCurrent) {
      await dispatch('updateEmployeeHasExposition', {
        uuid: data.uuid,
        endDate: TODAY,
      });
    }

    return Promise.resolve();
  },

  async updateEmployeeHasJobByEmployee({ state, dispatch }, payload: { data: Employee, oldData: Employee | null }) {
    const { data, oldData } = payload;
    if (!oldData || (data.endDate === null && oldData.endDate === null)) {
      return;
    }

    const employee = state.employees[data.uuid];
    employee.employeeHasJobsUuid.forEach((ehjUuid) => {
      const employeeHasJob = state.employeeHasJobs[ehjUuid];

      const oldEndDateTime: number = oldData.endDate !== null ? oldData.endDate.getTime() : 0;
      const jobEndDateTime: number = employeeHasJob.endDate !== null ? employeeHasJob.endDate.getTime() : 0;
      if ((data.endDate && jobEndDateTime) || (!data.endDate && oldEndDateTime !== jobEndDateTime)) {
        return;
      }

      dispatch('updateEmployeeHasJob', {
        uuid: ehjUuid,
        endDate: data.endDate,
      });
    });
  },
} as ActionTree<FirmUpdateModuleInterface, any>;
