<template functional>
  <div class="portlet box" :class="[props.noAction ? 'no-action' : '', props.color]">
    <div class="portlet-title">
      <div class="caption">
        <slot name="title">{{ props.title }}</slot>
      </div>
      <div class="actions">
        <slot name="actions"></slot>
      </div>
    </div>
    <div class="portlet-body" :class="props.type">
      <slot></slot>
    </div>
  </div>
</template>

<script type="ts">
export default {
  functional: true,
  name: 'Portlet',
  props: {
    color: String,
    title: String,
    type: String,
    noAction: Boolean,
  },
};
</script>
