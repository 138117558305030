
import { Component, Vue } from 'vue-property-decorator';
import { State } from 'vuex-class';

import { lazyInject, SERVICE_IDENTIFIER } from '@/ioc/container';
import { IPageService } from '@/services/interfaces';

import User from '@models/user/User';

@Component
export default class Page extends Vue {
  @lazyInject(SERVICE_IDENTIFIER.IPageService)
  pageService!: IPageService;

  @State(state => state.UserModule.connectedUser)
  connectedUser!: User;

  isGranted(route: string, permission: number): boolean {
    const find = this.connectedUser ? this.connectedUser.permissions.find(p => p.route === route) : null;
    return find ? find.permission >= permission : false;
  }
}
