import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import {
  Exclude,
  Expose,
  Transform,
  Type,
} from 'class-transformer';

import Employee from './Employee';

@Exclude()
class EmployeeHasExposition {
  static entity = 'employeeHasExpositions';
  static primaryKey = 'uuid';

  @Expose({ groups: ['all'] })
  new: boolean = true;

  @Expose({ groups: ['all'] })
  edit: boolean = false;

  @Expose({ notEmptyOnly: true })
  id: number | null = null;

  @Expose()
  uuid: string = uuidv4();

  employeeUuid: string = '';

  employee: Employee | null = null;

  @Expose()
  exposition!: number;

  @Expose()
  @Type(() => Date)
  @Transform(value => moment(value).format('YYYY-MM-DDTHH:mm:ss'), { toPlainOnly: true })
  beginDate: Date = new Date();

  @Expose()
  @Type(() => Date)
  @Transform(value => (value ? moment(value).format('YYYY-MM-DDTHH:mm:ss') : null), { toPlainOnly: true })
  endDate: Date | null = null;

  get isSave(): boolean {
    return this.id !== null;
  }

  get isCurrent(): boolean {
    return !this.endDate || this.endDate > TODAY;
  }
}

export default EmployeeHasExposition;
