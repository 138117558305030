import * as _ from 'lodash';

_.mixin({
  deepCamelCaseKey: (data: string) => JSON.parse(data.replace(
    /(_\w)\w+":/g,
    match => match[1].toUpperCase() + match.substring(2),
  )),
  accentsTidy(s: string) {
    let r = s.toLowerCase();
    r = r.replace(new RegExp('[àáâãäå]', 'g'), 'a');
    r = r.replace(new RegExp('æ', 'g'), 'ae');
    r = r.replace(new RegExp('ç', 'g'), 'c');
    r = r.replace(new RegExp('[èéêë]', 'g'), 'e');
    r = r.replace(new RegExp('[ìíîï]', 'g'), 'i');
    r = r.replace(new RegExp('ñ', 'g'), 'n');
    r = r.replace(new RegExp('[òóôõö]', 'g'), 'o');
    r = r.replace(new RegExp('œ', 'g'), 'oe');
    r = r.replace(new RegExp('[ùúûü]', 'g'), 'u');
    r = r.replace(new RegExp('[ýÿ]', 'g'), 'y');
    return r;
  },
  omitDeep(collection, excludeKeys, condition) {
    function omitFn(value: any) {
      if (value && typeof value === 'object') {
        excludeKeys.forEach((key: string) => {
          if (value[key] && condition(value[key])) {
            delete value[key];
          }
        });
      }
    }

    return _.cloneDeepWith(collection, omitFn);
  },
});
