import moment from 'moment';
import {
  Exclude,
  Expose,
  Transform,
  Type,
} from 'class-transformer';
import { Fields, Model } from '@vuex-orm/core';

@Exclude()
class Person extends Model {
  static entity = 'person';
  static primaryKey = 'uuid';

  static fields(): Fields {
    return {
      new: this.boolean(true),
      edit: this.boolean(false),
      id: this.number(null).nullable(),
      tag: this.string(null).nullable(),
      uuid: this.string(''),
      origin: this.string(null).nullable(),
      forename: this.string(''),
      surname: this.string(null).nullable(),
      birthName: this.string(''),
      dateOfBirth: this.date(null).nullable(),
      sex: this.number(null).nullable(),
      numSecu: this.string(null).nullable(),
      validNoSS: this.boolean(false).nullable(),
    };
  }

  @Expose({ groups: ['all'] })
  new!: boolean;

  @Expose({ groups: ['all'] })
  edit!: boolean;

  @Expose({ notEmptyOnly: true })
  id!: number | null;

  @Expose()
  tag!: string | null;

  @Expose()
  uuid!: string;

  @Expose()
  origin!: string;

  @Expose()
  forename!: string;

  @Expose()
  surname!: string | null;

  @Expose()
  birthName!: string;

  @Expose()
  sex!: number | null;

  @Expose()
  numSecu!: string | null;

  @Expose()
  validNoSS!: boolean;

  @Expose()
  @Type(() => Date)
  @Transform(value => moment(value).format('YYYY-MM-DD'), { toPlainOnly: true })
  dateOfBirth!: Date;

  get isSave() {
    return this.id !== null || this.origin !== null;
  }

  get isAdult() {
    return moment(this.dateOfBirth).isBefore(moment().subtract(14, 'years'));
  }

  get label() {
    let useName = '';
    if (this.surname) {
      useName = this.surname.toUpperCase();
    } else if (this.birthName) {
      useName = this.birthName.toUpperCase();
    } else {
      useName = 'Aucun nom';
    }
    const forename = this.forename ? ` ${this.forename}` : ' sans prénom';
    const birthName = this.surname && this.birthName ? ` (né(e) ${this.birthName.toUpperCase()})` : '';

    return useName + forename + birthName;
  }
}

export default Person;
