import { Model } from '@vuex-orm/core';

export default class ActionPlanHasStructure extends Model {
  static entity = 'actionPlanHasStructure';

  static primaryKey = ['structureId', 'actionPlanId'];

  static fields() {
    return {
      actionPlanId: this.attr(null),
      structureId: this.attr(null),
    };
  }
}
