import dashboard from './dashboard';
import products from './products';
import synthesis from './synthesis';
import inventory from './inventory';

const synthesisView = () => import('../pages/synthesis/SynthesisView.vue');

export default [
  {
    path: '/risques-chimiques',
    name: 'chemi-risks',
    component: synthesisView,
  },
  ...dashboard,
  ...products,
  ...synthesis,
  ...inventory,
];
