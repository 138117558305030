import { Store } from 'vuex';

import AbstractRepository from '@/lib/repository-factory/AbstractRepository';
import Contract from '@models/firm/Contract';

export default class ContractRepository extends AbstractRepository<Contract> {
  query = {
    get: '',
    all: 'api_get_firm_contracts',
    create: '',
    update: '',
    delete: '',
  };

  constructor(store: Store<any>) {
    super(store, Contract);
    Contract.setRepository(this);
  }
}
