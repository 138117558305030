import moment from 'moment';
import {
  Exclude,
  Expose,
  Transform,
  Type,
} from 'class-transformer';
import { Fields, Model } from '@vuex-orm/core';
import { FirmItemType } from '@/types/firm/firm';

import Firm from '@models/firm/Firm';
import Person from '@models/firm/Person';
import EmployeeHasContract from '@models/firm/EmployeeHasContract';
import EmployeeHasJob from '@models/firm/EmployeeHasJob';
import Exposition from '@models/firm/Exposition';
import EmployeeHasExposition from '@models/firm/EmployeeHasExposition';

@Exclude()
class Employee extends Model {
  static entity = 'employee';
  static primaryKey = 'uuid';

  static fields(): Fields {
    return {
      new: this.boolean(true),
      edit: this.boolean(false),
      id: this.number(null).nullable(),
      tag: this.string(null).nullable(),
      uuid: this.string(''),
      type: this.string('employee'),
      beginDate: this.date(null),
      endDate: this.date(null).nullable(),
      firmId: this.number(null),
      firm: this.belongsTo(Firm, 'firmId'),
      personUuid: this.string(''),
      person: this.belongsTo(Person, 'personUuid'), // obj person
      employeeHasJobs: this.hasMany(EmployeeHasJob, 'employeeUuid', 'uuid'),
      employeeHasContracts: this.hasMany(EmployeeHasContract, 'employeeUuid', 'uuid'),
      expositions: this.hasMany(EmployeeHasExposition, 'employeeUuid', 'uuid'),
    };
  }

  @Expose({ groups: ['all'] })
  new!: boolean;

  @Expose({ groups: ['all'] })
  edit!: boolean;

  @Expose({ notEmptyOnly: true })
  id!: number | null;

  @Expose()
  tag!: string | null;

  @Expose()
  uuid!: string;

  type!: FirmItemType;

  @Expose()
  @Type(() => Date)
  @Transform(value => moment(value).format('YYYY-MM-DDTHH:mm:ss'), { toPlainOnly: true })
  beginDate!: Date | null;

  @Expose()
  @Type(() => Date)
  @Transform(value => (value ? moment(value).format('YYYY-MM-DDTHH:mm:ss') : null), { toPlainOnly: true })
  endDate!: Date | null;

  @Expose({ name: 'firm', toPlainOnly: true })
  firmId!: number;

  @Type(() => Firm)
  firm!: Firm;

  @Expose()
  personUuid!: string;

  @Expose()
  @Type(() => Person)
  person!: Person;

  @Expose()
  @Type(() => EmployeeHasExposition)
  expositions!: Array<EmployeeHasExposition>;

  @Expose()
  @Type(() => EmployeeHasJob)
  employeeHasJobs!: Array<EmployeeHasJob>;

  @Expose()
  @Type(() => EmployeeHasContract)
  employeeHasContracts!: Array<EmployeeHasContract>;

  get isSave() {
    return this.id !== null;
  }

  get label() {
    return this.person.label;
  }

  get activeJobs(): Array<EmployeeHasJob> {
    return this.employeeHasJobs.filter(ehj => ehj.isActive);
  }

  get archiveJobs(): Array<EmployeeHasJob> {
    return this.employeeHasJobs.filter(ehj => !ehj.isActive);
  }

  get isAffected() {
    let affected = false;
    if (this.endDate !== null && this.endDate.getTime() <= (new Date()).getTime()) {
      affected = true;
    }

    affected = affected || this.activeJobs.length > 0;

    return affected;
  }

  get isArchived() {
    return this.endDate !== null && !this.edit && this.endDate < TODAY;
  }

  get allExpositions(): Array<Exposition> {
    const allExpositionsNbr = this.currentExpositions.map(ehe => ehe.exposition);
    this.activeJobs.forEach((ehj) => {
      if (ehj.job && ehj.job.expositions) {
        ehj.job.expositions.forEach((jexpoNbr) => {
          if (!allExpositionsNbr.includes(jexpoNbr)) {
            allExpositionsNbr.push(jexpoNbr);
          }
        });
      }
    });

    const allExpositions: Array<Exposition> = [];
    allExpositionsNbr.forEach((nbr) => {
      const expo = Exposition.find(nbr);
      if (expo !== null && allExpositions.filter(e => e.code === expo.code).length === 0) {
        allExpositions.push(expo);
      }
    });
    return allExpositions;
  }

  get currentExpositions() {
    return this.expositions.filter(ehe => ehe.endDate === null || ehe.endDate > TODAY);
  }
}

export default Employee;
