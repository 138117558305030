export enum FirmItemType {
  Employee = 'employee',
  Unit = 'unit',
  Job = 'job',
}

export enum FirmPermissionEnum {
  NOTHING,
  READ,
  WRITE
}

export interface SelectableItem {
  type: FirmItemType;
  uuid: string;
}

export interface SoftDeletableData {
  item: FirmStructureItem;
  cascade: boolean;
}

export interface FirmItem extends SelectableItem {
  new: boolean;
  edit: boolean;
  label: string;
}

export interface FirmStructureItem {
  type: FirmItemType;
  uuid: string;
  parentUuid: string | null;
  firmId: number;
  label: string;
  remove: boolean;
}

export interface FirmItemUpdateForm extends Vue {
  isModify: boolean;

  validate(e: Event): Promise<any>;
}
