import { Expose, Type } from 'class-transformer';

import { FirmStructure } from '@/entities/firm/FirmStructureHierarchy';
import ProductSheet from './ProductSheet';

export enum ProductStatus {
  PRODUCT_PRODUCTION = 1,
  PRODUCT_TRY = 2,
  PRODUCT_OUT = 3,
}

class Product {
  _new: boolean = false;
  _edit: boolean = false;
  id: number | null = null;
  activity: string | null = null;
  comment: string | null = null;
  internalReference: string | null = null;
  quantity: string | null = null;
  frequency: string | null = null;
  status: ProductStatus | null = null;
  inputDate: string | null = null;
  outputDate: string | null = null;
  createdAt: string | null = null;
  updatedAt: string | null = null;
  folder: number | null = null;

  @Expose()
  @Type(() => ProductSheet)
  productSheet!: ProductSheet;

  @Expose()
  @Type(() => FirmStructure)
  structures: Array<FirmStructure> = [];

  get isSave() {
    return this.id !== null;
  }

  get statusToLabel() {
    let status = '';
    switch (this.status) {
      case 1:
        status = 'Production';
        break;
      case 2:
        status = 'Essai';
        break;
      case 3:
        status = 'Sortie';
        break;
      default:
        status = '';
        break;
    }
    return status;
  }
}

export default Product;
