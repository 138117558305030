import { Fields, Model } from '@vuex-orm/core';

import RiskCharacter from '@models/proRisk/RiskCharacter';
import PainfulnessQuestion from '@models/proRisk/PainfulnessQuestion';

class Painfulness extends Model {
  static entity = 'painfulness';

  static fields(): Fields {
    return {
      id: this.number(false),
      isAllRequired: this.boolean(false),
      riskCharacter: this.hasOne(RiskCharacter, 'painfulness'),
      questions: this.hasMany(PainfulnessQuestion, 'id'),
    };
  }

  id!: number;
  isAllRequired!: boolean;
  riskCharacter!: RiskCharacter;
  questions!: Array<PainfulnessQuestion>;
}

export default Painfulness;
