import { Module } from 'vuex';
import Routing from 'fos-routing';
import axios from 'axios';
import { plainToClass } from 'class-transformer';

import { FolderEnum } from '@/types/Folder';
import { PaginatedData } from '@/types/paginatedData';
import MercureServer from '@/mercure/MercureServer';

import CheFolder from '@modules/chemical-risk/entities/CheFolder';
import Product from '@modules/chemical-risk/entities/Product';
import Pictogram from '@modules/chemical-risk/entities/Pictogram';
import HazardStatement from '@modules/chemical-risk/entities/HazardStatement';
import ProductFolder from '@modules/chemical-risk/entities/ProductFolder';

import { AnnexVIInformation, AnnexVISubstance } from '@modules/chemical-risk/types/types';
import { CheModuleInterface } from './types';

const CheModule: Module<CheModuleInterface, any> = {
  namespaced: true,
  state: {
    activeCheFolder: 0,
    currentProduct: null,
    currentProductFolder: null,
    folders: [],
    hazardStatements: [],
    pictograms: [],
  },
  mutations: {
    setCurrentProduct(state, product: Product): void {
      state.currentProduct = product;
    },
    setCurrentProductFolder(state, product: ProductFolder): void {
      state.currentProductFolder = product;
    },
    addFolder(state, payload: CheFolder): void {
      const folder = state.folders.find(f => f.id === payload.id);
      if (folder) {
        Object.assign(folder, payload);
      } else {
        state.folders.push(payload);
      }
    },
    setActiveCheFolder(state, cheFolder: CheFolder): void {
      if (cheFolder.id !== null) {
        state.activeCheFolder = cheFolder.id;
      }
    },
    setHazardStatement(state, payload): void {
      state.hazardStatements = payload;
    },
    setPictograms(state, payload): void {
      state.pictograms = payload;
    },
  },
  getters: {
    getActiveCheFolder: (state) => {
      if (state.activeCheFolder !== 0) {
        return state.folders.find(f => f.id === state.activeCheFolder);
      }
      return null;
    },
    validActiveCheFolderStatus: (state, getters) => (status: Array<number>) => {
      if (state.activeCheFolder !== 0) {
        const cheFolder = getters.getActiveCheFolder;
        if (cheFolder && status.includes(cheFolder.status)) {
          return true;
        }
      }
      return false;
    },
    getFolder: state => (id: number) => state.folders.find(f => f.id === id),
    getHistoricCheFolders: state => state.folders.filter(f => f.status === FolderEnum.FOLDER_CLOSED),
    getHazardStatement: state => (id: number) => state.hazardStatements.find(hazardStatement => hazardStatement.id === id),
    getHazardStatementByReference: state => (reference: string) => state.hazardStatements.find(hazardStatement => hazardStatement.reference === reference),
    getHazardStatementByVersion: state => (version: number) => state.hazardStatements.filter(hazardStatement => hazardStatement.version === version),
    getCmrLabel: state => (ids: Array<number>) => {
      const hazardStatements = state.hazardStatements.filter(hazardStatement => ids.includes(hazardStatement.id));

      let label = '';
      let ca = false;
      let cs = false;
      let ma = false;
      let ms = false;
      let ra = false;
      let rs = false;
      hazardStatements.forEach((hazardStatement) => {
        /*
        Non CMR => 40
        C avéré => 10
        C suspecté => 11
        M avéré => 20
        M suspecté => 21
        R avéré => 30
        R suspecté => 31
         */
        ca = hazardStatement.cmr === 10 ? true : ca;
        cs = hazardStatement.cmr === 11 ? true : cs;
        ma = hazardStatement.cmr === 20 ? true : ma;
        ms = hazardStatement.cmr === 21 ? true : ms;
        ra = hazardStatement.cmr === 30 ? true : ra;
        rs = hazardStatement.cmr === 31 ? true : rs;
      });

      /* eslint-disable no-nested-ternary, prefer-template */
      label = '' + (ca ? 'C(a) ' : cs ? 'C(s) ' : '') + (ma ? 'M(a) ' : ms ? 'M(s) ' : '') + (ra ? 'R(a) ' : rs ? 'R(s) ' : '');
      label = label !== '' ? label : 'Non CMR';
      return label;
    },
    getSensibilityLabel: state => (ids: Array<number>) => {
      const hazardStatements = state.hazardStatements.filter(hazardStatement => ids.includes(hazardStatement.id));

      let label = '';
      let sc = false;
      let sr = false;
      hazardStatements.forEach((hazardStatement) => {
        /*
        Non sensibilisante => 40
        Sensibilisant cutané => 10
        Sensibilisant respiratoire => 11
         */
        sc = hazardStatement.sensibility === 10 ? true : sc;
        sr = hazardStatement.sensibility === 11 ? true : sr;
      });

      label = '' + (sc ? 'S(c) ' : '') + (sr ? 'S(r) ' : '');
      label = label !== '' ? label : 'Non Sensibilisante';
      return label;
    },
    getPictogram: state => (id: number) => state.pictograms.find(pictogram => pictogram.id === id),
    getPictogramByVersion: state => (version: number) => state.pictograms.filter(pictogram => pictogram.version === version),
  },
  actions: {
    async init(context) {
      await context.dispatch('getApiActiveCheFolder');

      MercureServer.addListener('che_folder', () => {
        context.dispatch('getApiActiveCheFolder');
        context.dispatch('getApiHistoricCheFolders');
      });
    },
    async loadProductsFolder(context, payload) {
      const response = await axios.get<PaginatedData<ProductFolder>>(Routing.generate('api_get_che_folder_products', {
        page: payload.page,
        filters: payload.filters,
        limit: typeof payload.filters.limit !== 'undefined' ? payload.filters.limit : 1000,
      }));
      response.data.items = plainToClass(ProductFolder, response.data.items);

      return response.data;
    },
    async loadProducts(context, payload) {
      const { filters } = payload;

      const response = await axios.get(Routing.generate('api_get_che_products', {
        filters,
        page: filters.page,
        limit: typeof filters.limit !== 'undefined' ? filters.limit : 1000,
        metaOnly: typeof filters.metaOnly !== 'undefined' && filters.metaOnly ? 1 : 0,
      }));
      response.data.items = plainToClass(Product, response.data.items);

      return response.data;
    },
    async loadPictograms(context) {
      const response = await axios.get<Array<Pictogram>>(Routing.generate('api_get_che_pictograms'));
      context.commit('setPictograms', plainToClass(Pictogram, response.data));

      return response.data;
    },
    async loadHazardStatements(context) {
      const response = await axios.get<Array<HazardStatement>>(Routing.generate('api_get_che_hazard_statements'));
      await context.commit('setHazardStatement', plainToClass(HazardStatement, response.data));
      return response.data;
    },
    async loadProductFolder(context, id) {
      const response = await axios.get(Routing.generate('api_get_che_folder_product', { id }));
      context.commit('setCurrentProductFolder', plainToClass(ProductFolder, response.data));
    },
    async loadProduct(context, id) {
      const response = await axios.get(Routing.generate('api_get_che_product', { id }));
      context.commit('setCurrentProduct', response.data);
    },
    async deleteProduct(context, id) {
      await axios.delete(Routing.generate('api_delete_che_product', { id }));
    },
    async deleteFolderProduct(context, id) {
      await axios.delete(Routing.generate('api_delete_che_product_folder', { id }));
    },
    async postProduct(context, payload) {
      const result = await axios.post(Routing.generate('api_post_pr_product'), payload);

      return result.data;
    },
    async patchProduct(context, product) {
      const response = await axios.patch(Routing.generate('api_patch_che_product', { id: product.id }), product);
      context.commit('setCurrentProduct', response.data);
      return response;
    },
    async patchProductFolder(context, productFolder) {
      const response = await axios.patch(Routing.generate('api_patch_che_product_folder', { id: productFolder.id }), productFolder);
      context.commit('setCurrentProduct', response.data);
      return response;
    },
    async synchronizeFolder(context, list) {
      const response = await axios.patch(Routing.generate('api_synchronize_che_folder'), { list });
      const cheFolder = response.data;
      context.commit('addFolder', cheFolder);
      context.commit('setActiveCheFolder', cheFolder);

      return response;
    },
    async analyseCheFolder(context, id) {
      const response = await axios.patch(Routing.generate('api_patch_che_folder_analyse', { id }));
      const cheFolder = response.data;
      context.commit('addFolder', cheFolder);
      context.commit('setActiveCheFolder', cheFolder);

      return response;
    },
    async getProductSheets(context, payload) {
      const regex = /\//gi;
      const response = await axios.get(Routing.generate('api_search_che_product_sheet', {
        label: payload.label ? payload.label : 'false',
        vendor: payload.vendor ? payload.vendor : 'false',
        year: payload.year ? payload.year.replace(regex, '-') : 'false',
      }));
      return response.data;
    },
    async getSubstances(context, number) {
      const response = await axios.get(Routing.generate('api_search_che_substances', { number }));
      return response.data;
    },
    async getApiActiveCheFolder(context) {
      const response = await axios.get(Routing.generate('api_get_active_che_folder'));
      const cheFolder = response.data;
      context.commit('addFolder', cheFolder);
      context.commit('setActiveCheFolder', cheFolder);

      return response;
    },
    async getApiHistoricCheFolders(context) {
      const response = await axios.get<Array<CheFolder>>(Routing.generate('api_get_historic_che_folder'));
      response.data.forEach(f => context.commit('addFolder', f));

      return response;
    },
    async postApiActiveChemRiskFolder(context, chemRisk) {
      const response = await axios.post(Routing.generate('api_post_che_folder'), chemRisk);
      if (response.data !== null) {
        const cheFolder = response.data;
        context.commit('addFolder', cheFolder);
        context.commit('setActiveCheFolder', cheFolder);
      }
      return response.data;
    },
    loadAnnexVI() {
      return axios
        .get<AnnexVISubstance[]>(Routing.generate('api_annex_che_substances'))
        .then(response => response.data);
    },
    getAnnexVISubstances(context, productSheetUuid) {
      return axios
        .get<AnnexVISubstance>(Routing.generate('api_che_annex_product_sheet_substances', { uuid: productSheetUuid }))
        .then(response => response.data);
    },
    getAnnexVIInformation() {
      return axios.get<AnnexVIInformation>(Routing.generate('api_annex_info')).then(response => response.data);
    },
    getPreviewPDF(context, id) {
      const url = Routing.generate('api_preview_che_folder', { id });
      window.open(url);
    },
    getSeirich(context) {
      const url = Routing.generate('api_seirich_che_folder');
      window.open(url);
    },
  },
};

export default CheModule;
