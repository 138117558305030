import { Store } from 'vuex';

import AbstractRepository from '@/lib/repository-factory/AbstractRepository';
import Employee from '@models/firm/Employee';

export default class EmployeeRepository extends AbstractRepository<Employee> {
  query = {
    get: 'api_get_employee',
    all: '',
    create: 'api_post_firm_employees',
    update: 'api_put_firm_employees',
    delete: '',
  };

  constructor(store: Store<any>) {
    super(store, Employee);
    Employee.setRepository(this);
  }

  getAll(): Employee[] {
    return [];
  }

  hasEditedCurrentFirm() {
    return this.hasEdited(this.store.state.UserModule.currentFirmId);
  }

  hasEdited(currentFirm: number) {
    return Employee.query().where('edit', true).where('firmId', currentFirm).count() > 0;
  }
}
