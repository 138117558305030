import axios from 'axios';
import Routing from 'fos-routing';
import { ActionTree } from 'vuex';
import { classToPlain, plainToClass } from 'class-transformer';

import { FirmModuleState } from '@/store/modules/firm/types';
import Job from '@models/firm/Job';
import Employee from '@models/firm/Employee';
import Unit from '@models/firm/Unit';

import Firm from '@models/firm/Firm';
import Exposition from '@models/firm/Exposition';
import Contract from '@models/firm/Contract';
import * as types from './mutation-types';

export default {
  async loadFirmStructure(context, id) {
    const response = await axios.get(Routing.generate('api_get_firm_firm_organization', { id }));
    context.commit(types.SET_FIRM_STRUCTURES, response.data);
    await Job.insertOrUpdate({ data: response.data.jobs });
  },
  async loadStructure(context, id) {
    const response = await axios.get(Routing.generate('api_get_firm_firm_structures', { id }));
    context.commit(types.SET_STRUCTURES, response.data);
  },
  async loadSharedFiles(context) {
    const response = await axios.get(Routing.generate('api_get_shared_files'));
    context.commit(types.SET_SHARED_FILES, response.data);
  },
  async getEmployeesByFullName(context, fullName) {
    const response = await axios.get(Routing.generate('api_get_firm_employee_by_fullName', { fullName }));
    return plainToClass(Employee, response.data);
  },
  async getEmployees(context, payload) {
    await Employee.deleteAll();
    const response = await axios.get(Routing.generate('api_get_firm_employees', {
      page: payload.page,
      filters: payload.filters,
    }));
    await Employee.insertOrUpdate({ data: response.data.objects });
    return response.data;
  },
  async getExpositions(context) {
    const response = await axios.get(Routing.generate('api_get_firm_expositions'));
    return Exposition.insertOrUpdate({ data: response.data });
  },
  async getActivesJobs(context) {
    await Job.deleteAll();
    const response = await axios.get(Routing.generate('api_get_firm_actives_jobs'));
    await Job.insertOrUpdate({ data: response.data });
    return response.data;
  },
  async getActivesUnits(context) {
    await Unit.deleteAll();
    const response = await axios.get(Routing.generate('api_get_firm_actives_units'));
    await Unit.insertOrUpdate({ data: response.data });
    return response.data;
  },
  async getContracts(context) {
    await Contract.deleteAll();
    const response = await axios.get(Routing.generate('api_get_firm_contracts'));
    await Contract.insertOrUpdate({ data: response.data });
    return response.data;
  },
  async getContacts(context, id) {
    const result = await axios.get(Routing.generate('api_get_firm_contacts', { id }));
    return Firm.insertOrUpdate({ data: result.data });
  },
  async getFirm(context, id) {
    const result = await axios.get(Routing.generate('api_get_firm_firm', { id }));
    return Firm.insertOrUpdate({ data: result.data });
  },
  async postSharedFile(context, payload) {
    const result = await axios.post(Routing.generate('api_post_shared_file'), payload);
    return result.data;
  },
  async deleteSharedFile(context, id) {
    await axios.delete(Routing.generate('api_delete_shared_file', { id }));
  },
} as ActionTree<FirmModuleState, any>;
