async function promisesProgress<T>(promises: Promise<T>[], progress: (progress: number, response: T) => void) {
  let complete = 0;
  await promises.reduce((p, v) => p.then(async () => {
    complete += 1;
    const object = await v;
    progress(complete, object);
  }), Promise.resolve());

  return Promise.resolve();
}

export default promisesProgress;
