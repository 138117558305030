import 'reflect-metadata';
import 'es6-shim';
import moment from 'moment';
import 'moment/locale/fr';

import Vue from 'vue';
import VuePortal from 'portal-vue';
import { sync } from 'vuex-router-sync';
import Sticky from 'vue-sticky-directive';

import { Module } from '@/types/modules';

import '#/css/global/base.scss';

import { Component } from 'vue-property-decorator';
import { container } from './ioc/container';

import './global';
import './element-ui';
import './font-awesome';
import './mixins';
import './api';

import router from './router/proxy';
import store from './store';
import database from './store/database';
import repositoryFactory from './store/repository';
import i18n from './i18n';
import plugin from './plugin';

import Page from './mixins/Page.vue';
import App from './App.vue';

sync(store, router.router, { moduleName: 'RouteModule' });

moment.locale('fr');

Vue.config.productionTip = false;
Vue.config.devtools = process.env.NODE_ENV !== 'production';
Vue.config.silent = process.env.NODE_ENV === 'production';

Vue.use(VuePortal);
Vue.use(plugin);
Vue.use(Sticky);
Vue.mixin(Page);

const modules = require.context('@modules', true, /Module\.ts$/);
modules.keys().forEach((v) => {
  const IModule = modules(v).default;
  const module = new IModule(store, router, container, database, repositoryFactory) as Module;
  module.install({});
});
router.injectRoutes();

Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteLeave',
  'beforeRouteUpdate',
]);

new Vue({
  router: router.router,
  store,
  render: h => h(App),
  i18n,
}).$mount('#app');
