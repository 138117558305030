import { AbstractModule } from '@/types/modules';

import routes from './router';
import store from './store';

import { CheModuleInterface } from './store/types';

export default class ChemicalRiskModule extends AbstractModule {
  install(options: any): void {
    this.router.addRoutes(routes);
    this.store.registerModule<CheModuleInterface>('cheRisk', store);
    this.store.dispatch('cheRisk/init');
  }
}
