import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import {
  Exclude,
  Expose,
  Transform,
  Type,
} from 'class-transformer';

import { FirmItemType } from '@/types/firm/firm';

import Firm from '@models/firm/Firm';

import Person from './Person';
import EmployeeHasContract from './EmployeeHasContract';
import EmployeeHasJob from './EmployeeHasJob';
import EmployeeHasExposition from './EmployeeHasExposition';

@Exclude()
class Employee {
  static entity = 'employees';
  static primaryKey = 'uuid';

  @Expose({ groups: ['all'] })
  new: boolean = true;

  @Expose({ groups: ['all'] })
  edit: boolean = false;

  @Expose({ notEmptyOnly: true })
  id: number | null = null;

  @Expose()
  tag: string | null = null;

  @Expose()
  uuid: string = uuidv4();

  type: FirmItemType = FirmItemType.Employee;

  @Expose()
  @Type(() => Date)
  @Transform(value => moment(value).format('YYYY-MM-DDTHH:mm:ss'), { toPlainOnly: true })
  beginDate: Date = new Date();

  @Expose()
  @Type(() => Date)
  @Transform(value => (value ? moment(value).format('YYYY-MM-DDTHH:mm:ss') : null), { toPlainOnly: true })
  endDate: Date | null = null;

  @Expose({ name: 'firm', toPlainOnly: true })
  firmId: number = 0;

  @Type(() => Firm)
  firm: Firm | null = null;

  @Expose()
  personUuid: string | null = null;

  @Expose()
  @Type(() => Person)
  person!: Person;

  @Expose()
  @Type(() => EmployeeHasExposition)
  expositions: EmployeeHasExposition[] = [];

  @Expose()
  @Type(() => EmployeeHasJob)
  employeeHasJobs: EmployeeHasJob[] = [];

  @Expose()
  @Type(() => EmployeeHasContract)
  employeeHasContracts: EmployeeHasContract[] = [];

  employeeHasExpositionsUuid: string[] = [];

  employeeHasJobsUuid: string[] = [];

  employeeHasContractsUuid: string[] = [];

  get isSave() {
    return this.id !== null;
  }

  get isActive() {
    return this.endDate === null || this.endDate > TODAY;
  }

  get label() {
    return this.person ? this.person.label : '';
  }

  get activeJobs(): EmployeeHasJob[] {
    return this.employeeHasJobs.filter(ehj => ehj.isActive);
  }

  get archiveJobs(): EmployeeHasJob[] {
    return this.employeeHasJobs.filter(ehj => !ehj.isActive);
  }

  get isAffected() {
    let affected = !this.isActive;
    affected = affected || this.activeJobs.length > 0;
    return affected;
  }

  get isArchived() {
    return this.endDate !== null && !this.edit && this.endDate < TODAY;
  }

  get currentExpositions() {
    return this.expositions.filter(ehe => ehe.endDate === null || ehe.endDate > TODAY);
  }

  get toShow() {
    return this.endDate === null || moment(this.endDate).isSameOrAfter(moment().startOf('year')) || this.edit;
  }
}

export default Employee;
