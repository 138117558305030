import store from '@/store';
import { NavigationGuard } from 'vue-router';

const listing = () => import('../pages/document/ListingView.vue');
const identification = () => import('../pages/document/RiskIdentificationView.vue');
const identificationEdit = () => import('../pages/document/RiskIdentificationEditView.vue');
const synthesis = () => import('../pages/document/SynthesisView.vue');
const unitMatrix = () => import('../pages/document/UnitMatrixView.vue');

const proRiskIdentificationBeforeEnter: NavigationGuard = async function proRiskIdentificationBeforeEnter(to, from, next) {
  const promises = [];
  promises.push(store.dispatch('repositories/riskCharacter/findAll'));

  if (to.params.id !== undefined) {
    promises.push(store.dispatch('repositories/risk/find', to.params.id));
  }

  Promise.all(promises).then(() => {
    next();
  });
};

const proRiskListingBeforeEnter: NavigationGuard = async function proRiskListingBeforeEnter(to, from, next) {
  const folder = await store.dispatch('riskPro/getApiActiveProFolder');
  if (!folder) {
    next({ name: 'pro-risks-dashboard' });
    return;
  }

  const promises = [];
  promises.push(store.dispatch('repositories/riskCharacter/findAll'));
  Promise.all(promises).then(() => {
    next();
  });
};

export default [
  {
    path: '/risques-professionnels/document-unique/identification-des-risques',
    name: 'pro-risks-identification',
    component: identification,
    beforeEnter: proRiskIdentificationBeforeEnter,
    meta: {
      breadcrumb: [
        { name: 'Accueil', link: 'home', icon: 'home' },
        { name: 'Risques Professionnels', link: 'pro-risks-dashboard' },
        { name: 'Document unique', link: 'pro-risks-identification' },
        { name: 'Identification des risques' },
      ],
      permission: 1,
      access: () => store.getters['riskPro/validActiveProFolderStatus']([0]),
    },
  },
  {
    path: '/risques-professionnels/document-unique/identification-des-risques/create',
    name: 'pro-risks-identification-create',
    component: identificationEdit,
    beforeEnter: proRiskIdentificationBeforeEnter,
    meta: {
      breadcrumb: [
        { name: 'Accueil', link: 'home', icon: 'home' },
        { name: 'Risques Professionnels', link: 'pro-risks-dashboard' },
        { name: 'Document unique' },
        { name: 'Identification des risques', link: 'pro-risks-identification' },
        { name: 'Création d\'un risque' },
      ],
      group: 'pro-risks-identification',
      permission: 2,
      access: () => store.getters['riskPro/validActiveProFolderStatus']([0]),
    },
  },
  {
    path: '/risques-professionnels/document-unique/identification-des-risques/:id?',
    name: 'pro-risks-identification-edit',
    component: identificationEdit,
    beforeEnter: proRiskIdentificationBeforeEnter,
    meta: {
      breadcrumb: [
        { name: 'Accueil', link: 'home', icon: 'home' },
        { name: 'Risques Professionnels', link: 'pro-risks-dashboard' },
        { name: 'Document unique', link: 'pro-risks-identification' },
        { name: 'Identification des risques', link: 'pro-risks-identification' },
        { name: 'Modification d\'un risque' },
      ],
      group: 'pro-risks-identification',
      permission: 2,
      access: () => store.getters['riskPro/validActiveProFolderStatus']([0]),
    },
  },
  {
    path: '/risques-professionnels/document-unique/identification-des-risques/:id/duplicate',
    name: 'pro-risks-identification-duplicate',
    component: identificationEdit,
    beforeEnter: proRiskIdentificationBeforeEnter,
    meta: {
      breadcrumb: [
        { name: 'Accueil', link: 'home', icon: 'home' },
        { name: 'Risques Professionnels', link: 'pro-risks-dashboard' },
        { name: 'Document unique', link: 'pro-risks-identification' },
        { name: 'Identification des risques' },
      ],
      group: 'pro-risks-identification',
      permission: 2,
      access: () => store.getters['riskPro/validActiveProFolderStatus']([0]),
    },
  },
  {
    path: '/risques-professionnels/document-unique/cotation',
    name: 'pro-risks-cotation',
    component: listing,
    beforeEnter: proRiskListingBeforeEnter,
    meta: {
      breadcrumb: [
        { name: 'Accueil', link: 'home', icon: 'home' },
        { name: 'Risques Professionnels', link: 'pro-risks-dashboard' },
        { name: 'Document unique', link: 'pro-risks-identification' },
        { name: 'Cotation des risques' },
      ],
      group: 'pro-risks-cotation',
      permission: 2,
      access: () => store.getters['riskPro/validActiveProFolderStatus']([0]),
    },
  },
  {
    path: '/risques-professionnels/synthese',
    name: 'pro-risks-synthesis',
    component: synthesis,
    beforeEnter: proRiskListingBeforeEnter,
    meta: {
      breadcrumb: [
        { name: 'Accueil', link: 'home', icon: 'home' },
        { name: 'Risques Professionnels', link: 'pro-risks-dashboard' },
        { name: 'Document unique', link: 'pro-risks-identification' },
        { name: 'Synthèse' },
      ],
      group: 'pro-risks-synthesis',
      permission: 1,
      access: () => store.getters['riskPro/validActiveProFolderStatus']([0]),
    },
  },
  {
    path: '/risques-professionnels/matrice-unites-risques-professionnels',
    name: 'pro-risks-unit-matrix',
    component: unitMatrix,
    beforeEnter: proRiskListingBeforeEnter,
    meta: {
      breadcrumb: [
        { name: 'Accueil', link: 'home', icon: 'home' },
        { name: 'Risques Professionnels', link: 'pro-risks-dashboard' },
        { name: 'Document unique', link: 'pro-risks-identification' },
        { name: 'Matrice unités / risques professionnels' },
      ],
      group: 'pro-risks-unit-matrix',
      permission: 1,
      access: () => store.getters['riskPro/validActiveProFolderStatus']([0]),
    },
  },
];
