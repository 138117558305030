import { Fields, Model } from '@vuex-orm/core';

import Employee from '@models/firm/Employee';
import ProFolder from '@models/proRisk/ProFolder';
import RiskCharacter from '@models/proRisk/RiskCharacter';

class RiskEmployee extends Model {
  static entity = 'riskEmployee';

  static fields(): Fields {
    return {
      id: this.number(false),
      response: this.string(false),
      comment: this.string(false),
      isHardness: this.boolean(false),
      folder: this.hasOne(ProFolder, 'riskEmployees'),
      employee: this.hasOne(Employee, 'id'),
      character: this.hasOne(RiskCharacter, 'id'),
    };
  }

  id!: number;
  response!: string;
  comment!: string;
  isHardness!: boolean;
  folder!: ProFolder;
  employee!: Employee;
  character!: RiskCharacter;
}

export default RiskEmployee;
