import { Options } from '@vuex-orm/core/lib/plugins/use';

import { RepositoryInterface } from '@/lib/repository-factory/RepositoryInterface';

class RepositoryVuexORMPlugin {
  install(components: any, config: Options): void {
    components.Model.setRepository = function setRepository(repository: RepositoryInterface<any>) {
      this.repository = repository;
    };
  }
}

export default new RepositoryVuexORMPlugin();
