import { Model, Fields } from '@vuex-orm/core';

class FirmRole extends Model {
  static entity = 'firmRoles';

  static fields(): Fields {
    return {
      id: this.number(null),
      code: this.string(''),
      name: this.string(''),
    };
  }

  id!: number;
  code!: string;
  name!: string;
}

export default FirmRole;
