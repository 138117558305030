

















import { Component, Vue } from 'vue-property-decorator';

@Component
export default class Footer extends Vue {
  scrollTop() {
    const container = document.querySelector('.page-container');
    if (container) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }
  }
}
