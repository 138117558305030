import { Store } from 'vuex';
import AbstractRepository from '@/lib/repository-factory/AbstractRepository';
import RiskCharacter from '@models/proRisk/RiskCharacter';

export default class RiskCharacterRepository extends AbstractRepository<RiskCharacter> {
  query = {
    get: 'api_get_pr_risk_character',
    all: 'api_get_all_pr_risk_character',
    create: '',
    update: '',
    delete: '',
  };

  constructor(store: Store<any>) {
    super(store, RiskCharacter);
    RiskCharacter.setRepository(this);
  }

  public getAll() {
    return super.getAll().sort((a, b) => (a.label < b.label ? -1 : 1));
  }

  public getPotentiallyHard() {
    return RiskCharacter.query().where('isPotentiallyHard', true).get();
  }

  public fetchPaginatedRisk(limit: number, page: number) {

  }
}
