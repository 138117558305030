import moment from 'moment';
import {
  Exclude,
  Expose,
  Transform,
  Type,
} from 'class-transformer';
import { Fields, Model } from '@vuex-orm/core';

import Contract from '@models/firm/Contract';
import Employee from '@models/firm/Employee';

@Exclude()
class EmployeeHasContract extends Model {
  static entity = 'employeeHasContract';
  static primaryKey = 'uuid';

  static fields(): Fields {
    return {
      new: this.boolean(true),
      edit: this.boolean(false),
      id: this.number(null).nullable(),
      uuid: this.string(''),
      employeeUuid: this.string(''),
      employee: this.belongsTo(Employee, 'employeeUuid'),
      contractId: this.number(null).nullable(),
      contract: this.belongsTo(Contract, 'contractId'),
      dateStart: this.date(''),
      dateEnd: this.date(null).nullable(),
    };
  }

  @Expose({ groups: ['all'] })
  new!: boolean;

  @Expose({ groups: ['all'] })
  edit!: boolean;

  @Expose({ notEmptyOnly: true })
  id!: number | null;

  @Expose()
  uuid!: string;

  @Expose({ groups: ['all'] })
  employeeUuid!: string;

  employee!: Employee;

  @Expose({ groups: ['all'] })
  contractId!: number | null;

  @Expose()
  @Transform(value => (value ? value.id : null), { toPlainOnly: true })
  contract!: Contract | null;

  @Expose()
  @Type(() => Date)
  @Transform(value => moment(value).format('YYYY-MM-DDTHH:mm:ss'), { toPlainOnly: true })
  dateStart!: Date | null;

  @Expose()
  @Type(() => Date)
  @Transform(value => (value ? moment(value).format('YYYY-MM-DDTHH:mm:ss') : null), { toPlainOnly: true })
  dateEnd!: Date | null;

  get isSave() {
    return this.id !== null;
  }

  get isActive() {
    return this.dateEnd === null || moment(this.dateEnd).isAfter(moment());
  }
}

export default EmployeeHasContract;
