import { Exclude, Expose, Type } from 'class-transformer';
import { Fields, Model } from '@vuex-orm/core';

import Firm from '@models/firm/Firm';

@Exclude()
class Group extends Model {
  static entity = 'group';

  static fields(): Fields {
    return {
      id: this.number(null),
      label: this.string(null),
      firms: this.hasMany(Firm, 'groupId'),
    };
  }

  @Expose()
  id!: number;

  @Expose()
  label!: string;

  @Expose()
  @Type(() => Firm)
  firms!: Array<Firm>;
}

export default Group;
