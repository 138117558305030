import { ActionTree } from 'vuex';
import axios from 'axios';
import Routing from 'fos-routing';
import { plainToClass } from 'class-transformer';

import MercureServer from '@/mercure/MercureServer';

import Risk from '@models/proRisk/Risk';
import ProFolder from '@models/proRisk/ProFolder';

import { RiskProModuleInterface } from './types';
import * as types from './mutation-types';

export default {
  async init(context) {
    await context.dispatch('getApiActiveProFolder');

    MercureServer.addListener('pro_folder', () => {
      context.dispatch('getApiActiveProFolder');
      context.dispatch('listHistoricProFolders');
    });
  },

  async duplicate(context, id): Promise<void> {
    const { data } = await axios.post(Routing.generate('api_duplicate_pr_risk', { id }));
    await Risk.insertOrUpdate({ data });
  },

  async postApiActiveProFolder(context, form): Promise<ProFolder | null> {
    const params = form.cloneId ? { id: form.cloneId } : {};
    delete form.cloneId;

    const response = await axios.post(Routing.generate('api_post_pr_pro_folder', params), form);
    const proFolder = response.data;
    if (proFolder) {
      context.commit('setActiveProFolder', proFolder);
      await ProFolder.insertOrUpdate({ data: proFolder });
    }

    return proFolder;
  },
  async getApiActiveProFolder(context): Promise<ProFolder | null> {
    const response = await axios.get<ProFolder>(Routing.generate('api_get_pr_active_pro_folder'), { validateStatus: status => status < 500 });
    const proFolder = response.data || null;
    context.commit('setActiveProFolder', proFolder);
    if (proFolder) {
      await ProFolder.insertOrUpdate({ data: proFolder });
    }

    return proFolder;
  },
  async getApiHistoricProFolders(context) {
    const response = await axios.get(Routing.generate('api_get_pr_historic_pro_folder'));
    await ProFolder.insertOrUpdate({ data: response.data });
    return response;
  },
  async listHistoricProFolders(context) {
    const response = await axios.get(Routing.generate('api_list_pr_pro_folder'));
    context.commit(types.SET_HISTORIC_PRO_FOLDER, plainToClass(ProFolder, response.data));
    return response.data;
  },
  async closeProFolder(context, id) {
    const response = await axios.patch(Routing.generate('api_patch_pro_folder_close', { id }));
    const proFolder = response.data;
    context.commit('setActiveProFolder', proFolder);
    await ProFolder.insertOrUpdate({ data: response.data });

    return response;
  },
  getPreviewPDF(context, id) {
    const url = Routing.generate('api_preview_pro_folder', { id });
    window.open(url);
  },
} as ActionTree<RiskProModuleInterface, any>;
