import FirmContactModel from '@/store/models/firm/FirmContactModel';
import { Expose, Exclude } from 'class-transformer';

@Exclude()
class FirmContact extends FirmContactModel {
  @Expose()
  id!: number;

  @Expose()
  name!: string;

  @Expose()
  contact!: string;

  @Expose()
  email!: string;

  @Expose()
  address!: string;

  @Expose()
  postalCode!: string;

  @Expose()
  city!: string;

  @Expose()
  phone!: string;

  @Expose()
  mobile!: string;

  @Expose()
  fax!: string;
}

export default FirmContact;
