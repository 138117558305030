import axios from 'axios';
import Routing from 'fos-routing';
import { classToPlain, plainToClass } from 'class-transformer';

import { Store } from 'vuex';
import { Model } from '@vuex-orm/core';
import Collections from '@vuex-orm/core/lib/data/Collections';

import { RepositoryInterface } from './RepositoryInterface';

export interface QueryInterface {
  [key: string]: string | { name: string, groups: string[] }
}

export default abstract class AbstractRepository<T extends Model> implements RepositoryInterface<T> {
  name: string;

  store: Store<any>;

  usePatch: boolean = false;

  haveAllData: boolean = false;

  abstract query: QueryInterface = {
    get: '',
    all: '',
    create: '',
    update: '',
    delete: '',
  };

  force: boolean = false;

  protected constructor(store: Store<any>, private readonly classRef: typeof Model) {
    this.classRef = classRef;
    this.name = classRef.entity;
    this.store = store;
  }

  private objectId(object: T): string | number | null {
    if (Array.isArray(this.classRef.primaryKey)) {
      const primaryKeys: string[] = [];
      this.classRef.primaryKey.forEach((k: string) => {
        if (k in object) {
          // @ts-ignore
          primaryKeys.push(window.prop(object, k));
        }
      });

      return primaryKeys.join('_');
    }

    // @ts-ignore
    return this.classRef.primaryKey in object ? window.prop(object, this.classRef.primaryKey) : null;
  }

  protected fetchByOrm(id ?: number | string) {
    return id ? this.classRef.find(id) || null : this.classRef.all();
  }

  protected fetchByCache(id ?: number | string) {
    return null;
  }

  protected async fetchByApi(id ?: number | string) {
    let route;
    if (id) {
      const [url] = AbstractRepository.getQueryData(this.query.get);
      route = Routing.generate(url, { id });
    } else {
      const [url] = AbstractRepository.getQueryData(this.query.all);
      route = Routing.generate(url);
    }

    const response = await axios.get<T | T[]>(route);
    return response.data;
  }

  async fetch(id?: string | number, store: boolean = true): Promise<T[]> {
    const data = this.fetchByCache(id) || await this.fetchByApi(id) || null;
    if (data) {
      if (store) {
        const d = await this.classRef.insertOrUpdate({ data });
        return d[this.name] as T[];
      }

      let classes = [];
      if (Array.isArray(data)) {
        classes = data.map(v => plainToClass(this.classRef, v));
      } else {
        classes.push(plainToClass(this.classRef, data));
      }

      return classes as T[];
    }

    return [];
  }

  get(id: string | number): T | null {
    return this.classRef.find(id) as T || null;
  }

  getAll(): T[] {
    return this.classRef.all() as T[] || [];
  }

  async find(id: string | number): Promise<T | null> {
    let object = !this.force ? this.classRef.find(id) || null : null;
    if (!object) {
      object = (await this.fetch(id)).shift() as T | null;
    }

    return object as T;
  }

  async findAll(): Promise<T[]> {
    let object;
    if (this.haveAllData && !this.force) {
      object = this.classRef.all() || [];
    } else {
      object = await this.fetch();
      this.haveAllData = true;
    }

    return object as T[];
  }

  async insertOrUpdate(object: T, send: boolean = true): Promise<Collections> {
    const id = this.objectId(object);
    if (id) {
      return this.update(object, send);
    }

    return this.create(object, send);
  }

  async create(object: T, send: boolean = true): Promise<Collections> {
    let payload = object;
    if (send) {
      const [url, groups] = AbstractRepository.getQueryData(this.query.create);
      const response = await axios.post(Routing.generate(url), classToPlain(payload, { groups }));
      payload = response.data;
    }

    return this.classRef.insertOrUpdate({ data: payload });
  }

  async update(object: T, send: boolean = true): Promise<Collections> {
    const id = this.objectId(object);
    if (!id) {
      throw new Error('object have not id');
    }

    let payload = object;
    if (send) {
      const [url, groups] = AbstractRepository.getQueryData(this.query.update);
      const response = this.usePatch
        ? await axios.patch(Routing.generate(url, { id }), classToPlain(object, { groups }))
        : await axios.put(Routing.generate(url, { id }), classToPlain(object, { groups }));

      payload = response.data;
    }

    return this.classRef.insertOrUpdate({ data: payload });
  }

  async delete(id: string | number, send: boolean = true): Promise<any> {
    if (send && this.query.delete) {
      const [url] = AbstractRepository.getQueryData(this.query.delete);
      await axios.delete(Routing.generate(url, { id }));
    }
    return this.classRef.delete(id);
  }

  async deleteAll() {
    this.haveAllData = false;
    return this.classRef.deleteAll();
  }

  private static getQueryData(query: string | { name: string, groups: string[] }): [string, string[]] {
    const url = typeof query === 'string' ? query : query.name;
    const groups = typeof query === 'string' ? [] : query.groups;

    return [url, groups];
  }
}
