class Substance {
  static entity = 'substances';
  static primaryKey = 'id';

  _new: boolean = false;
  _edit: boolean = false;
  id: number | null = null;
  referenceId: string | null = null;
  personalNumber: string | null = null;
  CASNumber: string | null = null;
  ECNumber: string | null = null;
  label: string | null = null;
  quantity: string | null = null;
  productSheetUuid: string | null = null;
  hazardStatements: Array<number> = [];

  get isSave() {
    return this.id !== null;
  }
}

export default Substance;
