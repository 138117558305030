import { Store } from 'vuex';

import AbstractRepository from '@/lib/repository-factory/AbstractRepository';
import Job from '@models/firm/Job';

export default class JobRepository extends AbstractRepository<Job> {
  query = {
    get: '',
    all: '',
    create: 'api_post_job',
    update: 'api_put_job',
    delete: 'api_delete_job',
  };

  constructor(store: Store<any>) {
    super(store, Job);
    Job.setRepository(this);
  }

  hasEditedCurrentFirm() {
    return this.hasEdited(this.store.state.UserModule.currentFirmId);
  }

  hasEdited(currentFirm: number) {
    return Job.query().where('edit', true).where('firmId', currentFirm).count() > 0;
  }
}
