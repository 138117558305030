import { schema } from 'normalizr';
import EmployeeHasExposition from '@modules/declaration/entities/EmployeeHasExposition';

const employeeHasExposition = new schema.Entity<EmployeeHasExposition>(EmployeeHasExposition.entity, {}, {
  processStrategy: (value) => {
    value.beginDate = value.beginDate ? new Date(value.beginDate) : null;
    value.endDate = value.endDate ? new Date(value.endDate) : null;
    return Object.assign(new EmployeeHasExposition(), { ...value });
  },
  mergeStrategy: (entityA, entityB) => Object.assign(entityA, entityB),
  idAttribute: EmployeeHasExposition.primaryKey,
});

export default employeeHasExposition;
