import { RouteConfig } from 'vue-router';
import store from '@/store';

const MyUsers = () => import('@/views/firm/user/MyUsersListView.vue');
const MyUsersEdit = () => import('@/views/firm/user/MyUsersEditView.vue');

export default [
  {
    path: '/entreprise/mes-utilisateurs',
    name: 'my-users',
    component: MyUsers,
    meta: {
      breadcrumb: [
        { name: 'Accueil', link: 'home', icon: 'home' },
        { name: 'Entreprise' },
        { name: 'Mes utilisateurs' },
      ],
      permission: 1,
    },
  },
  {
    path: '/entreprise/mes-utilisateurs/edition/:id',
    name: 'my-users-edit',
    component: MyUsersEdit,
    beforeEnter: (to, from, next) => {
      store.dispatch('changeCurrentUser', to.params.id).then(() => {
        next();
      }).catch(() => {
        next({ name: 'home' });
      });
    },
    meta: {
      breadcrumb: [
        { name: 'Accueil', link: 'home', icon: 'home' },
        { name: 'Entreprise' },
        { name: 'Mes utilisateurs', link: 'my-users' },
      ],
      group: 'my-users',
      permission: 2,
    },
  },
] as RouteConfig[];
