import { schema } from 'normalizr';

import FirmStructure from '@modules/declaration/entities/FirmStructure';

import unitSchema from './unitSchema';
import jobSchema from './jobSchema';

const structureSchema = new schema.Union<FirmStructure>({
  Unit: unitSchema,
  Job: jobSchema,
}, 'disc');

export default structureSchema;
