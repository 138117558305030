

















import { Component, Vue, Watch } from 'vue-property-decorator';
import { State } from 'vuex-class';
import Header from '@/components/Header.vue';
import SidebarMenu from '@/components/SidebarMenu.vue';
import Footer from '@/components/Footer.vue';

@Component({
  components: {
    SidebarMenu,
    Header,
    Footer,
  },
})
export default class App extends Vue {
  @State('init')
  init!: boolean;

  errors: string[] = [];

  loading = this.$loading({
    lock: true,
    text: 'Chargement des données...',
    spinner: 'el-icon-loading',
    background: '#444',
    customClass: 'app-loader',
  });

  @Watch('$route.path')
  onRouteChange() {
    this.errors = [];
  }

  @Watch('init', { immediate: true })
  onChange() {
    if (this.init) {
      this.loading.close();
    }
  }

  errorCaptured(err: string) {
    if (process.env.NODE_ENV !== 'production') {
      this.errors.push(err);
    }
  }
}
