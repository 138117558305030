import { Fields, Model } from '@vuex-orm/core';

class CodeNafHasExposition extends Model {
  static entity = 'codeNafHasExposition';
  static primaryKey = ['expositionId', 'codeNafId'];

  static fields(): Fields {
    return {
      expositionId: this.number(null),
      codeNafId: this.number(null),
    };
  }

  codeNafId!: number;
  expositionId!: number;
}

export default CodeNafHasExposition;
