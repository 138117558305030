import store from '@/store';

const identificationEdit = () => import('../pages/identification/IdentificationEditView.vue');
const identification = () => import('../pages/identification/IdentificationView.vue');

export default [
  {
    path: '/risques-chimiques/identification-des-produits',
    name: 'chemi-risks-identification',
    component: identification,
    meta: {
      permission: 1,
      breadcrumb: [
        { name: 'Accueil', link: 'home', icon: 'home' },
        { name: 'Risques Chimiques', link: 'chemi-risks-dashboard' },
        { name: 'Analyse des FDS' },
      ],
      access: () => store.getters['cheRisk/validActiveCheFolderStatus']([0, 4, 5]),
    },
  },
  {
    path: '/risques-chimiques/identification-des-produits/analyse/:id',
    name: 'chemi-risks-identification-edit',
    component: identificationEdit,
    beforeEnter: (to: any, from: any, next: () => void) => {
      const p1 = store.dispatch('cheRisk/loadProductFolder', to.params.id);
      // const p2 = store.dispatch('firm/loadFirmStructure', store.state.UserModule.currentFirmId);
      const p3 = store.dispatch('cheRisk/loadPictograms');
      const p4 = store.dispatch('cheRisk/loadHazardStatements');
      Promise.all([p1, p3, p4]).then(() => {
        next();
      });
    },
    meta: {
      permission: 1,
      group: 'chemi-risks-identification',
      breadcrumb: [
        { name: 'Accueil', link: 'home', icon: 'home' },
        { name: 'Risques Chimiques', link: 'chemi-risks-dashboard' },
        { name: 'Analyse des FDS', link: 'chemi-risks-identification' },
        { name: 'Édition' },
      ],
      access: () => store.getters['cheRisk/validActiveCheFolderStatus']([0, 4, 5]),
    },
  },
];
