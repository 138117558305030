export interface Folder {
  id: number | null;
  reference: string;
  beginAt: Date | null;
  endAt: Date | null;
  files: Array<any>;
}

export enum FolderEnum {
  FOLDER_OPEN,
  FOLDER_CANCEL,
  FOLDER_IN_CLOSING,
  FOLDER_CLOSED
}
