interface MessageEventData {
  type: string
  data: Object
}

interface MercureListener {
  type: string,

  callback(payload: { type: string, response: MessageEvent, data: MessageEventData }): void
}

class MercureServer {
  private readonly baseUrl: string = '';
  private firmSource: EventSource | null = null;
  private listeners: MercureListener[] = [];

  constructor() {
    this.baseUrl = process.env.MERCURE_PUBLISH_URL || 'localhost:3000';
  }

  subscriber(firmId: number) {
    const url = new URL(this.baseUrl);
    url.searchParams.append('topic', `firm/${firmId}`);

    this.firmSource = new EventSource(url.toString(), {
      withCredentials: true,
    });

    this.firmSource.onmessage = ev => this.dispatchEvent(ev);

    return this;
  }

  getFirmSource() {
    return this.firmSource;
  }

  addListener(type: string, callback: (payload: { type: string, response: MessageEvent, data: MessageEventData }) => void) {
    this.listeners.push({ type, callback });
  }

  private dispatchEvent(ev: MessageEvent) {
    const response: MessageEvent = JSON.parse(ev.data);
    this.listeners.forEach((listener) => {
      if (response.type.includes(listener.type)) {
        listener.callback({ type: response.type, response: ev, data: response.data });
      }
    });
  }
}

export default new MercureServer();
