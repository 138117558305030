const MyUsers = () => import('../pages/MyUsersListView.vue');

export default [
  {
    path: '/entreprise/mes-utilisateurs',
    name: 'my-users',
    component: MyUsers,
    meta: {
      breadcrumb: [
        { name: 'Accueil', link: 'home', icon: 'home' },
        { name: 'Entreprise' },
        { name: 'Mes utilisateurs' },
      ],
      permission: 1,
    },
  },
];
