import * as Vuex from 'vuex';
import { RepositoryInterface } from '@/lib/repository-factory/RepositoryInterface';

interface RModule extends Vuex.Module<any, any> {
  namespaced: boolean
  state: []
  getters: Vuex.GetterTree<any, any>
  actions: Vuex.ActionTree<any, any>
  mutations: Vuex.MutationTree<any>
  modules: Vuex.ModuleTree<any>
}

class RepositoryFactory {
  repositories: RepositoryInterface<any>[] = [];

  store!: Vuex.Store<any>;

  started: boolean = false;

  namespace: string = 'repositories';

  repositoryWaiting: any[] = [];

  bindStore(store: Vuex.Store<any>) {
    this.store = store;
    this.start();
  }

  start() {
    if (!this.started) {
      this.started = true;
      this.buildModule();
    }
  }

  get<T extends RepositoryInterface<any>>(name: string): T {
    const repository = this.repositories.find(r => r.name === name);
    if (!repository) {
      throw new Error(`repository ${name} does not exist`);
    }

    // @ts-ignore
    return repository;
  }

  register(Repository: any) {
    if (this.started) {
      const repository = new Repository(this.store, this);
      this.repositories.push(repository);
      this.store.registerModule(
        [this.namespace, repository.name],
        this.createSubModule(repository),
      );
    } else {
      this.repositoryWaiting.push(Repository);
    }
  }

  buildModule() {
    this.store.registerModule(this.namespace, this.createModule());
  }

  /**
   * Create Vuex Module from the registered entities.
   */
  private createModule(): Vuex.Module<any, any> {
    const module = this.createRootModule();

    this.repositoryWaiting.forEach((Repository) => {
      const repository = new Repository(this.store);
      this.repositories.push(repository);
      module.modules[repository.name] = this.createSubModule(repository);
    });

    return module;
  }

  private createSubModule(repository: RepositoryInterface<any>): Vuex.Module<any, any> {
    return {
      namespaced: true,
      state: [],
      getters: this.createSubGetters(repository),
      actions: this.createSubActions(repository),
      mutations: {},
    };
  }

  private createRootModule(): RModule {
    return {
      namespaced: true,
      state: [],
      getters: {
        getRepository: state => (name: string) => this.get<any>(name),
      },
      actions: {},
      mutations: {},
      modules: {},
    };
  }

  private createSubGetters(repository: RepositoryInterface<any>): Vuex.GetterTree<any, any> {
    const getters = this.getGetters(repository);

    const getterTree: Vuex.GetterTree<any, any> = {};
    getters.forEach((getter: keyof RepositoryInterface<any>) => {
      const func = repository[getter];
      if (typeof func === 'function') {
        if (func.length === 0) {
          // @ts-ignore
          getterTree[getter] = () => repository[getter]();
        } else {
          // @ts-ignore
          getterTree[getter] = () => (...args: any[]) => repository[getter](...args);
        }
      }
    });

    return getterTree;
  }

  private createSubActions(repository: RepositoryInterface<any>): Vuex.ActionTree<any, any> {
    const actions = this.getActions(repository);

    const actionTree: Vuex.ActionTree<any, any> = {};
    actions.forEach((action: keyof RepositoryInterface<any>) => {
      const func = repository[action];
      if (typeof func === 'function') {
        actionTree[action] = (context, args: any) => {
          if (typeof args === 'undefined') {
            // @ts-ignore
            return repository[action]();
          }

          // @ts-ignore
          return repository[action](args);
        };
      }
    });

    return actionTree;
  }

  getGetters(toCheck: any) {
    return this.getAllFuncs(toCheck).filter(k => k.match(/^get|has/) && !k.match(/OwnProperty/));
  }

  getActions(toCheck: any) {
    return this.getAllFuncs(toCheck).filter(k => k.match(/^find|create|update|insert|delete|search/));
  }

  getAllFuncs(toCheck: any) {
    let props: any[] = [];
    let obj = toCheck;
    // eslint-disable-next-line no-cond-assign
    do {
      props = props.concat(Object.getOwnPropertyNames(obj));
    } while (obj = Object.getPrototypeOf(obj));

    return props.sort().filter((e, i, arr) => (e !== arr[i + 1] && typeof toCheck[e] === 'function'));
  }
}

export default RepositoryFactory;
