import { AbstractModule } from '@/types/modules';

import routes from './router';

import store from './store';

import { RiskProModuleInterface } from './store/types';
import professionalRiskContainer from './ioc/professional-risk.container';

import RiskCharacterRepository from './repositories/RiskCharacterRepository';
import RiskRepository from './repositories/RiskRepository';
import ActionPlanRepository from './repositories/ActionPlanRepository';
import ProFolderRepository from './repositories/ProFolderRepository';
import ThesaurusRepository from './repositories/ThesaurusRepository';

export default class ProfessionalRiskModule extends AbstractModule {
  install(options: any): void {
    this.router.addRoutes(routes);
    this.store.registerModule<RiskProModuleInterface>('riskPro', store);
    this.store.dispatch('riskPro/init');

    this.repositoryFactory.register(ProFolderRepository);
    this.repositoryFactory.register(RiskCharacterRepository);
    this.repositoryFactory.register(RiskRepository);
    this.repositoryFactory.register(ActionPlanRepository);
    this.repositoryFactory.register(ThesaurusRepository);

    professionalRiskContainer.build(this.container);
  }
}
