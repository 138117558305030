class Comment {
  static entity = 'comments';
  static primaryKey = 'id';

  id: number | null = null;
  comment: string | null = null;
  status: number | null = null;
}

export default Comment;
