import { Module } from 'vuex';

import { FirmUpdateModuleInterface } from './types';

import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
  declaration: false,
  initialStep: 0,
  currentStep: 0,
  editable: true,
  progressStep: '',
  progress: 0,
  progressTotal: 0,
  units: {},
  jobs: {},
  persons: {},
  employees: {},
  employeeHasJobs: {},
  employeeHasContracts: {},
  employeeHasExpositions: {},
  results: {},
};

const FirmUpdateModule: Module<FirmUpdateModuleInterface, any> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default FirmUpdateModule;
